<template>
	<div class="m_wrapper">
		<div class="top-menu">
			<div class="top-menu-content">
				<div class="left_logo">
					<img
						src="https://img.i2soft.net/i2official-web/v2/assets/images/home/phone/navLogo.svg"
						alt=""
					/>
				</div>
				<div class="right_menu">
					<van-dropdown-menu>
						<van-dropdown-item ref="menuDropdown">
							<template #title>
								<img
									class="right_menuImg"
									src="https://img.i2soft.net/i2official-web/assets/images/nav/phone/black_menu.png"
									alt=""
								/>
							</template>
							<template #default>
								<div class="right_menu_dropdown">
									<el-menu
										mode="vertical"
										:default-active="activeIndex"
										router
										@select="handleSelect"
									>
										<el-menu-item index="/first">
											<span>首页</span>
											<img
												src="https://img.i2soft.net/i2official-web/assets/images/nav/phone/navArrow.png"
												alt=""
											/>
										</el-menu-item>
										<el-menu-item index="/introduce">
											<span>公司介绍</span>
											<img
												src="https://img.i2soft.net/i2official-web/assets/images/nav/phone/navArrow.png"
												alt=""
											/>
										</el-menu-item>
										<el-menu-item index="/solve">
											<span>解决方案</span>
											<img
												src="https://img.i2soft.net/i2official-web/assets/images/nav/phone/navArrow.png"
												alt=""
											/>
										</el-menu-item>
										<el-menu-item index="/relations">
											<span>投资者关系</span>
											<img
												src="https://img.i2soft.net/i2official-web/assets/images/nav/phone/navArrow.png"
												alt=""
											/>
										</el-menu-item>
										<el-menu-item index="/joinUs">
											<span>加入我们</span>
											<img
												src="https://img.i2soft.net/i2official-web/assets/images/nav/phone/navArrow.png"
												alt=""
											/>
										</el-menu-item>
									</el-menu>
								</div>
							</template>
						</van-dropdown-item>
					</van-dropdown-menu>
				</div>
			</div>
		</div>
		<div class="top-menu-fixed"> </div>
		<el-main class="flex-container">
			<router-view class="flex-item"></router-view>
			<div class="mobileFooterWrapper">
				<div class="topBox">
					<div
						class="textBox"
						v-for="(item, index) in footData"
						:class="{ 'textBox-active': activeIndex === item.path }"
						:key="index + 1"
						@click="footerLinkClick(item.path)"
					>
						<span>{{ item.name }}</span>
						<BottomLine
							size="small"
							v-show="activeIndex === item.path"
						/>
					</div>
				</div>
				<div class="bottomTipBox">沪ICP备09061940号-1</div>
			</div>
		</el-main>
	</div>
</template>

<script>
	import { isMobile } from '@/utils/index';
	import BottomLine from '@/components/BottomLine.vue';

	export default {
		components: { BottomLine },
		data() {
			return {
				activeIndex: '',
				isMobile: false,
				footData: [
					{
						path: '/first',
						name: '首页',
					},
					{
						path: '/introduce',
						name: '公司介绍',
					},
					{
						path: '/solve',
						name: '解决方案',
					},
					{
						path: '/relations',
						name: '投资者关系',
					},
					{
						path: '/joinUs',
						name: '加入我们',
					},
				],
			};
		},
		mounted() {
			this.activeIndex = '/first';
			this.isMobile = isMobile();
			console.log('this.isMobile', this.isMobile);
		},
		computed: {
			is_after_scroll() {
				return this.$store.state.account.afterScroll;
			},
		},
		watch: {},
		methods: {
			handleSelect(index) {
				this.activeIndex = index;
				this.$refs.menuDropdown.toggle();
			},
			// 底部-快速链接
			footerLinkClick(path) {
				this.$router.push({ path: path });
				this.activeIndex = path;
			},
		},
	};
</script>

<style lang="less" scoped>
	.m_wrapper {
		width: 100%;
		display: flex;
		flex-direction: column;
		min-height: 100vh;

		.top-menu {
			width: 100%;
			position: fixed;
			left: 0;
			top: 0;
			z-index: 5;

			.top-menu-content {
				padding: 15px 20px;
				display: flex;
				background: #fff;
				box-shadow: 0px 1px 5px 1px rgba(0, 0, 0, 0.2);
				justify-content: space-between;
				align-items: center;
			}

			.left_logo {
				width: 115px;
				height: 30px;

				img {
					width: 115px;
				}
			}

			.right_menu {
				height: 20px;
				display: flex;
				justify-content: center;
				align-items: center;

				.right_menuImg {
					width: 20px;
					height: 16px;
				}

				.right_menu_dropdown {
					.el-menu {
						.el-menu-item {
							border-bottom: 1px solid #e0e0e0;
							display: flex;
							justify-content: space-between;
							align-items: center;

							img {
								width: 6px;
								height: 8px;
							}
						}

						.routeItem {
							font-size: 14px;
							color: #333;
							height: 60px;

							span {
								margin-left: 15px;
							}
						}
					}
				}
			}
		}

		.top-menu-fixed {
			height: 60px;
		}

		.flex-container {

			flex: 1;
			display: flex;
			flex-direction: column;
			padding: 0; /* 顶部菜单的高度 */
			background-color: #fff;

			.flex-item {
				flex: 1;
				// padding: 0 12px;
			}
		}

		.mobileFooterWrapper {
			width: 100%;
			padding: 14px 0;
			background: #f9f9f9;

			.topBox {
				width: 100%;
				margin-bottom: 10px;
				display: flex;
				justify-content: space-around;
				align-items: stretch;
				color: #333;
				font-size: 12px;

				span:active {
					color: #b08d57;
				}
			}
			.textBox-active {
				color: #b08d57;
			}

			.bottomTipBox {
				font-size: 10px;
				color: #666;
			}
		}
	}

	/deep/ .van-dropdown-menu__bar {
		box-shadow: none;
	}

	/deep/ .van-dropdown-menu__title::after {
		display: none;
	}

	/deep/ .van-dropdown-menu__title {
		padding: 0;
	}

	/deep/ .van-ellipsis {
		height: 16px;
	}
</style>