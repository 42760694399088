<template>
    <div class="m_wrapper">
        <div class="m_firstBannerWrapper">
            <div class="block">
                <el-carousel :interval="3000" width="100%" height="238px">
                    <el-carousel-item v-for="(item, index) in m_firstDataList" :key="index + 1">
                        <img :src="item.imgUrl" alt="" />
                    </el-carousel-item>
                </el-carousel>
            </div>
        </div>
        <div class="m_secondWrapper">
            <div class="secontTitle">
                <WaveTitle :title="`我们能为您的行业\n打造深度的数字化能力`" subhead="Digital capability" isMobile />
            </div>
            <div class="secContent">
                <div class="secTopRow">
                    <div class="secItem" v-for="(item, index) in m_secondDataList" :key="index">
                        <img class="showImage" :src="item.imgUrl" alt="" />
                        <div>{{ item.name }}</div>
                        <BottomLine isMobile />
                    </div>
                </div>
            </div>
        </div>
        <div class="m_thirdWrapper">
            <div class="thirdTitle">
                <WaveTitle title="我们的技术实力" subhead="technical strength" isMobile textColor="#FFFFFF" />
            </div>
            <div class="thirdContent">
                <div class="thirdRowItem" v-for="(item, index) in m_thirdDataList" :key="index">
                    <div class="itemTitle">{{ item.title }}</div>
                    <div class="itemBottomName">{{ item.name }}</div>
                </div>
            </div>
        </div>
        <div class="m_forthWrapper">
            <div class="forthTitle">
                <WaveTitle title="我们的市场表现" subhead="Market performance" isMobile></WaveTitle>
            </div>
            <img src="https://img.i2soft.net/i2official-web/v2/assets/images/home/phone/forthback.png" alt="" />
            <div class="forthContent">
                <div class="desc">
                    2021年11月15日，北交所正式开市。艾融软件为北交所首批上市公司、北证50首批成份指数样本股、北交所首批做市标的股票。
                </div>
            </div>
        </div>
        <div class="m_fifthWrapper">
            <div class="m_fifthContent">
                <div class="m_fifthItem" v-for="(item, index) in m_fifthDataList" :key="index + 80">
                    <img :src="item.imgUrl" alt="" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { isMobile } from "@/utils/index";
import WaveTitle from '@/components/WaveTitle.vue';
import BottomLine from '@/components/BottomLine.vue';

export default {
    name: "First",
    components: { WaveTitle, BottomLine },
    data() {
        return {
            screenWidth: document.body.clientWidth,     // 屏幕宽
            isMobile: false,
            m_firstDataList: [
                {
                    id: "1",
                    imgUrl: "https://img.i2soft.net/i2official-web/v2/assets/images/home/phone/banner1.png",
                },
                {
                    id: "2",
                    imgUrl: "https://img.i2soft.net/i2official-web/v2/assets/images/home/phone/banner2.png",
                },
                {
                    id: "3",
                    imgUrl: "https://img.i2soft.net/i2official-web/v2/assets/images/home/phone/banner3.png",
                },
                {
                    id: "4",
                    imgUrl: "https://img.i2soft.net/i2official-web/v2/assets/images/home/phone/banner4.png",
                },
            ],
            m_secondDataList: [
                {
                    id: "1",
                    name: "产品及解决方案",
                    imgUrl: "https://img.i2soft.net/i2official-web/v2/assets/images/home/phone/second01.svg",
                },
                {
                    id: "2",
                    name: "业务咨询和创新",
                    imgUrl: "https://img.i2soft.net/i2official-web/v2/assets/images/home/phone/second02.svg",
                },
                {
                    id: "3",
                    name: "大规模定制交付",
                    imgUrl: "https://img.i2soft.net/i2official-web/v2/assets/images/home/phone/second03.svg",
                },
                {
                    id: "4",
                    name: "数字化运营",
                    imgUrl: "https://img.i2soft.net/i2official-web/v2/assets/images/home/phone/second04.svg",
                },
                {
                    id: "5",
                    name: "数字化安全保障",
                    imgUrl: "https://img.i2soft.net/i2official-web/v2/assets/images/home/phone/second05.svg",
                },
                {
                    id: "6",
                    name: "数字化系统集成",
                    imgUrl: "https://img.i2soft.net/i2official-web/v2/assets/images/home/phone/second06.svg",
                },
            ],
            m_thirdDataList: [
                {
                    id: "13",
                    title: "90%",
                    name: "技术工程师超过",
                },
                {
                    id: "7",
                    title: "430项",
                    name: "计算机软件著作权",
                },
                {
                    id: "1",
                    title: "24项",
                    name: "软件技术发明专利",
                },
            ],
            m_fifthDataList: [
                {
                    id: "1",
                    imgUrl: "https://img.i2soft.net/i2official-web/assets/images/home/phone/fifth/fifth01.png",
                },
                {
                    id: "2",
                    imgUrl: "https://img.i2soft.net/i2official-web/v2/assets/images/home/phone/fifth02.png",
                },
                {
                    id: "3",
                    imgUrl: "https://img.i2soft.net/i2official-web/assets/images/home/phone/fifth/fifth03.png",
                },
                {
                    id: "4",
                    imgUrl: "https://img.i2soft.net/i2official-web/assets/images/home/phone/fifth/fifth04.png",
                },
                {
                    id: "5",
                    imgUrl: "https://img.i2soft.net/i2official-web/assets/images/home/phone/fifth/fifth05.png",
                },
                {
                    id: "6",
                    imgUrl: "https://img.i2soft.net/i2official-web/assets/images/home/phone/fifth/fifth06.png",
                },
            ],
        };
    },
    mounted() {
        this.isMobile = isMobile();
    },
};
</script>

<style lang="less" scoped>
@media screen and (max-width: 479px) {
    .m_wrapper {
        .m_firstBannerWrapper {
            /deep/ .el-carousel__indicators {
                width: 80%;
            }

            img {
                width: 100%;
                height: inherit;
            }
        }

        .m_secondWrapper {

            .secontTitle {
                text-align: center;
                font-size: 16px;
                margin-top: 40px;
                margin-bottom: 40px;
                font-family: PingFangSC-Semibold;
            }

            .secContent {
                .secTopRow {
                    display: grid;
                    grid-template-columns: repeat(3, 1fr);
                    align-items: center;
                    justify-items: center;
                    row-gap: 60px;

                    .secItem {
                        width: 90px;
                        font-size: 12px;
                        font-weight: bold;
                        line-height: normal;
                        text-align: center;
                        letter-spacing: 0em;
                        color: #001F34;

                        .showImage {
                            width: 42px;
                            height: 42px;
                            margin-bottom: 16px;
                        }
                    }
                }

                .secBottonRow {
                    // background: green;
                    display: flex;
                    justify-content: space-between;
                    font-size: 12px;
                    margin-bottom: 10px;

                    .secItem {
                        width: 90px;

                        .showImage {
                            width: 48px;
                            height: 48px;
                        }
                    }
                }
            }
        }

        .m_thirdTitle {
            text-align: center;
            width: 100%;
            height: 50px;
            color: #333;
            font-size: 16px;
            font-family: PingFangSC-Semibold;
        }

        .m_thirdWrapper {
            background-image: url("https://img.i2soft.net/i2official-web/v2/assets/images/home/phone/techProwessBack.png");
            background-repeat: no-repeat;
            background-position: center;
            background-size: cover;
            height: 280px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            justify-content: space-evenly;



            .thirdContent {
                width: 100%;
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: space-evenly;

                .thirdRowItem {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    line-height: normal;
                    text-align: center;
                    letter-spacing: 0em;

                    .itemTitle {
                        font-size: 36px;
                        font-weight: bold;
                        color: #FFF;

                    }

                    .itemBottomName {
                        font-size: 12px;
                        font-weight: normal;
                        color: #F49A42;
                    }
                }
            }
        }

        .m_forthWrapper {
            background: #fff;

            .forthTitle {
                margin: 50px 0;
            }

            .forthContent {
                width: 75%;
                margin: 15px auto;

                .desc {
                    font-size: 14px;
                    font-weight: normal;
                    line-height: normal;
                    text-align: center;
                    white-space: pre-line;
                    letter-spacing: 0em;
                    color: #001F34;
                }

            }
        }

        .m_fifthWrapper {
            padding-bottom: 20px;

            .m_fifthTitle {
                width: 100%;
                color: #333;
                font-size: 16px;
                text-align: center;
                margin-top: 40px;
                font-family: PingFangSC-Semibold;
                margin-bottom: 30px;
            }

            .m_fifthContent {
                margin: 0 auto;
                width: calc(100% - 20px);
                padding-left: 10px;
                padding-right: 10px;
                display: flex;
                justify-content: space-between;
                flex-wrap: wrap;

                .m_fifthItem {
                    width: calc((100% - 10px) * 0.5);
                    padding-top: 3px;

                    img {
                        width: 100%;
                    }
                }
            }
        }
    }
}
</style>
