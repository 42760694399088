<template>
  <div>
    <div v-if="!isMobile" class="pc">
      <div class="joinBanner">
        <div>
          <img src="https://img.i2soft.net/i2official-web/assets/images/joinus/firstBanner.png" />
        </div>
        <div>
          <p>行业格局已经发生巨变</p>
          <p>我们为您提供场景化的</p>
          <p>深度行业解决方案</p>
        </div>
      </div>
      <div class="announcementWrapper">
        <div class="top_box">
          <div class="pc_change">
            <a @click.prevent="recruitBtnDidiClick(1)">社会招聘</a>
            <a class="school_recruit" @click.prevent="recruitBtnDidiClick('second')">校园招聘</a>
            <a class="school_recruit" @click.prevent="goToExam()">在线考试</a>
          </div>
        </div>
      </div>
      <div class="joinUsCss">
        <WaveTitle style="margin-top: 20px" :title="'加入我们'" :subhead="'Join Us'"></WaveTitle>
        <div class="joinUsBox">
          <div class="joinFour">
            <div style="display: flex;gap: 25px;overflow: auto">
              <div class="joinCard" v-for="(item, index) in recruitData" :key="index"
                @click="recruitBtnDidiClick(index + 1)">
                <img :src="item.imgUrl" />
                <div class="cardName">{{ item.title }}</div>
                <div class="lookDetail">详情查看</div>
              </div>
            </div>
          </div>
          <img class="handBg" src="https://img.i2soft.net/i2official-web/v2/assets/images/joinus/joinUsBg.png" />
        </div>
      </div>
      <div class="usV2">
        <WaveTitle style="margin-top: 20px" :title="'走进我们'" :subhead="'Abouts Us'"></WaveTitle>
        <div class="closeIntro">
          {{ `成熟的产品先进的软件工程实施方法技艺娴熟的技术团队是艾融软件满足客户需求的三大法宝。艾融软件的技术团队是一支朝气蓬勃、'+'\n'+'激情四射的专业团队，活好、皮实、合群、上进，是艾融软件技术团队的真实写照。`
          }}
        </div>
        <div class="closeUsImgs">
          <div style="width: 459px;height: 446px;">
            <img style="width: 459px;height: 446px;"
              src="https://img.i2soft.net/i2official-web/assets/images/joinus/work.png">
          </div>
          <div style="width: 459px;height: 446px;">
            <img style="width: 459px;height: 446px;"
              src="https://img.i2soft.net/i2official-web/assets/images/joinus/meeting.png">
          </div>
          <div style="width: 459px;height: 446px;">
            <img style="width: 459px;height: 446px;"
              src="https://img.i2soft.net/i2official-web/assets/images/joinus/cultivate.png">
          </div>
          <div style="width: 459px;height: 446px;">
            <img style="width: 459px;height: 446px;"
              src="https://img.i2soft.net/i2official-web/assets/images/joinus/rest.png">
          </div>
          <div style="width: 459px;height: 446px;">
            <img style="width: 969px;height: 446px;"
              src="https://img.i2soft.net/i2official-web/v2/assets/images/joinus/usImg5.png">
          </div>
        </div>
      </div>
      <div class="employeeV2">
        <WaveTitle style="margin-top: 20px" :title="'员工心声'" :subhead="'employee aspirations'"></WaveTitle>
        <div class="employeeIntro">
          {{ '艾融的TA（项目采访视频）' + '\n' + ' 加入艾融的那天起，就走上了收获的旅程。收获友谊，收获成长，收获经验，收获未来。' }}
        </div>
        <div class="heardBox">
          <div class="heardSwipe">
            <el-carousel :interval="4000" type="card" height="500px" :autoplay="false" ref="swip1Ref"
              @change="onSwipChange">
              <el-carousel-item>
                <div class="heardVidio" @click="
                  videoBtnDidClick(
                    'https://img.i2soft.net/1634263555513729.mp4'
                  )
                  ">
                  <img class="lookImg" src="https://img.i2soft.net/i2official-web/assets/images/joinus/employee1.png" />
                  <img class="vidioIcon"
                    src="https://img.i2soft.net/i2official-web/assets/images/joinus/employee3.png" />
                </div>
              </el-carousel-item>
              <el-carousel-item>
                <div class="heardVidio" @click="
                  videoBtnDidClick(
                    'https://img.i2soft.net/1634263536049409.mp4'
                  )
                  ">
                  <img class="lookImg" src="https://img.i2soft.net/i2official-web/assets/images/joinus/employee2.png" />
                  <img class="vidioIcon"
                    src="https://img.i2soft.net/i2official-web/assets/images/joinus/employee3.png" />
                </div>
              </el-carousel-item>
              <!--              <el-carousel-item>-->
              <!--                <div class="heardVidio">-->
              <!--                  <img class="lookImg"  src="https://img.i2soft.net/i2official-web/assets/images/joinus/employee2.png"/>-->
              <!--                  <img class="vidioIcon" src="https://img.i2soft.net/i2official-web/assets/images/joinus/employee3.png"/>-->
              <!--                </div>-->
              <!--              </el-carousel-item>-->
            </el-carousel>
          </div>
          <div class="heardPage">
            <div class="pre1">
              <div class="pre1left" @click="prev1">PREV</div>
              <div class="pre1Right"></div>
            </div>
            <div class="pages">
              <div v-for="(i, index) in 2" :key="index" :style="index == 0 ? {} : { marginLeft: '15px' }"
                :class="{ 'pageActive': index == swipeIndex }">
                {{ `0${index + 1}` }}
              </div>
            </div>
            <div class="next1">
              <div class="next1left"></div>
              <div class="next1Right" @click="next1">
                NEXT
                <img style="margin-left: 10px"
                  src="https://img.i2soft.net/i2official-web/v2/assets/images/joinus/pageRight.png" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="restV2">
        <WaveTitle style="margin-top: 20px" :title="'培训成长体系'" :subhead="'Training and Growth'"></WaveTitle>
        <div class="restContent">
          <div class="restLeft">
            <el-carousel :interval="4000" type="card" height="500px" :autoplay="false" :initial-index="1"
              ref="swip2Ref">
              <el-carousel-item>
                <div class="heardVidio">
                  <img class="lookImg" src="https://img.i2soft.net/i2official-web/v2/assets/images/joinus/tixi2.png" />
                  <img class="vidioIcon"
                    src="https://img.i2soft.net/i2official-web/assets/images/joinus/employee3.png" />
                </div>
              </el-carousel-item>
              <el-carousel-item>
                <div class="heardVidio" @click="
                  videoBtnDidClick(
                    'https://img.i2soft.net/1634263569265467.mp4'
                  )
                  ">
                  <img class="lookImg" src="https://img.i2soft.net/i2official-web/v2/assets/images/joinus/tixi1.png" />
                  <img class="vidioIcon"
                    src="https://img.i2soft.net/i2official-web/assets/images/joinus/employee3.png" />
                </div>
              </el-carousel-item>
            </el-carousel>
          </div>
          <div class="restRight">
            <div class="rightName">笨鸟学院技术论坛</div>
            <div class="rightdetail">{{
              '无论你是初入职场的新人，' + '\n' + '还是奋斗路上的中流砥柱，我们都准备了丰富' + '\n' + '的培训课程，助你攀上新的高峰。'
            }}</div>
            <div class="rightPage">
              <div class="pre" @click="prev2">PREV</div>
              <div class="diviPage"></div>
              <div class="next" @click="next2">
                NEXT
                <img style="margin-left: 10px"
                  src="https://img.i2soft.net/i2official-web/v2/assets/images/joinus/pageRight.png" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <PhoneIndex v-else/>
    <video-player :videoSrc="videoUrl" />
  </div>
</template>
<script>
import { isMobile } from "@/utils/index";
import videoPlayer from "@/views/video/index.vue";
import WaveTitle from "@/components/WaveTitle";
import PhoneIndex from './PhoneIndex.vue';

export default {
  components: { videoPlayer, WaveTitle,PhoneIndex },
  data() {
    return {
      swipeIndex: 0,
      swip2Ref: '',
      isMobile: false,
      videoState: this.$store.state.account.videoState,
      videoUrl: "",
      version: "",
      recruitData: [
        {
          title: "用户体验设计类",
          imgUrl: "https://img.i2soft.net/i2official-web/v2/assets/images/joinus/joinCardImg1.png",
          desc: "查看岗位",
        },
        {
          title: "技术研发类",
          imgUrl: "https://img.i2soft.net/i2official-web/v2/assets/images/joinus/joinCardImg2.png",
          desc: "查看岗位",
        },
        {
          title: "商务拓展类",
          imgUrl: "https://img.i2soft.net/i2official-web/v2/assets/images/joinus/joinCardImg3.png",
          desc: "查看岗位",
        },
        {
          title: "人力资源类",
          imgUrl: "https://img.i2soft.net/i2official-web/v2/assets/images/joinus/joinCardImg4.png",
          desc: "查看岗位",
        },
      ],
    };
  },
  created() { },
  mounted() {
    this.isMobile = isMobile();
    window.addEventListener("scroll", this.getScroll);
  },
  destroyed() {
    window.removeEventListener("scroll", this.getScroll);
  },
  methods: {
    videoBtnDidClick(url) {
      this.videoUrl = url;
      this.$store.commit("TO_VIDEOS");
    },
    mouseLeave() {
      // eslint-disable-next-line no-unused-vars
      let mask = document.getElementById("mask");
      let word = document.getElementById("word1");
      let img = document.getElementById("img1");
      word.className = "changeVideo";
      mask.className = "mouseLeave";
      img.style.display = "none";
    },
    getScroll() {
      // this.indexNumTop = $("#index_num").offset().top;
      this.scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      if (
        !!document.documentElement.scrollTop &&
        document.documentElement.scrollTop >= 70
      ) {
        //设置滚动大于300时的执行内容
        // console.log(
        //   "document.documentElement.scrollTop=",
        //   document.documentElement.scrollTop
        // );
        this.$store.commit("TO_AfterScroll");
      } else {
        this.$store.commit("TO_AfterScroll_false");
      }
    },
    // 跳转招聘页面
    recruitBtnDidiClick(type) {
      if (typeof type === "string" && type !== "second") {
        return;
      }
      if (typeof type === "string" && type === "second") {
        type = 99;
      }
      this.$router.push({ path: "/recruit", query: { category: type } });
      this.$router.afterEach(() => {
        window.scrollTo(0, 0);
      });
    },

    // 跳转招聘考试页面
    goToExam() {
      this.$router.push({ path: "/examList" });
    },
    prev2() {
      console.log('this.swip2Ref', this.$refs.swip2Ref)
      this.$refs.swip2Ref.prev()
    },
    next2() {
      this.$refs.swip2Ref.next()
    },
    prev1() {
      this.$refs.swip1Ref.prev()
    },
    next1() {
      this.$refs.swip1Ref.next()
    },
    onSwipChange(e) {
      console.log('onSwipChange', e)
      this.swipeIndex = e
    }

  },
};
</script>
<style scoped lang="less">
.recruitmentTitle_box {
  position: absolute;
  top: 0%;
  text-align: right;
  width: 80%;
  height: 100%;
  left: 3%;
}

.changeVideoLeave {
  background-color: #245da1;
  color: #ffffff;
}

.changeVideo {
  background-color: #ffffff;
  color: #333333;
}

.activeVideo {
  background-color: #000000;
  opacity: 0.3;
}

.mouseLeave {
  display: block;
}

#mask {
  position: absolute;
}

#mask2 {
  position: absolute;
}

#mask3 {
  position: absolute;
  top: 75px;
  right: 15%;
  z-index: 17;
}

/* .pc_change div {
  margin-right: 10px;
  position: relative;
  margin-top: 6%;
  cursor: default;
} */
.pc {
  background: white;
}

.announcementWrapper {
  margin-top: -6px;
  background-color: #F4F0D7;
  padding: 0 20px;
  display: flex;
  flex-direction: row;
  justify-content: center;

  .top_box {
    width: 75%;
  }

  .pc_change {
    margin-right: 25px;
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    align-items: center;
    justify-content: flex-end;
    column-gap: 31px;

    a {
      padding: 20px 0;
      cursor: default;
      text-decoration: none;
      color: #000000;
      font-size: 15px;
      cursor: pointer;
    }

    a:hover {
      color: #F49A42;
    }

    .active {
      color: #F49A42;
    }

  }

}

.email p {
  margin: 0;
  font-family: PingFangSC-Regular;
  font-size: 16px;
  margin-top: 5px;
  margin-bottom: 5px;
  text-align: left;
}

.email p a {
  color: #245da1;
}

.email img {
  position: absolute;
  top: 0;
  left: 78.5%;
  height: 100%;
}

.email {
  position: absolute;
  top: 90%;
  left: 19%;
  width: 100%;
}

.itemRest:hover {
  filter: progid:DXImageTransform.Microsoft.Shadow(color=#909090, direction=120, strength=4);
  -moz-box-shadow: 2px 2px 5px #909090;
  -webkit-box-shadow: 2px 2px 5px #909090;
  box-shadow: 2px 2px 5px #909090;
  transform: translateY(-15px);
}

.itemSpan {
  position: absolute;
  top: 25%;
  font-size: 16px;
  color: #245da1;
  font-family: PingFangSC-Regular;
}

.recruitmentPosition {
  position: absolute;
  z-index: 10;
  top: 28%;
  left: 18%;
}

.itemTitle {
  position: absolute;
}

.itemRest p {
  font-size: 20px;
  font-weight: bold;
  letter-spacing: 1px;
  margin-top: 20px;
  font-family: PingFangSC-Semibold;
  margin-left: 20px;
  /* background: red;  */
  width: 100%;
  text-align: left;
}

.itemRest {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  margin-right: 10px;
  margin-left: 10px;
}

.recruitmentCategory {
  display: flex;
}

.recruitmentWord div {
  flex: 0 0 20%;
  display: flex;
}

.recruitment img:nth-child(1) {
  width: 100%;
  height: 100%;
}

.recruitmentTitle {
  padding: 0;
  font-size: 30px;
  position: absolute;
  z-index: 10;
  top: 20px;
  left: 18%;
  margin-left: 10px;
}

.recruitment {
  position: relative;
  overflow: hidden;
  margin-bottom: 10px;
}

.titleTop img {
  width: 16%;
  height: 60%;
}

.titleTopP {
  padding: 0;
  margin-left: 20px;
  font-weight: bold;
  letter-spacing: 3px;
  font-size: 22px;
}

.titleTop {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: absolute;
  z-index: 19;
  width: 320px;
  height: 50px;
  background-color: #ffffff;
  filter: progid:DXImageTransform.Microsoft.Shadow(color=#909090, direction=120, strength=4);
  -moz-box-shadow: 2px 2px 5px #909090;
  -webkit-box-shadow: 2px 2px 5px #909090;
  box-shadow: 2px 2px 5px #909090;
  left: 42%;
  top: 40%;
}

.backBorder {
  width: 70%;
  height: 70%;
  border: 2px #979797 solid;
  top: 30px;
  right: 1%;
  position: absolute;
}

.backColor {
  background-color: #438fc7;
  width: 70%;
  height: 70%;
  position: absolute;
  top: 105px;
  right: 10%;
  z-index: 10;
}

.birdImg {
  height: 70%;
  position: absolute;
  z-index: 15;
  top: 75px;
  right: 15%;
  width: 70%;
}

.position {
  overflow: hidden;
  position: relative;
  height: 100%;
}

.restVideo {
  display: flex;
  flex-direction: row;
  justify-content: center;
  height: 100%;
}

.restVideo_item {
  display: flex;
  flex: 0 0 35%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.rest {
  margin-top: 50px;
  height: 500px;
  position: relative;
  margin-bottom: 60px;
}

.restTitle {
  margin: 0;
  padding: 0;
  font-size: 30px;
  margin-bottom: 25px;
}

.employee {
  overflow: hidden;
  position: relative;
}

.employeeVideo {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: stretch;
  margin-top: 5%;
}

.employeeVideo_item div:nth-child(2) {
  width: 100%;
  overflow: hidden;
  position: relative;
}

#word1,
#word2 {
  text-align: left;
  padding: 20px 20px 30px 20px;
}

.employeeVideo_item div:nth-child(1) p {
  margin: 0;
  padding: 0;
  font-family: PingFangSC-Semibold;
  font-size: 16px;
}

.employeeVideo_item div:nth-child(1) span {
  margin: 0;
  padding: 0;
  font-family: PingFangSC-Semibold;
  font-size: 16px;
}

.employeeVideo_item div:nth-child(1) span:nth-child(1) {
  margin: 0;
  padding: 0;
  font-family: PingFangSC-Semibold;
  font-weight: bold;
  font-size: 20px;
}

.employeeVideo_item {
  flex: 0 0 29%;
  display: flex;
  flex-direction: column;
  margin-left: 10px;
  margin-right: 10px;
  overflow: hidden;
  position: relative;
}

.employeePosition {
  top: 100px;
  left: 0px;
  width: 100%;
  z-index: 10;
  position: absolute;
  text-align: center;
}

.employee img {
  width: 100%;
  height: 100%;
  z-index: 11;
}

.employeeTitle {
  width: 100%;
  text-align: center;
  font-size: 30px;
  position: absolute;
  z-index: 10;
  left: 0;
  top: -10px;
  margin-top: 50px;
}

.secContent {
  width: 60%;
  margin: auto;
  margin-bottom: 60px;
}

.secTopRow {
  flex-wrap: wrap;
  display: flex;
}

.secTopRow div span {
  font-family: PingFangSC-Semibold;
  padding-top: 5px;
  font-size: 16px;
}

.secTopRow div {
  flex: 0 0 25%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.us p:nth-child(1) {
  margin: auto;
  font-family: PingFangSC-Light;
  font-size: 30px;
  color: rgba(51, 51, 51, 100);
  padding-top: 25px;
}

.us p:nth-child(2) {
  margin: auto;
  width: 50%;
  font-family: PingFangSC-Light;
  font-size: 16px;
  color: rgba(51, 51, 51, 100);
  padding-top: 54px;
  padding-bottom: 50px;
  text-align: left;
}

.joinBanner {
  overflow: hidden;
  position: relative;
}

.joinBanner p:nth-child(1) {
  padding: 0;
  margin: 0;
  color: rgba(255, 255, 255, 100);
  font-size: 38px;
  text-align: left;

  font-family: PingFangSC-Medium;
}

.joinBanner p:nth-child(2) {
  padding: 0;
  margin: 0;
  color: rgba(255, 255, 255, 100);
  font-size: 40px;
  text-align: left;
  font-weight: bold;
  font-family: PingFangSC-Medium;
}

.joinBanner p:nth-child(3) {
  padding: 0;
  margin: 0;
  color: rgba(255, 255, 255, 100);
  font-size: 25px;
  text-align: left;
  font-family: PingFangSC-Medium;
}

.joinBanner img {
  width: 100%;
  height: 100%;
}

.joinBanner div:nth-child(2) {
  position: absolute;
  z-index: 10;
  top: 150px;
  right: 10%;
}

.joinUsCss {
  height: 850px;

  .joinUsBox {
    margin-top: 50px;
    position: relative;

    //overflow: hidden;
    .joinFour {
      width: 80%;
      position: absolute;
      //width: 1700px;
      left: 50%;

      transform: translateX(-50%);
      /* 水平向左偏移50% */
      //overflow: auto;
      display: flex;
      justify-content: center;
      //justify-content: space-between;
      //gap: 25px;
      //display: grid;
      //grid-auto-flow: column;
      //grid-template-columns: repeat(auto-fit,400px);
      //grid-gap: 25px 15px;
      //justify-content: center;
      z-index: 99;

      .joinCard {
        //overflow: auto;
        cursor: pointer;
        //width: 375px;
        width: 23.4rem;
        //height: 524px;
        height: 32.75rem;
        border-radius: 10px;
        opacity: 1;
        background: #FFFFFF;
        box-sizing: border-box;
        border: 1px solid rgba(0, 0, 0, 0.25);
        padding: 5px;

        .cardName {
          font-size: 30px;
          font-weight: normal;
          text-align: center;
          color: #000000;
          margin-top: 25px;
        }

        .lookDetail {
          font-size: 28px;
          font-weight: 300;
          text-align: center;
          color: #F49A42;
          margin-top: 25px;
        }
      }
    }

    .handBg {
      height: 532px;
      position: absolute;
      width: 100%;
      top: 200px;
      left: 50%;
      /* 水平居中 */
      transform: translateX(-50%);
      /* 水平向左偏移50% */
    }
  }

}

.usV2 {
  margin-top: 100px;

  .closeIntro {
    white-space: pre-wrap;
    margin-top: 30px;
    margin-bottom: 30px;
    font-family: SourceHanSansSC;
    font-size: 18px;
    font-weight: normal;
    line-height: normal;
    text-align: center;
    letter-spacing: 0em;
    color: #001F34;
  }

  .closeUsImgs {
    width: 1600px;
    margin: 0 auto;
    display: grid;
    grid-template-columns: repeat(auto-fit, 400px);
    grid-gap: 40px 110px;
    justify-content: center;
  }
}

.employeeV2 {
  .employeeIntro {
    white-space: pre-wrap;
    margin-top: 30px;
    margin-bottom: 30px;
    font-family: SourceHanSansSC;
    font-size: 18px;
    font-weight: normal;
    line-height: normal;
    text-align: center;
    letter-spacing: 0em;
    color: #001F34;
  }

  .heardBox {
    margin: 0 auto;
    width: 80%;

    .heardSwipe {
      .heardVidio {
        position: relative;

        .lookImg {
          z-index: 10;
        }

        .vidioIcon {
          position: absolute;
          z-index: 99;
          top: 42%;
          left: 50%;
          transform: translate(-50%, -42%);
        }
      }
    }


    .heardPage {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 30px;

      .pre1 {
        display: flex;
        align-items: center;

        .pre1left {
          cursor: pointer;
          font-family: DIN;
          font-size: 20px;
          font-weight: bold;
          text-align: center;
          color: #001F34;
        }

        .pre1Right {
          margin: 0px 150px 0px 30px;
          height: 1px;
          width: 126px;
          background: #000000;
        }
      }

      .pages {
        display: flex;
        font-family: DIN;
        font-size: 20px;
        font-weight: bold;
        text-align: center;
        color: #001F34;

        .pageActive {
          color: #D67D14;
        }
      }

      .next1 {
        display: flex;
        align-items: center;

        .next1left {
          height: 1px;
          width: 126px;
          background: #000000;
          margin: 0px 30px 0px 150px;
        }

        .next1Right {
          cursor: pointer;
          font-family: DIN;
          font-size: 20px;
          font-weight: bold;
          text-align: center;
          color: #001F34;
          display: flex;
          align-items: center;
        }
      }
    }
  }
}

.restV2 {
  .restContent {
    margin: 0 auto;
    width: 80%;
    display: flex;
    justify-content: center;
    margin-top: 30px;

    .restLeft {
      width: 742px;
    }

    .restRight {
      width: 500px;

      .rightName {
        font-size: 36px;
        font-weight: normal;
        color: #001F34;
        margin-top: 50px;
      }

      .rightdetail {
        white-space: pre-wrap;
        //font-size: 30px;
        font-weight: normal;
        color: #001F34;
        margin-top: 30px;
      }

      .rightPage {
        display: flex;
        align-items: center;
        margin: 0 auto;
        margin-top: 120px;
        justify-content: center;

        .pre {
          cursor: pointer;
          font-family: DIN;
          font-size: 20px;
          font-weight: bold;
          text-align: center;
          color: #001F34;
        }

        .diviPage {
          width: 179px;
          height: 1px;
          background: black;
          margin: 0px 40px 0px 40px;
        }

        .next {
          cursor: pointer;
          font-family: DIN;
          font-size: 20px;
          font-weight: bold;
          color: #001F34;
          display: flex;
          align-items: center;
        }
      }
    }
  }
}

.el-carousel__item h3 {
  color: #475669;
  font-size: 14px;
  opacity: 0.75;
  line-height: 200px;
  margin: 0;
}

.el-carousel__item:nth-child(2n) {
  background-color: #99a9bf;
}

.el-carousel__item:nth-child(2n+1) {
  background-color: #d3dce6;
}
</style>
