<template>
    <div class="wave-title">
        <div :style="{ fontSize: titleFontSize, color: textColor }" :class="{ m_title: isMobile }">{{ title }}</div>
        <img src="https://img.i2soft.net/i2official-web/v2/assets/images/home/wave.png" />
        <div :style="{ fontSize: subheadFontSize, color: textColor }" :class="{ m_subhead: isMobile }">{{ subhead }}</div>
    </div>
</template>
<script name="wave-title">
export default {
    name: "WaveTitle",
    props: {
        title: {
            type: String,
            default: ''
        },
        subhead: {
            type: String,
            default: ''
        },
        // 是否为移动端
        isMobile: {
            type: Boolean,
            default: false
        },
        titleSize: {
            type: Number,
            default: 36
        },
        subheadSize: {
            type: Number,
            default: 14
        },
        textColor: {
            type: String,
            default: '#221815'
        }
    },
    computed: {
        titleFontSize() {
            return this.titleSize + 'px';
        },
        subheadFontSize() {
            return this.subheadSize + 'px';
        },
    },
}


</script>
<style lang="less" scoped>
.wave-title {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    div:first-child {
        font-size: 36px;
        font-weight: 700;
        line-height: normal;
        text-align: center;
        letter-spacing: 0em;
        color: #221815;
        white-space: pre-wrap;
    }

    img {
        margin: 12px 0;
    }

    div:last-child {
        font-family: Times New Roman;
        font-size: 14px;
        font-weight: normal;
        line-height: normal;
        white-space: pre-wrap;
    }

    .m_title {
        font-size: 18px !important;
    }

    .m_subhead {
        font-size: 12px !important;
    }
}
</style>