/*
 * @Author: your name
 * @Date: 2021-10-12 15:33:09
 * @LastEditTime: 2025-01-07 11:14:06
 * @LastEditors: lyt
 * @Description: In User Settings Edit
 * @FilePath: /new-i2finance/official_web_portal/official/src/main.js
 */
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
// import './plugins/element.js'
import './assets/css/global.css'
// import animated from './assets/css/animate.css'
// import animated from '../node_modules/animate.css'
import animated from './plugins/animate.css'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import axios from "axios";
import Vant from 'vant';
import 'vant/lib/index.css';

require('./mock');

Vue.config.productionTip = false
Vue.prototype.$axios = axios

Vue.use(ElementUI)
Vue.use(animated)
Vue.use(Vant);

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')

