<template>
  <div class="solveWrapper">
    <div class="pcWrapper" v-if="!isMobile">
      <div class="firstBannerWrapper">
        <img src="https://img.i2soft.net/i2official-web/v2/assets/images/solve/firstback.png" alt="" />
      </div>
      <div class="secondWrapper">
        <div class="secondTitle">
          <WaveTitle title="SQUARE互联网金融2.0整体解决方案" subhead="智能场景构件/微服务架构"></WaveTitle>
        </div>
        <div class="secondContent">
          <div class="secondRow">
            <div class="secondColumn">
              <div class="secondColumnItem" v-for="(item, index) in secondData.slice(0, 2)" :key="index">
                <div class="title">
                  <span>0{{ index + 1 }}</span>
                  <div class="right">
                    <div>{{ item.title }}</div>
                    <div class="line"></div>
                  </div>
                </div>
                <div class="desc">{{ item.desc }}
                </div>
              </div>
            </div>
            <img src="https://img.i2soft.net/i2official-web/v2/assets/images/solve/solution.png" alt="" />
            <div class="secondColumn">
              <div class="secondColumnItem" v-for="(item, index) in secondData.slice(2, 4)" :key="index">
                <div class="title">
                  <span>0{{ index + 3 }}</span>
                  <div class="right">
                    <div>{{ item.title }}</div>
                    <div class="line line-linear-left"></div>
                  </div>
                </div>
                <div class="desc desc-align-right">{{ item.desc }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="thirdWrapper">
        <div class="thirdContent">
          <div class="videoBox">
            <img src="https://img.i2soft.net/i2official-web/v2/assets/images/solve/video/video.png" alt="" />
            <div class="playBtn" @click="videoBtnDidClick">
            </div>
          </div>
        </div>
      </div>
      <div class="forthWrapper">
        <div class="forthTitle">
          <WaveTitle title="我能为您提供的场景和服务" subhead="Scenarios and services"></WaveTitle>
        </div>
        <div class="forthContent">
          <div class="forthRow">
            <div class="forthRowItem" v-for="(item, index) in forthData" :key="index + 20">
              <div class="imageBox">
                <img :src="item.imgUrl" alt="" />
              </div>
              <div class="itemTitle">{{ item.title }}</div>
              <BottomLine />
              <div class="itemBottomName">{{ item.name }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="fifthWrapper">
        <div class="fifthTitle">
          <WaveTitle title="经典案例" subhead="Classic case"></WaveTitle>
        </div>
        <div class="fifthContent">

          <div class="imgBox">
            <img :src="fifthData[currentIndex - 1].imageUrl" alt="" />
          </div>
          <div class="fifth-right">
            <!--  -->
            <div class="fifth-right-content">
              <div class="title">
                <span>0{{ currentIndex }}</span>
                <div class="right">
                  <div>{{ fifthData[currentIndex - 1].title }}</div>
                  <div class="line"></div>
                </div>
              </div>
              <div class="desc">{{ fifthData[currentIndex - 1].desc }}</div>
            </div>
            <div class="steps">
              <div class="step-btn" @click="imgDidClick('PREV')">
                <div class="triangle triangle-left" v-if="currentIndex - 1 !== 0"></div>
                <div>PREV</div>
              </div>
              <div class="line"></div>
              <div class="step-btn" @click="imgDidClick('NEXT')">
                <div>NEXT</div>
                <div class="triangle triangle-rigth" v-if="currentIndex !== fifthData.length"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <PhoneIndex v-else />
    <video-player :videoSrc="videoUrl" />
  </div>

</template>

<script>
import videoPlayer from "@/views/video/index.vue";
import { isMobile } from "@/utils/index";
import WaveTitle from '@/components/WaveTitle.vue';
import BottomLine from '@/components/BottomLine.vue';
import PhoneIndex from './PhoneIndex.vue';

export default {
  components: { videoPlayer, WaveTitle, BottomLine,PhoneIndex },
  data() {
    return {
      isMobile: false,
      videoState: this.$store.state.account.videoState,
      videoUrl:
        "https://img.i2soft.net/%E8%89%BE%E8%9E%8D%E8%BD%AF%E4%BB%B6SQUARE%E5%AE%9E%E6%96%BD%E6%96%B9%E6%B3%95%E8%AE%BA.mp4",
      secondData: [
        {
          title: "产品及解决方案",
          desc: '精准客户营销、互联网支付、 在线贷款、国际业务、结汇售汇、 房产销售、商旅服务、公共政务。'
        },
        {
          title: "数字化安全保障",
          desc: '大客户画像、全息客户视图、 实时营销评估、权威身份认证、 动态威胁感知。'
        },
        {
          title: "数字化系统集成",
          desc: '设备租赁、基础设施、营销、供应链、国际贸易、直销银行、 生活缴费、大宗交易。'
        },
        {
          title: "数字化运营",
          desc: '行长驾驶舱、集团采购、金融电商、动态运营决策、汽车销售、 社交网络、跨境电商、网上消费、 交易银行。'
        },

      ],
      forthData: [
        {
          id: "1",
          title: "渠道类",
          name: "个人手机银行、企业手机银行、个人网银、企业网银、小程序、直销银行",
          imgUrl: "https://img.i2soft.net/i2official-web/v2/assets/images/solve/server/server01.svg",
        },
        {
          id: "2",
          title: "平台类",
          name: "业务能力中台、融资中台、营销中台",
          imgUrl: "https://img.i2soft.net/i2official-web/v2/assets/images/solve/server/server02.svg",
        },
        {
          id: "3",
          title: "开发平台类",
          name: "分布式服务开发平台（FAST）；跨平台前端开发平台-Lark",
          imgUrl: "https://img.i2soft.net/i2official-web/v2/assets/images/solve/server/server03.svg",
        },
        {
          id: "4",
          title: "工具类",
          name: "互联网+可信身份认证平台、互联网+全渠道运营服务平台",
          imgUrl: "https://img.i2soft.net/i2official-web/v2/assets/images/solve/server/server04.svg",
        },
        {
          id: "5",
          title: "运营类",
          name: "银行商城联合运营服务、小程序/公众号/服务号/营销活动/直播/获客展业运营服务",
          imgUrl: "https://img.i2soft.net/i2official-web/v2/assets/images/solve/server/server05.svg",
        },
      ],
      fifthData: [
        {
          id: "01",
          title: "现金管理案例",
          desc: "上海银行现金管理平台",
          imageUrl: "https://img.i2soft.net/i2official-web/assets/images/solve/fifth/fifth_l_1.png",
        },
        {
          id: "02",
          title: "直销银行案例",
          desc: "上海银行  上行快线",
          imageUrl: "https://img.i2soft.net/i2official-web/assets/images/solve/fifth/fifth_l_2.png",
        },
        {
          id: "03",
          title: "金融电商案例",
          desc: "工行“融e购”金融电商平台",
          imageUrl: "https://img.i2soft.net/i2official-web/assets/images/solve/fifth/fifth_l_3.png",
        },
        {
          id: "04",
          title: "现金管理案例",
          desc: "光大银行现金管理云平台",
          imageUrl: "https://img.i2soft.net/i2official-web/assets/images/solve/fifth/fifth_l_4.png",
        },
      ],
      currentIndex: 1,
    };
  },
  computed: {
    showRightSpan() {
      let showResult = 2;
      if (this.m_currentIndex <= 3) {
        showResult = this.m_currentIndex + 1;
      } else {
        showResult = 1;
      }
      return '0' + String(showResult);
    }
  },
  mounted() {
    this.isMobile = isMobile();
    window.addEventListener("scroll", this.getScroll);
  },
  destroyed() {
    window.removeEventListener("scroll", this.getScroll);
  },
  methods: {
    videoBtnDidClick() {
      this.$store.commit("TO_VIDEOS");
    },
    imgDidClick(type) {
      if (type === 'PREV') {
        if (this.currentIndex === 1) {
          this.currentIndex = 4;
        } else {
          this.currentIndex--;
        }
      } else {
        if (this.currentIndex === 4) {
          this.currentIndex = 1;
        } else {
          this.currentIndex++;
        }
      }
    },
    getScroll() {
      this.scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      if (!!document.documentElement.scrollTop && document.documentElement.scrollTop >= 70
      ) {
        this.$store.commit("TO_AfterScroll");
      } else {
        this.$store.commit("TO_AfterScroll_false");
      }
    },
  },
};
</script>

<style lang="less" scoped>
.solveWrapper {
  background: #fff;

  .pcWrapper {
    .firstBannerWrapper {
      position: relative;

      img {
        margin: 0 auto;
        display: block;
        width: 100%;
        height: auto;
      }

      .textBox {
        position: absolute;
        top: 212px;
        right: 10%;
        width: 460px;
        height: 210px;
        // background: tomato;
        text-align: right;

        .firstRow1 {
          color: #034579;
          font-size: 40px;
          font-weight: bold;
        }

        .firstRow2 {
          color: #0497d1;
          font-weight: bold;
          font-size: 40px;
        }

        .firstRow3 {
          color: #0497d1;
          font-size: 30px;
        }
      }
    }

    .secondWrapper {
      padding: 96px 0;

      .secondTitle {
        margin-bottom: 80px;
      }

      .secondContent {
        .secondRow {

          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: center;
          column-gap: 100px;

          .secondColumn {
            display: flex;
            flex-direction: column;
            row-gap: 120px;

            .secondColumnItem {
              display: flex;
              flex-direction: column;
              align-items: center;
              row-gap: 24px;

              .title {
                font-size: 36px;
                display: flex;
                flex-direction: row;
                margin-bottom: 24px;

                .right {
                  margin-left: 8px;
                  text-align: left;
                }

                .line {
                  width: 100%;
                  margin-top: 4px;
                  height: 4px;
                  background: linear-gradient(to right, #fff, #B08D57);
                }

                .line-linear-left {
                  background: linear-gradient(to left, #fff, #B08D57);
                }
              }

              .desc {
                width: 300px;
                font-size: 18px;
                text-align: right;
                line-height: normal;
              }

              .desc-align-right {
                text-align: left;
              }


            }
          }
        }
      }
    }

    .thirdWrapper {
      background: url("https://img.i2soft.net/i2official-web/v2/assets/images/solve/video/videoBack.png");
      padding: 40px 0;

      .thirdContent {
        .videoBox {
          width: 80%;
          position: relative;
          margin: 0 auto;
          cursor: pointer;

          img {
            width: 100%;
            height: auto;
          }

          .playBtn {
            width: 172px;
            height: 172px;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);

            img {
              width: 100%;
              height: 100%;
            }
          }
        }
      }
    }

    .forthWrapper {
      width: 100%;
      padding: 40px 0;

      .forthTitle {
        text-align: center;
        font-size: 30px;
        margin-bottom: 50px;
      }

      .forthContent {
        width: 80%;
        margin: 24px auto;

        .forthRow {
          display: flex;
          justify-content: space-around;
          font-size: 14px;
          text-align: center;

          .forthRowItem {
            width: 17%;
            cursor: pointer;
            .itemTitle {
              font-size: 18px;
              margin-top: 20px;
            }

            .itemBottomName {
              margin-top: 20px;
              height: 48px;
              font-size: 13px;
              margin-left: 5px;
              margin-right: 5px;
              text-align: center;
              opacity: 0;
              // background: red;
              // word-break:break-all;
            }

            .imageBox {
              width: 100%;
              height: 140px;
              display: flex;
              justify-content: center;

              img {
                margin-top: 25px;
                width: 120px;
                height: 120px;
              }
            }
          }

          .forthRowItem:hover{
            .itemBottomName {
              opacity: 1;
              // background: red;
              // word-break:break-all;
            }
          }
        }
      }
    }


    .fifthWrapper {
      padding: 66px 0;
      background: #F1F1F1;

      .fifthTitle {
        margin-bottom: 80px;
      }

      .fifthContent {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: center;
        column-gap: 100px;

        .imgBox {
          width: 710px;
          height: 400px;

          img {
            width: 100%;
            height: 100%;
          }
        }

        .fifth-right {
          // width: 23.5%;
          // height: 100%;
          // background: rebeccapurple;
          // float: right;
          // display: flex;
          // position: relative;
          display: flex;
          flex-direction: column;
          justify-content: space-between;

          .fifth-right-content {
            .title {
              font-size: 36px;
              display: flex;
              flex-direction: row;
              align-items: center;
              margin-bottom: 24px;


              .right {
                margin-left: 8px;
                text-align: left;
              }

              .line {
                width: 100%;
                margin-top: 4px;
                height: 4px;
                background: linear-gradient(to left, #fff, #B08D57);
              }

            }

            .desc {
              text-align: left;
              font-size: 14px;
              font-weight: 300;
              line-height: normal;
              letter-spacing: 0em;
              color: #001F34;
            }
          }


          .steps {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            column-gap: 40px;

            .step-btn {
              padding: 10px;
              cursor: pointer;
              display: flex;
              flex-direction: row;
              align-items: center;
              column-gap: 15px;

              .triangle {
                width: 0;
                height: 0;
                border-top: 4px solid transparent;
                border-bottom: 4px solid transparent;
              }

              .triangle-left {
                border-right: 6px solid #6B6764;
              }

              .triangle-rigth {
                border-left: 6px solid #6B6764;
              }
            }

            .line {
              width: 242px;
              // height: 0px;
              height: 1px;
              background-color: #413D39;
              // border: 1px solid #413D39;
            }

          }

          img {
            width: 100%;
            height: auto;
          }

          .indexSpan {
            position: absolute;
            bottom: 45px;
            width: 33.33%;
            z-index: 100;
            font-size: 30px;
            color: #333;
          }

          .indexSpan_1 {
            left: 0;
          }

          .indexSpan_2 {
            left: 33.33%;
          }

          .indexSpan_3 {
            left: 66.66%;
          }
        }
      }
    }
  }
}
</style>
