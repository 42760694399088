import { render, staticRenderFns } from "./SocialRecruitmentComp.vue?vue&type=template&id=20ac11fa&scoped=true"
import script from "./SocialRecruitmentComp.vue?vue&type=script&lang=js"
export * from "./SocialRecruitmentComp.vue?vue&type=script&lang=js"
import style0 from "./SocialRecruitmentComp.vue?vue&type=style&index=0&id=20ac11fa&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/.pnpm/vue-loader@15.11.1_@vue+compiler-sfc@3.5.13_cache-loader@4.1.0_webpack@4.47.0__css-loader@3.6_ckru7f3yypsoan6edyu23zfbiq/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "20ac11fa",
  null
  
)

export default component.exports