<!--
 * @Author: lyt
 * @Date: 2024-12-24 15:30:08
 * @LastEditTime: 2025-01-07 15:33:38
 * @LastEditors: lyt
 * @Description: lyt
 * @FilePath: /new-i2finance/official_web_portal/official/src/components/BottomLine.vue
 *  
-->
<template>
    <div class="line-group" :class="{ 'm_line_group': isMobile, 'small_line_group': size === 'small' }">
        <div class="line1"></div>
        <div class="line2"></div>
    </div>
</template>
<script name="wave-title">
export default {
    name: "BottomLine",
    props: {
        title: {
            type: String,
            default: ''
        },
        subhead: {
            type: String,
            default: ''
        },
        // 是否为移动端
        isMobile: {
            type: Boolean,
            default: false
        },
        // 尺寸 normal | small
        size: {
            type: String,
            default: 'normal',
            validator: function (value) {
                return ['normal', 'small'].includes(value);
            }
        }
    },
}
</script>
<style lang="less" scoped>
.line-group {
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 8px;
    margin: 16px 0;

    div:first-child {
        width: 38px;
        border: 2px solid #B08D57;
    }

    div:last-child {

        width: 20px;
        border: 2px solid #B08D57;
    }

}

.m_line_group {
    row-gap: 4px;
    margin: 12px 0;

    div {
        border: 1px solid #B08D57 !important;
    }
}

.small_line_group {
    row-gap: 3px;
    margin: 4px 0;

    div:first-child {
        width: 16px;
        border: 1px solid #B08D57;
    }

    div:last-child {

        width: 9px;
        border: 1px solid #B08D57;
    }
}
</style>