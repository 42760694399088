<template>
	<el-container direction="vertical">
		<div
			class="pcContainer"
			v-if="!isMobile"
		>
			<el-header>
				<div class="left-menu">
					<div
						class="left_logo"
						@click="goIndex"
					>
						<img
							src="https://img.i2soft.net/i2official-web/assets/images/nav/navLogo.png"
							alt=""
						/>
						<div style="font-size: 20px; font-weight: normal; line-height: 20px; letter-spacing: 0px; color: #333333; margin-left: 20px; width: 250px"> 证券代码830799</div>
					</div>
					<div class="home-navmenu">
						<el-menu
							mode="horizontal"
							:default-active="activeIndex"
							router
							@select="handleSelect"
						>
							<el-menu-item index="/first">
								<div class="menu-item">
									<span>首页</span>
									<div
										class="line-group"
										v-if="activeIndex === '/first'"
									>
										<div class="line1"></div>
										<div class="line2"></div>
									</div>
								</div>
							</el-menu-item>
							<el-menu-item index="/introduce">
								<div class="menu-item">
									<span>公司介绍</span>
									<div
										class="line-group"
										v-if="activeIndex === '/introduce'"
									>
										<div class="line1"></div>
										<div class="line2"></div>
									</div>
								</div>
							</el-menu-item>
							<el-menu-item index="/solve">
								<div class="menu-item">
									<span>解决方案</span>
									<div
										class="line-group"
										v-if="activeIndex === '/solve'"
									>
										<div class="line1"></div>
										<div class="line2"></div>
									</div>
								</div>
							</el-menu-item>
							<el-menu-item index="/dynamic">
								<div class="menu-item">
									<span>公司动态</span>
									<div
										class="line-group"
										v-if="activeIndex === '/dynamic'"
									>
										<div class="line1"></div>
										<div class="line2"></div>
									</div>
								</div>
							</el-menu-item>
							<el-menu-item index="/relations">
								<div class="menu-item">
									<span>投资者关系</span>
									<div
										class="line-group"
										v-if="activeIndex === '/relations'"
									>
										<div class="line1"></div>
										<div class="line2"></div>
									</div>
								</div>
							</el-menu-item>
							<el-menu-item index="/joinUs">
								<div class="menu-item">
									<span>加入我们</span>
									<div
										class="line-group"
										v-if="activeIndex === '/joinUs'"
									>
										<div class="line1"></div>
										<div class="line2"></div>
									</div>
								</div>
							</el-menu-item>
						</el-menu>
					</div>
					<!--                    <div class="">-->
					<!--                        <img src="" alt="" />-->
					<!--                    </div>-->
				</div>
			</el-header>
			<el-main style="padding: 0; margin-top: 1px; background-color: #eff0f4; width: 100%">
				<router-view></router-view>
				<Footer @footerLinkClick="footerLinkClick"></Footer>
			</el-main>
		</div>
		<PhoneHome v-else />
	</el-container>
</template>

<script>
	import { isMobile } from '@/utils/index';
	import Footer from '@/components/footer.vue';
	import PhoneHome from './PhoneHome.vue';

	export default {
		components: { Footer, PhoneHome },
		data() {
			return {
				activeIndex: '',
				isMobile: false,
				isMenuShow: false,
				active: '',
				block: '',
			};
		},
		created() {
			// this.activeIndex = '/first';
		},
		mounted() {
			this.activeIndex = '/first';
			this.isMobile = isMobile();
			console.log('this.isMobile', this.isMobile);
		},
		computed: {
			is_after_scroll() {
				return this.$store.state.account.afterScroll;
			},
		},
		watch: {},
		methods: {
			handleSelect(index) {
				console.log('index', index);

				this.activeIndex = index;
				this.isMenuShow = false;
			},
			getPath() {
				this.activeIndex = this.$route.path;
			},
			modelRightMenu() {
				this.isMenuShow = true;
			},
			hideMenu() {
				this.isMenuShow = false;
			},
			// 底部-快速链接
			footerLinkClick(info) {
				this.$router.push({ path: info.path });
				this.activeIndex = info.path;
				this.isMenuShow = false;
			},
			goIndex() {
				this.$router.push({ path: '/first' });
				this.activeIndex = '/first';
			},
		},
	};
</script>

<style lang="less" scoped>
	.pcContainer {
		width: 100%;
		position: relative;

		.el-header {
			position: fixed;
			top: 0;
			left: 0;
			width: 100%;
			z-index: 100;
			overflow: hidden;
			background-color: #fff;
			// background-color: green;
			height: 100px !important;
			display: flex;
			align-items: center;

			.left-menu {
				width: 75%;
				height: 100%;
				margin: 0 auto;
				// background: purple;
				display: flex;
				//flex-direction: row-reverse;
				justify-content: space-between;
				.left_logo {
					cursor: pointer;
					//width: 30%;
					display: flex;
					// background: tomato;
					text-align: left;
					justify-content: center;
					align-items: center;
					font-size: 16px;

					img {
						width: 151px;
						height: 40px;
					}

					.textSpan {
						margin-left: 24px;
						// background: hotpink;
					}
				}
				.home-navmenu {
					//width: 65%;
					height: 100%;
					//padding-right: 24px;
					//margin-left: 680px;
					// background-color: peru;
					display: flex;
					// align-items: center;
					align-items: flex-end;

					.el-menu--horizontal {
						width: 100%;
						border-bottom: none;
						.el-menu-item {
							font-size: 18px;
						}

						.menu-item {
							display: flex;
							flex-direction: column;
							row-gap: 8px;

							.line-group {
								display: flex;
								flex-direction: column;
								align-items: center;
								row-gap: 8px;

								div:first-child {
									width: 38px;
									border: 1px solid #b08d57;
								}

								div:last-child {
									width: 20px;
									border: 1px solid #b08d57;
								}
							}
						}

						.el-menu-item.is-active {
							color: #b08d57;
							font-weight: 700;
							border: none;
						}
					}

					ul {
						// background: red;
						display: flex;

						li {
							list-style-type: none;
							margin: 0 25px;
							padding: 0;
						}
					}
				}
			}
		}

		.el-main {
			position: absolute;
			top: 80px;
			left: 0px;
		}

		/deep/ .el-menu--horizontal > .el-menu-item {
			// height: 80px;
			line-height: normal;
		}
	}

	// @media screen and (max-width: 479px) {
	//     .m_wrapper {
	//         width: 100%;

	//         .top-menu {
	//             // background: red;
	//             width: 100vw;
	//             height: 60px;
	//             position: fixed;
	//             left: 0;
	//             top: 0;
	//             z-index: 5;
	//             display: flex;
	//             justify-content: space-between;
	//             align-items: center;

	//             &.showMenuActive {
	//                 z-index: 2;
	//                 background: transparent;
	//                 opacity: 0.1;
	//             }

	//             .left_logo {
	//                 width: 114px;
	//                 height: 30px;
	//                 margin-left: 20px;
	//             }

	//             .right_menu {
	//                 display: flex;
	//                 justify-content: center;
	//                 align-items: center;
	//                 width: 50px;
	//                 height: 50px;
	//                 margin-right: 20px;

	//                 // background: blue;
	//                 // float: right;
	//                 .right_menuImg {
	//                     width: 20px;
	//                     height: 16px;
	//                     // background: chocolate;
	//                 }
	//             }
	//         }

	//         .after-scrollMenu {
	//             width: 100vw;
	//             height: 60px;
	//             position: fixed;
	//             left: 0;
	//             top: 0;
	//             z-index: 5;
	//             display: flex;
	//             background: #FFF;
	//             box-shadow: 0px 1px 5px 1px rgba(0, 0, 0, 0.2);
	//             justify-content: space-between;
	//             align-items: center;

	//             .left_logo {
	//                 width: 114px;
	//                 height: 30px;
	//                 margin-left: 20px;

	//                 img {
	//                     width: 115px;
	//                     height: 30px;
	//                 }
	//             }

	//             .right_menu {
	//                 display: flex;
	//                 justify-content: center;
	//                 align-items: center;
	//                 width: 50px;
	//                 height: 50px;
	//                 margin-right: 20px;

	//                 // background: blue;
	//                 // float: right;
	//                 .right_menuImg {
	//                     width: 20px;
	//                     height: 16px;
	//                     // background: chocolate;
	//                 }
	//             }
	//         }

	//         .fixMenu {
	//             // display: ;
	//             position: fixed;
	//             left: 0;
	//             right: 0;
	//             top: 0;
	//             bottom: 0;
	//             background: rgba(255, 255, 255, 88%);
	//             // background: green;
	//             z-index: 7;
	//             opacity: 1;

	//             .modelTopWraper {
	//                 width: 100%;
	//                 height: 60px;
	//                 // background: tomato;
	//                 display: flex;
	//                 justify-content: space-between;
	//                 align-items: center;

	//                 .leftTop {
	//                     margin-left: 20px;

	//                     img {
	//                         width: 115px;
	//                         height: 30px;
	//                     }
	//                 }

	//                 .crossDiv {
	//                     width: 40px;
	//                     height: 40px;
	//                     display: inline-block;
	//                     display: flex;
	//                     justify-content: center;
	//                     align-items: center;
	//                     // background: blueviolet;
	//                     margin-right: 20px;

	//                     img {
	//                         width: 18px;
	//                         height: 18px;
	//                     }
	//                 }
	//             }

	//             .middleContent {
	//                 // background: cadetblue;
	//                 margin-top: 60px;

	//                 .el-menu {
	//                     .el-menu-item {
	//                         border-bottom: 1px solid #E0E0E0;
	//                         padding: 0 15px !important;
	//                         display: flex;
	//                         justify-content: space-between;
	//                         align-items: center;

	//                         img {
	//                             width: 6px;
	//                             height: 8px;
	//                         }
	//                     }

	//                     .routeItem {
	//                         font-size: 14px;
	//                         color: #333;
	//                         height: 60px;

	//                         span {
	//                             margin-left: 15px;
	//                         }
	//                     }
	//                 }

	//             }

	//             .fix_bottom {
	//                 position: absolute;
	//                 bottom: 0;
	//                 left: 0;
	//                 width: 100%;
	//             }
	//         }

	//     }

	// }
</style>
