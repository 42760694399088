<!--
 * @Author: lyt
 * @Date: 2024-10-22 01:34:44
 * @LastEditTime: 2025-01-17 14:24:08
 * @LastEditors: lyt
 * @Description: 移动端-投资者关系-业绩报告模块
 * @FilePath: /new-i2finance/official_web_portal/official/src/components/phone/AchievementComp.vue
 *  
-->
<template>
	<div class="m-acv-wrapper">
		<div class="m-acv-wrapper-item">
			<div class="m-acv-wrapper-item-title">
				<WaveTitle
					title="公司年报"
					subhead="Company annual report"
					isMobile
				/>
			</div>
			<div class="m-acv-wrapper-item-content">
				<div class="card-box">
					<div
						class="card-box-item"
						v-for="annual in annualList"
						:key="annual.id"
					>
						<a
							:href="annual?.to"
							target="_blank"
							r
						>
							<div class="top">
								<div class="year">
									<span>{{ annual?.year }}</span>
									<span>年</span></div
								>
								<div class="type">{{ annual?.reportType }}</div>
								<div class="text-en">Annual Report</div>
							</div>
							<div class="bottom">上海艾融软件股份有限公司</div>
						</a>
					</div>
				</div>
			</div>
		</div>
		<div class="m-acv-wrapper-item">
			<div class="m-acv-wrapper-item-title">
				<WaveTitle
					title="公司中报"
					subhead="Company interim report"
					isMobile
				/>
			</div>
			<div class="m-acv-wrapper-item-content">
				<div class="card-box">
					<div
						class="card-box-item"
						v-for="inter in InterimList"
						:key="inter.id"
					>
						<a
							:href="inter?.to"
							target="_blank"
							r
						>
							<div class="top">
								<div class="year">
									<span>{{ inter?.year }}</span>
									<span>年</span></div
								>
								<div class="type">{{ inter?.reportType }}</div>
								<div class="text-en">Interim Report</div>
							</div>
							<div class="bottom">上海艾融软件股份有限公司</div>
						</a>
					</div>
				</div>
			</div>
		</div>
		<div class="m-acv-wrapper-item">
			<div class="m-acv-wrapper-item-title">
				<WaveTitle
					title="公司季报"
					subhead="Company quarterly report"
					isMobile
				/>
			</div>
			<div class="m-acv-wrapper-item-content">
				<div class="card-box">
					<div
						class="card-box-item"
						v-for="quart in QuarterlyList"
						:key="quart.id"
					>
						<a
							:href="quart?.to"
							target="_blank"
							r
						>
							<div class="top">
								<div class="year">
									<span>{{ quart?.year }}</span>
									<span>年</span></div
								>
								<div class="type">{{ quart?.reportType }}</div>
								<div class="text-en">Quarterly Report</div>
							</div>
							<div class="bottom">上海艾融软件股份有限公司</div>
						</a>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import WaveTitle from '@/components/WaveTitle.vue';

	export default {
		name: 'AchievementComp',
		components: { WaveTitle },
		props: {},
		data() {
			return {
				// 年报
				annualList: [],
				// 中报
				InterimList: [],
				// 季报
				QuarterlyList: [],
				datas: [],
				query_type: '',
				page: {
					totle: '',
					currentPage: 2,
					pageSize: 8,
				},
				staic: '',
			};
		},
		filters: {},
		created() {
			this.getachievent();
			this.query_type = this.$route.query.category;
			console.log('this.query_type', this.query_type);
			window.scrollTo(0, 540);
		},
		mounted() {
			window.addEventListener('scroll', this.getScroll);
		},
		destroyed() {
			window.removeEventListener('scroll', this.getScroll);
		},
		methods: {
			// 获取业绩表
			getachievent() {
				// 年报业报
				this.$axios.get('/parameter/annualList').then((res) => {
					this.annualList = getHandelData(res.data.data);
					console.log('年报业报', this.annualList);
				});
				// 中报业报
				this.$axios.get('/parameter/InterimList').then((res) => {
					this.InterimList = getHandelData(res.data.data);
					console.log('中报业报', this.InterimList);
				});
				// 季报业报
				this.$axios.get('/parameter/QuarterlyList').then((res) => {
					this.QuarterlyList = getHandelData(res.data.data);
					console.log('季报业报', this.QuarterlyList);
				});

				const getHandelData = (data) => {
					if (!data || (data && data.length <= 0)) {
						return [];
					}
					data.forEach((item) => {
						// 截取字符串
						const name = item.name;
						const companyName = '上海艾融软件股份有限公司';
						const pdfSuffix = '.pdf';
						// 找到公司名称的结束位置
						const startIndex = name.indexOf(companyName) + companyName.length;
						// 找到 .pdf 的开始位置
						const endIndex = name.indexOf(pdfSuffix);
						// 截取中间部分并添加换行符
						if (startIndex !== -1 && endIndex !== -1) {
							let title = name.substring(startIndex, endIndex);
							// 找到年份并添加换行符
							const yearMatch = title.match(/\d{4}年/);
							if (yearMatch) {
								const year = yearMatch[0];
								item.year = year.replace('年', '');
								item.reportType = title.replace(year, '');
							}
						}
					});
					return data;
				};
			},
			getScroll() {
				this.scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
				if (!!document.documentElement.scrollTop && document.documentElement.scrollTop >= 70) {
					this.$store.commit('TO_AfterScroll');
				} else {
					this.$store.commit('TO_AfterScroll_false');
				}
			},
		},
	};
</script>

<style lang="less" scoped>
	* {
		-moz-box-sizing: border-box;
		box-sizing: border-box;
		-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
	}

	.m-acv-wrapper {
		padding: 0px 10px;
		padding-bottom: 22px;
		.m-acv-wrapper-item {
			.m-acv-wrapper-item-title {
				padding: 27px 0px;
			}
			.m-acv-wrapper-item-content {
				.card-box {
					display: grid;
					grid-template-columns: repeat(2, 1fr);
					grid-gap: 10px;

					.card-box-item {
						width: 100%;
						padding: 14px 20px;
						background: #ffffff;
						box-sizing: border-box;
						border: 0.5px solid rgba(0, 0, 0, 0.1);
						box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.1);

						.top {
							display: flex;
							flex-direction: column;
							row-gap: 4px;
							padding: 20px;
							font-weight: normal;
							line-height: normal;
							text-align: center;
							letter-spacing: 0em;
							color: #001f34;
							.year {
								font-size: 24px;
								font-weight: 700;
								span:last-child {
									font-weight: 400;
								}
							}
							.type {
								font-size: 18px;
								font-weight: 400;
							}
							.text-en {
								font-size: 12px;
								color: #f49a42;
							}
						}
						.bottom {
							font-size: 11px;
							font-weight: 250;
							color: #001f34;
						}
					}

					.card-box-item:hover {
						background: #f49a42;
						.top {
							color: #fff;
						}
						.bottom {
							color: #fff;
						}
						.text-en {
							color: #fff;
						}
					}
					.card-box-item:active {
						background: #f49a42;
						.top {
							color: #fff;
						}
						.bottom {
							color: #fff;
						}
						.text-en {
							color: #fff;
						}
					}
				}
			}
		}
	}
</style>
