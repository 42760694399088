<template>
    <div class="container">
        <div v-if="!isMobile" class="pcWrapper">
            <div class="relationsWrapper">
                <div class="bannerWrapper">
                    <img src="https://img.i2soft.net/i2official-web/v2/assets/images/dynamic/cmpDynamicBg.png" />
                </div>
                <div class="mapWrapper">
                    <div style="width: 80%;position: relative;margin: 0 auto">
                        <div class="annoce_box">
                            <div class="inner-tit center gray">
                                <div class="secontTitle">
                                    <WaveTitle :title="'公司动态'" :subhead="'Dynamic state'"></WaveTitle>
                                </div>
                            </div>
                            <div class="annonce_borderv2" v-for="dyn in dynamicList" :key="dyn.id">
                                <a :href="dyn.url" target="_blank" class="Allv2">
                                    <div class="annonceIn">
                                        <div class="annonceInLeft">
                                            <span class="num">{{ dyn.num }}</span>
                                        </div>
                                        <div class="annonceInRight">
                                            <div class="cell_v2">
                                                <h3 class="h3_v2">{{ dyn.content }}</h3>
                                                <div class="info_v2">
                                                    {{ dyn.url }}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { isMobile } from "@/utils/index";
import WaveTitle from '@/components/WaveTitle.vue';
export default {
    components: { WaveTitle },
    data() {
        return {
            isMobile: false,
            keyWords: '',
            dynamicList: [],
        };
    },
    created() {
        this.getData();
    },
    mounted() {
        this.isMobile = isMobile();
        window.addEventListener("scroll", this.getScroll);
    },
    destroyed() {
        window.removeEventListener("scroll", this.getScroll);
    },
    methods: {
        //获取数据并处理
        getData() {
            this.$axios.get("/parameter/dynamicList").then((res) => {
                this.dynamicList = res.data.data;
                console.log("dynamicList", this.dynamicList)
            })
        },
        // 切换tab
        handleClick(tab, event) {
            console.log(tab, event);
        },
        getScroll() {
            // this.indexNumTop = $("#index_num").offset().top;
            this.scrollTop =
                window.pageYOffset ||
                document.documentElement.scrollTop ||
                document.body.scrollTop;
            if (!!document.documentElement.scrollTop && document.documentElement.scrollTop >= 70
            ) {
                //设置滚动大于300时的执行内容
                // console.log(
                //   "document.documentElement.scrollTop=",
                //   document.documentElement.scrollTop
                // );
                this.$store.commit("TO_AfterScroll");
            } else {
                this.$store.commit("TO_AfterScroll_false");
            }
        },
    },
};
</script>

<style lang="less" scoped>
* {
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.relationsWrapper {
    background-color: #ffffff;

    .bannerWrapper {
        width: 100%;
        position: relative;

        img {
            width: 100%;
            //height: auto;
            height: 100%;
        }

        .bannerTextWrapper {
            position: absolute;
            top: 45%;
            left: 5%;
            display: flex;
            flex-direction: column;

            .bannerText1 {
                //color: rgba(255, 255, 255, 100);
                //font-size: 35px;
                //text-align: left;
                //font-family: PingFangSC-Light;
                font-family: Source Han Serif CN;
                font-size: 72px;
                font-weight: 900;
                line-height: normal;
                letter-spacing: 0em;
                text-align: left;
                color: #006291;
            }

            .bannerText2 {
                //color: rgba(255, 255, 255, 100);
                //font-size: 40px;
                //text-align: left;
                //font-family: PingFangSC-Medium;
                font-family: AlibabaPuHuiTi;
                font-size: 36px;
                font-weight: normal;
                line-height: normal;
                letter-spacing: 0em;
                text-align: left;
                color: #296995;
            }

            .bannerText3 {
                color: rgba(255, 255, 255, 100);
                font-size: 25px;
                text-align: left;
                font-family: PingFangSC-Light;
            }
        }
    }

    .announcementWrapper {
        .top_box {
            background-color: rgba(246, 246, 246, 1);
            position: relative;
            padding: 1px 0px;
            height: 50px;
            top: -6px;
        }
    }

    .mapWrapper {
        display: flex;
        flex-direction: column;
        margin: auto;
        padding: 0px;

        /*公司动态*/
        .annoce_box {
            margin-bottom: 20px;
            margin-top: 20px;
            width: 100%;
            height: 100%;

            .center {
                text-align: center;

                h3 {
                    color: #333333;
                    font-size: 26px;
                }
            }

            .annonce_border {
                background-color: rgba(242, 243, 242, 1);
                margin-top: 10px;
            }

            .annonce_border .All {
                display: flex;
                width: 100%;
                ;
                text-decoration: none;
                color: #333;
                margin: 0;
                padding: 0;
            }

            .All:link {
                a {
                    color: rgba(37, 94, 162, 100);
                }
            }

            .All:visited {
                a {
                    color: rgba(37, 94, 162, 100);
                }
            }

            .All:hover {
                a {
                    content: '';
                    color: #FFF;
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    background-color: rgba(53, 135, 195, 1);
                }
            }

            .left_box {
                width: 120px;
                height: 120px;
                padding: 20px;
                position: relative;

                .date_box {
                    margin-top: 10px;
                    margin-left: 10px;

                    .num {
                        font-size: 40px;
                        margin-bottom: 10px;
                        font-family: 'ziticqtezhanti';
                    }
                }
            }

            .right_box {
                width: calc(100% - 122px);
                margin-left: 2px;
                position: relative;
                padding: 0 0 0 3.125vw;
            }

            .left_box i {
                font-style: normal;
                font-size: 40px;
                line-height: 1;
                font-family: 'ziticqtezhanti';
                display: block;
                position: relative;
                transition: color .3s ease;
            }

            .left_box span {
                position: absolute;
                left: 20px;
                right: 20px;
                bottom: 20px;
                font-size: 18px;
                font-family: 'NeutraTextLight';
                transition: color .3s ease;
            }

            .content_box {
                background-color: #FFF;
                width: 0.5vw;
            }

            .cell_box {
                height: 100%;
                position: relative;
                text-align: left;
                margin-top: 1.5%;
                display: flex;
            }

            .cell_box .cell {
                width: 75%;
                display: inline-block;
                vertical-align: middle;
            }

            .h3 {
                font-size: 15px;
                transition: color .3s ease;
            }

            .info {
                font-size: 80%;
                margin-bottom: 15px;
                color: rgba(37, 125, 159, 100);
            }

            .cell_box .cell span {
                margin-right: 20px;
            }

            .pdf_img {
                width: 20%;
                height: 100%;
                position: relative;
                margin-right: 3%;
                text-align: right;
            }

            .pdf_img img {
                width: 50px;
                height: 50px;
            }

            .annonce_borderv2 {
                background-color: rgba(242, 243, 242, 1);
                margin-top: 10px;
                padding: 22px;
            }

            .annonce_borderv2:hover {
                background-image: url('https://img.i2soft.net/i2official-web/v2/assets/images/dynamic/jhk.png');
                background-size: cover;
                //background-position: center;
                background-repeat: no-repeat;

                .Allv2 {
                    .annonceIn {
                        .annonceInLeft {
                            background: #F3D8B8;
                            font-weight: 700;

                            num {
                                font-size: 38px;
                                font-weight: 700;
                            }
                        }
                    }
                }

            }

            .annonce_borderv2 .Allv2 {
                display: flex;
                width: 100%;
                text-decoration: none;
                color: #333;
                margin: 0;
                padding: 0;
            }


            .annonceIn {
                display: flex;

                .annonceInLeft {
                    width: 106px;
                    height: 114px;
                    background-image: url('https://img.i2soft.net/i2official-web/v2/assets/images/dynamic/mask_group.png');
                    background-size: cover;
                    background-position: center;
                    background-repeat: no-repeat;
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    .num {
                        font-size: 38px;
                        color: #1C1E50;
                        //color: red;
                    }
                }

                .annonceInRight {
                    margin-left: 25px;

                    .cell_v2 {
                        .h3_v2 {
                            font-size: 24px;
                            transition: color .3s ease;
                            font-weight: bold;
                            text-align: left;
                            color: #383838;
                        }

                        .info_v2 {
                            text-align: left;
                            font-size: 20px;
                            margin-bottom: 15px;
                            color: #F49A42;
                        }
                    }
                }

            }



        }
    }
}

.dynamicTopBg {
    background-image: url("https://img.i2soft.net/i2official-web/v2/assets/images/dynamic/cmpDynamicBg.png");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    height: 926px;
}
</style>
