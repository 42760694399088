<!--
 * @Author: lyt
 * @Date: 2024-10-22 01:02:00
 * @LastEditTime: 2025-01-20 10:30:15
 * @LastEditors: lyt
 * @Description: 移动端-投资者关系-公司治理模块
 * @FilePath: /new-i2finance/official_web_portal/official/src/components/phone/GovernmentComp.vue
 *  
-->
<template>
	<div class="m-gov-wrapper">
		<!-- 人事管理 -->
		<div class="gov-firstWrapper">
			<div class="list">
				<div class="item">
					<h3>董事会</h3>
					<div class="block">
						<div class="box">
							<h4 class="h4">张岩先生</h4>
							<h3 class="h3">董事长、总经理</h3>
						</div>
						<div class="box">
							<h4 class="h4"> 吴臻女士</h4>
							<h3 class="h3">董事、副总经理</h3>
						</div>
						<div class="box">
							<h4 class="h4">杨光润先生</h4>
							<h3 class="h3">董事、副总经理</h3>
						</div>
						<div class="box">
							<h4 class="h4">张甫先生</h4>
							<h3 class="h3">董事</h3>
						</div>
						<div class="box">
							<h4 class="h4">王涛先生</h4>
							<h3 class="h3">董事</h3>
						</div>
						<div class="box">
							<h4 class="h4">周忠恳先生</h4>
							<h3 class="h3">董事</h3>
						</div>
						<div class="box">
							<h4 class="h4">周宁女士</h4>
							<h3 class="h3">独立董事</h3>
						</div>
						<div class="box">
							<h4 class="h4">谭毓安先生</h4>
							<h3 class="h3">独立董事</h3>
						</div>
						<div class="box">
							<h4 class="h4">朱以林先生</h4>
							<h3 class="h3">独立董事</h3>
						</div>
					</div>
				</div>
				<div class="item">
					<h3>监事会</h3>
					<div class="block">
						<div class="box">
							<h4 class="h4">柳红芳女士</h4>
							<h3 class="h3">监事会主席</h3>
						</div>
						<div class="box">
							<h4 class="h4">胡超先生</h4>
							<h3 class="h3">监事</h3>
						</div>
						<div class="box">
							<h4 class="h4">彭小波先生</h4>
							<h3 class="h3">监事</h3>
						</div>
					</div>
				</div>
				<div class="item">
					<h3>高级管理层</h3>
					<div class="block">
						<div class="box">
							<h4 class="h4">张岩先生</h4>
							<h3 class="h3">董事长、总经理</h3>
						</div>
						<div class="box">
							<h4 class="h4">吴臻女士</h4>
							<h3 class="h3">董事、副总经理</h3>
						</div>
						<div class="box">
							<h4 class="h4">杨光润先生</h4>
							<h3 class="h3">董事、副总经理</h3>
						</div>
						<div class="box">
							<h4 class="h4">王涛先生</h4>
							<h3 class="h3">董事、董事会秘书、财务总监</h3>
						</div>
						<div class="box">
							<h4 class="h4">夏恒敏女士</h4>
							<h3 class="h3">财务副总监</h3>
						</div>
					</div>
				</div>
			</div>
		</div>
		<!-- 公司章程 -->
		<div class="gov-secondWrapper">
			<div class="charter-cont">
				<a
					href="https://www.bse.cn/disclosure/2023/2023-10-27/a5c6cccf6ab941e3908961f7979813b0.pdf"
					target="_blank"
				>
					<div class="txt-box">
						<div class="txt-box-main">
							<div class="line line-top"></div>
							<div class="text-row1">上海艾融软件股份有限公司</div>
							<div class="text-row2">公司章程</div>
							<div class="tip-date">2023年11月14日经公司2023年第三次临时股东大会审议通过</div>
							<div class="line line_bottom"></div>
						</div>
						<div class="tip-click">点击查看详情</div>
					</div>
				</a>
			</div>
		</div>

		<div class="gov-thirdWrapper">
			<div class="gov-thirdTitle">
				<WaveTitle
					title="投资者服务"
					subhead="Investor service"
					isMobile
				/>
			</div>
			<div class="gov-thirdContent">
				<div class="text">
					<div class="text-row1">为投资者提供优质服务<br />是资本市场发展的核心目标</div>
					<div class="text-row2">联系我们</div>
					<div class="text-row3">以便做出明智的投资决策</div>
					<div class="text-row4">投资者互动平台</div>
					<div class="text-row5">
						<a
							href="https://ir.p5w.net/c/830799.shtml"
							target="_blank"
						>
							<span>立即跳转</span>
							<div class="triangle"></div>
						</a>
					</div>
				</div>
				<div class="box">
					<div class="box-item">
						<img
							src="https://img.i2soft.net/i2official-web/v2/assets/images/relations/phone/gov_icon_phone.svg"
							alt=""
						/>
						<div>投资者热线</div>
						<div>021-68816717</div>
					</div>
					<div class="box-item">
						<img
							src="https://img.i2soft.net/i2official-web/v2/assets/images/relations/phone/gov_icon_email.svg"
							alt=""
						/>
						<div>投资者邮箱</div>
						<div>public@i2finance.net</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import WaveTitle from '@/components/WaveTitle.vue';
	export default {
		name: 'GovernmentComp',
		components: { WaveTitle },
		props: {},
		data() {
			return {
				// 年报
				annualList: [],
				// 中报
				InterimList: [],
				// 季报
				QuarterlyList: [],
				shareholderList: [],
			};
		},
		filters: {},
		created() {
			this.getachievent();
		},
		mounted() {
			window.addEventListener('scroll', this.getScroll);
		},
		destroyed() {
			window.removeEventListener('scroll', this.getScroll);
		},
		methods: {
			// 获取业绩表
			getachievent() {
				// 股东权益
				this.$axios.get('/parameter/shareholderList').then((res) => {
					this.shareholderList = res.data.data;
					console.log('年报业报', this.shareholderList);
				});
			},

			getScroll() {
				this.scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
				if (!!document.documentElement.scrollTop && document.documentElement.scrollTop >= 70) {
					this.$store.commit('TO_AfterScroll');
				} else {
					this.$store.commit('TO_AfterScroll_false');
				}
			},
		},
	};
</script>

<style lang="less" scoped>
	* {
		-moz-box-sizing: border-box;
		box-sizing: border-box;
		-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
	}

	.m-gov-wrapper {
		.gov-firstWrapper {
			padding: 10px;
			margin-bottom: 30px;
			.list {
				.item {
					.block {
						display: grid;
						grid-template-columns: repeat(2, 1fr);
						gap: 10px;

						.box {
							padding: 20px 30px;
							opacity: 1;
							background: #eaeff3;

							.h4 {
								font-size: 14px;
								font-weight: 600;
								line-height: 14px;
								text-align: center;
								letter-spacing: 0px;
								color: #666666;
							}

							.h3 {
								font-size: 16px;
								font-weight: 500;
								line-height: 16px;
								text-align: center;
								letter-spacing: 0px;
								color: #333333;
							}
						}

						.box:hover {
							background-color: #f3d8b8;
							color: #b08d57;
							box-shadow: 0px 8px 32px 0px rgba(0, 0, 0, 0.16);

							.h4 {
								color: #b08d57;
							}

							.h3 {
								color: #b08d57;
							}
						}

						.box:active {
							background-color: #f3d8b8;
							color: #b08d57;
							box-shadow: 0px 8px 32px 0px rgba(0, 0, 0, 0.16);

							.h4 {
								color: #b08d57;
							}

							.h3 {
								color: #b08d57;
							}
						}
					}
				}
			}
		}

		.gov-secondWrapper {
			height: 296px;
			background-image: url('https://img.i2soft.net/i2official-web/v2/assets/images/relations/phone/gov_constitutionback.png');
			background-position: center;
			background-repeat: no-repeat;
			background-size: cover;
			overflow: hidden;

			.charter-cont {
				height: 100%;

				a {
					width: 100%;
					height: 100%;
					text-decoration: none;

					.txt-box {
						width: 100%;
						height: 100%;
						padding: 25px;
						display: flex;
						flex-direction: column;
						align-items: center;
						justify-content: center;
						color: #ffffff;
						line-height: normal;
						letter-spacing: 0em;

						.txt-box-main {
							padding: 10px 0;
							display: flex;
							flex-direction: column;
							align-items: center;
							justify-content: center;
							row-gap: 10px;

							.line {
								width: 202px;
								height: 36px;
								border-left: 1px solid #ffffff;
								border-right: 1px solid #ffffff;
							}
							.line-top {
								border-top: 1px solid #ffffff;
							}
							.line-bottom {
								border-bottom: 1px solid #ffffff;
							}
							.text-row1 {
								font-size: 24px;
								font-weight: 500;
							}

							.text-row2 {
								font-size: 28px;
								font-weight: 900;
							}

							.tip-date {
								font-size: 9px;
								font-weight: 300;
							}
						}
						.tip-click {
							font-size: 12px;
						}
					}
				}
			}
		}
		.gov-thirdWrapper {
			.gov-thirdTitle {
				margin-top: 44px;
			}
			.gov-thirdContent {
				min-height: 483px;
				padding: 44px 24px;
				background-image: url('https://img.i2soft.net/i2official-web/v2/assets/images/relations/phone/gov_thirdback.png');
				.text {
					margin-bottom: 58px;
					font-weight: normal;
					line-height: normal;
					letter-spacing: 0em;
					color: #001f34;
					display: flex;
					text-align: left;
					flex-direction: column;
					align-items: flex-start;
					justify-content: center;
					white-space: pre-wrap;

					.text-row1 {
						font-size: 24px;
						margin-bottom: 14px;
					}
					.text-row2 {
						font-size: 26px;
						font-weight: 500;
						letter-spacing: 0.06em;
						margin-bottom: 4px;
					}
					.text-row3 {
						font-size: 20px;
						margin-bottom: 12px;
					}
					.text-row4 {
						font-size: 14px;
						margin-bottom: 4px;
						a {
							color: #727272;
						}
					}
					.text-row5 {
						a {
							display: flex;
							flex-direction: row;
							align-items: center;
							column-gap: 4px;
							font-size: 12px;
							color: #727272;
							.triangle {
								width: 0;
								height: 0;
								border-top: 6px solid transparent;
								border-bottom: 6px solid transparent;
								border-left: 8px solid #f49a42;
							}
						}
					}
				}
				.box {
					display: flex;
					flex-direction: row;
					.box-item {
						width: 136px;
						min-width: 136px;
						margin-right: 14px;
						padding: 14px 8px;
						display: flex;
						flex-direction: column;
						align-items: flex-start;
						row-gap: 4px;
						border-radius: 8px;
						opacity: 0.8;
						background: #ffffff;
						box-shadow: 0px 8px 32px 0px rgba(0, 0, 0, 0.16);
						img {
							width: 24px;
							min-width: 24px;
							height: 24px;
							margin-bottom: 10px;
						}
						div {
							font-size: 14px;
							font-weight: normal;
							line-height: normal;
							letter-spacing: 0em;
							color: #001f34;
						}
						div:last-child {
							font-size: 12px;
							color: #727272;
						}
					}
				}
			}
		}
	}
</style>
