<template>
  <div class="cardBox">
    <div class="cardYear">{{cardYear}}</div>
    <div class="reportName">{{reportName}}</div>
    <div class="reportEng">{{reportEng}}</div>
    <div class="reportIntroduce">{{reportIntroduce}}</div>
    <div class="footBtn">
      <div class="btnLeft" @click="preview">在线预览</div>
      <div class="btnLeft" @click="preview">下载PDF</div>
    </div>
  </div>
</template>

<script name="report-card">
export default {
  name: "ReportCard",
  props: {
    cardYear: {
      type: String,
      default: ''
    },
    reportName: {
      type: String,
      default: ''
    },
    reportEng: {
      type: String,
      default: ''
    },
    reportIntroduce: {
      type: String,
      default: ''
    },
    urlPdf: {
      type: String,
      default: ''
    },
  },

  methods: {
    preview(){
      window.open(this.urlPdf)
    }
  }
}
</script>

<style  lang="less" scoped>
.cardBox{
  width: 422px;
  height: 482px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  background: #FFFFFF;
  box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.1);
  position: relative;
  background-image: url('https://img.i2soft.net/i2official-web/v2/assets/images/relations/yjBg.png'); /* 设置背景图片 */
  background-size: cover; /* 背景图片覆盖整个div区域 */
  background-position: center; /* 背景图片居中 */
  background-repeat: no-repeat;
  .cardYear{
    padding: 55px 113px 15px 113px;
    font-size: 40px;
    font-weight: normal;
    color: #001F34;
  }
  .reportName{
    font-size: 28px;
    font-weight: 500;
    color: #F49A42;
    margin-bottom: 5px;
  }
  .reportEng{
    font-size: 24px;
    font-weight: normal;
    color: #F49A42;
  }
  .reportIntroduce{
    margin: 0 auto;
    margin-top: 10%;
    height: 100px;
    width: 80%;
    //white-space: pre-wrap;
    font-size: 24px;
    overflow: hidden;
    text-overflow: ellipsis;
    font-weight: normal;
    color: #001F34;
    //padding: 0px 69px 10px 69px;
    -webkit-line-clamp: 3; /* 限制文本显示的行数 */
    -webkit-box-orient: vertical; /* 设置盒子垂直排列子元素 */
    white-space: normal; /* 允许文本内部换行 */

  }
  //.divider{
  //  margin: 0 auto;
  //  width: 95%;
  //  border: 1px solid rgba(11, 6, 0, 0.17);
  //  bottom: 0;
  //}
  .footBtn{
    width: 95%;
    position: absolute;
    bottom: 0;
    border-top: 1px solid rgba(11, 6, 0, 0.17);
    left: 50%; /* 水平居中 */
    transform: translateX(-50%); /* 水平向左偏移50% */
    display: flex;
    .btnLeft{
      cursor: pointer;
      line-height: 100px;
      width: 50%;
      font-size: 24px;
      font-weight: 250;
      text-align: center;
      color: #001F34;
    }
  }
}
</style>