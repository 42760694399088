<template>
  <div class="container">
    <div v-if="!isMobile" class="pcWrapper">
      <div class="ach__relationsWrapper">
        <div class="ach_bannerWrapper">
          <img src="https://img.i2soft.net/i2official-web/assets/images/relations/gov_back.png" />
          <!-- <div class="bannerTextWrapper">
                      <span class="bannerText1">行业格局已经发生巨变</span>
                      <span class="bannerText2">我们为您提供场景化的</span>
                      <span class="bannerText3">深度行业解决方案</span>
                    </div> -->

          <div class="bannerTextWrapper">
            <!--                        <span class="bannerText1">公司治理</span>-->
            <!-- <span class="bannerText3">深度行业解决方案</span> -->
          </div>
        </div>
        <div class="announcementWrapper">
          <div class="top_box">
            <div class="pc_change">
              <a @click.prevent="jump('anc')" v-bind:class="{ 'active': (staic == 'anc') }">公司公告</a>
              <a v-bind:class="{ 'active': (staic == 'gov') }">公司治理</a>
              <a @click.prevent="jump('acv')" v-bind:class="{ 'active': (staic == 'acv') }">业绩报告</a>
            </div>
          </div>
        </div>
        <div class="mapWrapper">
          <!--                    <div class="investor-governance-s1">-->
          <!--                        <div class="wrapper">-->
          <!--                            <div class="inner-tit center gray">-->
          <!--                                <h3 class="h3">股东大会</h3>-->
          <!--                            </div>-->
          <!--                            <div class="tip">截至2022年4月21日，公司前十名股东持股情况如下表所示：</div>-->
          <!--                            <div class="cont">-->
          <!--                                <div class="head-box">-->
          <!--                                    <span>序号</span>-->
          <!--                                    <span>股东名称</span>-->
          <!--                                    <span>持有人类别</span>-->
          <!--                                    <span>持有股份数（股）</span>-->
          <!--                                    <span>持股比例(%)</span>-->
          <!--                                </div>-->
          <!--                                <div class="items">-->
          <!--                                    <div class="item"-->
          <!--                                         v-for="item in shareholderList"-->
          <!--                                         :key="item.id"-->
          <!--                                    >-->
          <!--                                        <span class="num">{{item.num}}</span>-->
          <!--                                        <span>{{item.name}}</span>-->
          <!--                                        <span>{{item.type}}</span>-->
          <!--                                        <span>{{item.shares}}</span>-->
          <!--                                        <span>{{item.proportion}}</span>-->
          <!--                                    </div>-->
          <!--                                </div>-->
          <!--                            </div>-->
          <!--                        </div>-->
          <!--                    </div>-->
          <div class="investor-governance-s2" id="gov">
            <div class="top-block">
              <div class="wrapper">
                <span @click="func('director')" v-bind:class="{ 'active': (active == 'director') }">董事会</span>
                <span @click="func('supervisor')" v-bind:class="{ 'active': (active == 'supervisor') }">监事会</span>
                <span @click="func('senior')" v-bind:class="{ 'active': (active == 'senior') }">高级管理层</span>
              </div>
            </div>
            <div class="bot-block">
              <div class="wrapper">
                <div class="child" v-if="block == 'director'">
                  <div class="box">
                    <h4 class="h4">张岩先生</h4>
                    <h3 class="h3">董事长、总经理</h3>
                  </div>
                  <div class="box">
                    <h4 class="h4"> 吴臻女士</h4>
                    <h3 class="h3">董事、副总经理</h3>
                  </div>
                  <div class="box">
                    <h4 class="h4">杨光润先生</h4>
                    <h3 class="h3">董事、副总经理</h3>
                  </div>
                  <div class="box">
                    <h4 class="h4">张甫先生</h4>
                    <h3 class="h3">董事</h3>
                  </div>
                  <div class="box">
                    <h4 class="h4">王涛先生</h4>
                    <h3 class="h3">董事</h3>
                  </div>
                  <div class="box">
                    <h4 class="h4">周忠恳先生</h4>
                    <h3 class="h3">董事</h3>
                  </div>
                  <div class="box">
                    <h4 class="h4">周宁女士</h4>
                    <h3 class="h3">独立董事</h3>
                  </div>
                  <div class="box">
                    <h4 class="h4">谭毓安先生</h4>
                    <h3 class="h3">独立董事</h3>
                  </div>
                  <div class="box">
                    <h4 class="h4">朱以林先生</h4>
                    <h3 class="h3">独立董事</h3>
                  </div>
                </div>
                <div class="child" v-if="block == 'supervisor'">
                  <div class="box">
                    <h4 class="h4">柳红芳女士</h4>
                    <h3 class="h3">监事会主席</h3>
                  </div>
                  <div class="box">
                    <h4 class="h4">胡超先生</h4>
                    <h3 class="h3">监事</h3>
                  </div>
                  <div class="box">
                    <h4 class="h4">彭小波先生</h4>
                    <h3 class="h3">监事</h3>
                  </div>
                </div>
                <div class="child" v-if="block == 'senior'">
                  <div class="box">
                    <h4 class="h4">张岩先生</h4>
                    <h3 class="h3">董事长、总经理</h3>
                  </div>
                  <div class="box">
                    <h4 class="h4">吴臻女士</h4>
                    <h3 class="h3">董事、副总经理</h3>
                  </div>
                  <div class="box">
                    <h4 class="h4">杨光润先生</h4>
                    <h3 class="h3">董事、副总经理</h3>
                  </div>
                  <div class="box">
                    <h4 class="h4">王涛先生</h4>
                    <h3 class="h3">董事、董事会秘书、财务总监</h3>
                  </div>
                  <div class="box">
                    <h4 class="h4">夏恒敏女士</h4>
                    <h3 class="h3">财务副总监</h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="investor-governance-s3" id="consti">
            <div class="wrapper">
              <div class="inner-tit center gray">
                <WaveTitle style="margin-top: 20px" :title="'公司章程'" :subhead="'Articles of association'"></WaveTitle>
              </div>
              <div class="cont">
                <a href="https://www.bse.cn/disclosure/2023/2023-10-27/a5c6cccf6ab941e3908961f7979813b0.pdf"
                  target="_blank">
                  <div class="img-box">
                    <div class="centerZc">
                      <div class="topWhite"></div>
                      <div class="cmpNameZc">上海艾融软件股份有限公司</div>
                      <div class="nameZc">公司章程</div>
                      <div class="nameZcDetail">2023年11月14日经公式2023年第三次临时股东大会审议通过</div>
                      <div class="bottomWhite"></div>
                      <div class="clickDetail">点击查看详情</div>
                    </div>
                  </div>
                </a>
              </div>
            </div>
          </div>

          <div class="investor-governance-s4">
            <div class="wrapper">
              <div class="inner-tit center gray">
                <WaveTitle style="margin-top: 20px" :title="'投资者服务'" :subhead="'Articles of association'"></WaveTitle>
              </div>
              <div class="serviceBox">
                <div class="serviceLeft">
                  <div class="servcieLine1">
                    为投资者提供优质服务
                    是资本市场发展的核心目标
                  </div>
                  <div class="servcieLine2">
                    联系我们
                  </div>
                  <div class="servcieLine3">
                    以便做出明智的投资决策
                  </div>
                  <div class="servcieLine4">
                    投资者互动平台
                  </div>
                  <div class="servcieLine5" @click="nowLink">
                    <div class="runService">立即跳转</div>
                    <img src="https://img.i2soft.net/i2official-web/v2/assets/images/relations/polygon.png" />
                  </div>

                  <div class="servcieLine6">
                    <div class="phoneService">
                      <div style="display: flex"> <img
                          src="https://img.i2soft.net/i2official-web/v2/assets/images/relations/phoneSevice.png" />
                      </div>

                      <div class="phoneName">投资者热线</div>
                      <div class="phoneNum">021-68816717</div>
                    </div>
                    <div class="emailService">
                      <div style="display: flex">
                        <img src="https://img.i2soft.net/i2official-web/v2/assets/images/relations/emailService.png" />
                      </div>
                      <div class="emailName">投资者邮箱</div>
                      <div class="emailNum">public@i2finance.net</div>
                    </div>
                  </div>
                </div>
                <div class="serviceRight">
                  <img src="https://img.i2soft.net/i2official-web/v2/assets/images/relations/serviceBg.png">
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { isMobile } from "@/utils/index";
import $ from 'jquery'
import WaveTitle from "@/components/WaveTitle";

export default {
  components: {
    WaveTitle
  },
  data() {
    return {
      isMobile: false,
      // 年报
      annualList: [],
      // 中报
      InterimList: [],
      // 季报
      QuarterlyList: [],
      datas: [],
      shareholderList: [],
      active: "",
      block: "",
      staic: '',
      query_type: '',
      location: '',
    };
  },
  filters: {},
  created() {
    this.getachievent();
    this.query_type = this.$route.query.category;
    this.location = this.$route.query.location;
    this.pg_change(this.query_type);
    this.func(this.location);
  },
  mounted() {
    this.isMobile = isMobile();
    window.addEventListener("scroll", this.getScroll);
  },
  destroyed() {
    window.removeEventListener("scroll", this.getScroll);
  },
  methods: {
    // 跳转
    jump(type) {
      if (type == "anc") {
        this.$router.push({ path: "/Announce", query: { category: type } });
      } else if (type == "acv") {
        this.$router.push({ path: "/Achievement", query: { category: type } });
      } else if (type == "ser") {
        this.$router.push({ path: "/Service", query: { category: type } });
      }
    },
    // 跳转后tab标签的改变
    pg_change(type) {
      console.log("static", type);
      console.log("this.query_type", this.query_type);
      this.staic = type;
    },
    // 获取业绩表
    getachievent() {
      // 股东权益
      this.$axios.get("/parameter/shareholderList").then((res) => {
        this.shareholderList = res.data.data;
        console.log("年报业报", this.shareholderList)
      })
    },
    // 切换tab
    func(c) {
      console.log("c", c);
      if (!c) {
        this.active = 'director';
        this.block = 'director';
        window.scrollTo(0, 540);
      } else {
        if (c != 'consti') {
          const hash = window.location.hash;
          const index = hash.lastIndexOf("#");
          if (index != -1) {
            const id = hash.substring(index + 1, hash.length + 1);
            setTimeout(function () {
              console.log("值", $(document.getElementById(id)).offset().top);
              $('html, body').animate({ scrollTop: $(document.getElementById(id)).offset().top - 43 }, 500)
            }, 0);
          }
          this.active = c;
          this.block = c;
        } else {
          const hash = window.location.hash;
          const index = hash.lastIndexOf("#");
          if (index != -1) {
            const id = hash.substring(index + 1, hash.length + 1);
            setTimeout(function () {
              console.log("值", $(document.getElementById(id)).offset().top);//$('html,body').scrollTop($(div).offset().top - 43);
              $('html, body').animate({ scrollTop: $(document.getElementById(id)).offset().top - 43 }, 500)
            }, 0);
          }
          this.active = 'director';
          this.block = 'director';
        }

      }
    },
    getScroll() {
      // this.indexNumTop = $("#index_num").offset().top;
      this.scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      if (!!document.documentElement.scrollTop && document.documentElement.scrollTop >= 70
      ) {
        //设置滚动大于300时的执行内容
        // console.log(
        //   "document.documentElement.scrollTop=",
        //   document.documentElement.scrollTop
        // );
        this.$store.commit("TO_AfterScroll");
      } else {
        this.$store.commit("TO_AfterScroll_false");
      }
    },
    nowLink() {
      window.open('https://ir.p5w.net/c/830799.shtml')
    }
  },
};
</script>

<style lang="less" scoped>
* {
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.ach__relationsWrapper {
  background-color: #ffffff;

  .ach_bannerWrapper {
    width: 100%;
    position: relative;

    img {
      width: 100%;
      height: auto;
    }

    .bannerTextWrapper {
      position: absolute;
      top: 45%;
      left: 45%;
      display: flex;
      flex-direction: column;

      .bannerText1 {
        color: #ffffff;
        font-size: 30px;
        text-align: left;
        font-family: PingFangSC-Medium;
      }

      .bannerText2 {
        color: rgba(255, 255, 255, 100);
        font-size: 40px;
        text-align: left;
        font-family: PingFangSC-Medium;
      }

      .bannerText3 {
        color: rgba(255, 255, 255, 100);
        font-size: 30px;
        text-align: left;
        font-family: PingFangSC-Light;
      }
    }
  }

        .announcementWrapper {
          margin-top: -6px;
          background-color: #F4F0D7;
          padding: 0 20px;
          display: flex;
          flex-direction: row;
          justify-content: center;
    
          .top_box {
            width: 75%;
          }
    
          .pc_change {
            margin-right: 25px;
            display: flex;
            flex-direction: row;
            align-items: flex-end;
            align-items: center;
            justify-content: flex-end;
            column-gap: 31px;
    
            a {
              padding: 20px 0;
              cursor: default;
              text-decoration: none;
              color: #000000;
              font-size: 15px;
              cursor: pointer;
            }
    
            a:hover {
              color: #F49A42;
            }
    
            .active {
              color: #F49A42;
            }
    
          }
    
        }

  .mapWrapper {
    .wrapper {
      width: 81.25vw;
      //max-width: 1560px;
      margin: 0 auto;
      position: relative;
      z-index: 2;
    }

    display: flex;
    flex-direction: column;
    margin: auto;
    padding: 0px;

    .investor-governance-s1 {
      padding: 6.25vw 0;

      .wrapper {
        width: 80%;
        max-width: 1560px;
        margin: 0 auto;
        position: relative;
        z-index: 2;
        text-align: left;

        .center {
          text-align: center;

          h3 {
            color: #333333;
            font-size: 26px;
          }
        }

        .tip {
          font-size: 14px;
          color: #333333;
          margin-top: 20px;
        }

        .cont {
          margin-top: 2vw;
          font-size: 0;

          .head-box {
            background-image: linear-gradient(90deg, #001066, #2f50a2);

            span:nth-of-type(1) {
              padding-left: 3.125vw;
              font-size: 18px;
            }

            span:nth-of-type(2) {
              font-size: 18px;
              width: 40%;
            }

            span:nth-of-type(3) {
              font-size: 18px;
              width: 15%;
            }

            span {
              font-size: 18px;
              display: inline-block;
              vertical-align: top;
              width: 15%;
              line-height: 3.75;
              color: #fff;
            }
          }

          .item:nth-of-type(even) {
            background-color: #f7f7f7;
          }

          .item {
            border-bottom: 1px solid #e6e6e6;

            span:nth-of-type(1) {
              padding-left: 3.125vw;
            }

            span:nth-of-type(2) {
              width: 40%;
            }

            span:nth-of-type(3) {
              width: 15%;
            }

            span {
              display: inline-block;
              vertical-align: top;
              width: 15%;
              font-size: 12px;
              line-height: 3;
              color: #333333;
            }
          }
        }
      }
    }

    .investor-governance-s2 {
      padding: 6.25vw 0;
      //background-color: #f7f7f7;
      background: white;

      .top-block {
        font-size: 0;
        text-align: center;
        border-bottom: 1px solid #d9d9d9;

        span {
          left: 2vw;
          display: inline-block;
          vertical-align: top;
          font-size: 24px;
          line-height: 40px;
          color: #333333;
          padding: 0 2vw 20px;
          margin: 0 4vw;
          position: relative;
          transition: all .3s ease;
          cursor: pointer;
        }

        .wrapper {
          width: 80%;

          span {
            font-size: 18px;
            padding-bottom: 10px;
          }

          .active {
            font-size: 24px;
            padding-bottom: 10px;
            border-bottom: 2px solid #F49A42;
            color: #F49A42;
          }
        }
      }

      .bot-block {
        margin-top: 2vw;
        font-size: 0;

        .wrapper {
          width: 80%;

          .child {
            display: flex;
            flex-wrap: wrap;

            .box {
              padding: 3vw 20px;
              cursor: pointer;
            }

            h4 {
              font-size: 14px;
            }

            h3 {
              font-size: 18px;
            }

            .box:hover h3 {
              font-size: 24px;
              font-weight: 900;
              color: #B08D57;
            }

            .box:hover h4 {
              font-size: 28px;
              font-weight: 900;
              color: #B08D57;
            }

            .box:hover {
              background-image: url("https://img.i2soft.net/i2official-web/v2/assets/images/relations/loveWen.png");
              background-size: 200%;
              background-clip: border-box;
              // background-size: cover;
              background-position: center;
              background-repeat: no-repeat;
              transform: translateY(-20px);
              box-shadow: 0 26px 40px -24px rgb(0 36 100 / 50%);
            }
          }
        }

        .box {
          display: inline-block;
          vertical-align: top;
          width: calc((100% - 6.2vw) / 3);
          position: relative;
          padding: 3.5vw 2.5vw;
          background-color: #eaeff3;
          margin-top: 2vw;
          margin-right: 2vw;
          transition: all .3s ease;

          h4 {
            font-size: 16px;
            color: #808080;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            position: relative;
            z-index: 2;
            transition: color .3s ease;
          }

          h3 {
            font-size: 24px;
            color: #333333;
            margin-top: 15px;
            position: relative;
            z-index: 2;
            transition: color .3s ease;
          }
        }
      }
    }

    .investor-governance-s3 {
      padding: 6.25vw 0;

      .wrapper {
        width: 100%;

        .center {
          text-align: center;
        }

        h3 {
          font-size: 26px;
        }

        .cont {
          margin-top: 2vw;
          font-size: 0;
          width: 100%;

          a {
            width: 100%;
            display: flex;
            width: 100%;
            text-decoration: none;
            color: #333;

            .img-box {
              width: 100%;
              //max-width: 960px;
              height: 592px;
              overflow: hidden;
              background-image: url("https://img.i2soft.net/i2official-web/v2/assets/images/relations/cmpZcBg.png");
              background-size: cover;
              background-position: center;
              background-repeat: no-repeat;
              display: flex;
              justify-content: center;
              align-items: center;

              .centerZc {

                .topWhite {
                  margin: 0 auto;
                  width: 404px;
                  height: 72px;
                  border-top: 1px solid #FFFFFF;
                  border-left: 1px solid #FFFFFF;
                  border-right: 1px solid #FFFFFF;
                }

                .cmpNameZc {
                  margin: 0 auto;
                  font-size: 48px;
                  font-weight: 500;
                  color: #FFFFFF;
                  margin-top: 20px;
                }

                .nameZc {
                  margin: 0 auto;
                  font-size: 56px;
                  font-weight: 900;
                  color: #FFFFFF;
                  margin-top: 20px;
                }

                .nameZcDetail {
                  margin: 0 auto;
                  font-size: 18px;
                  font-weight: 300;
                  color: #FFFFFF;
                  margin-top: 20px;
                }

                .bottomWhite {
                  margin: 0 auto;
                  width: 404px;
                  height: 72px;
                  border-bottom: 1px solid #FFFFFF;
                  border-left: 1px solid #FFFFFF;
                  border-right: 1px solid #FFFFFF;
                  margin-top: 20px;
                }

                .clickDetail {
                  margin: 0 auto;
                  font-size: 24px;
                  font-weight: 500;
                  color: #FFFFFF;
                  margin-top: 80px;
                }
              }


            }

            //img {
            //    display: block;
            //    width: 100%;
            //    transition: transform .6s ease;
            //    vertical-align: middle;
            //    border: none;
            //}

            //.txt-box {
            //    position: relative;
            //    width: 38%;
            //    padding: 6.25vw 3.125vw;
            //    background-image: linear-gradient(90deg, #001066, #2f50a2);
            //    text-align: center;
            //    color: #ffffff;
            //
            //    h4 {
            //        font-size: 18px;
            //    }
            //
            //    h3 {
            //        font-size: 32px;
            //        margin-top: 1.5vw;
            //    }
            //
            //    .tip {
            //        position: absolute;
            //        bottom: 3.125vw;
            //        left: 2.125vw;
            //        right: 2.125vw;
            //        font-size: 12px;
            //    }
            //}
          }
        }
      }
    }

    .investor-governance-s4 {
      padding: 6.25vw 0;

      .wrapper {
        width: 80%;

        .serviceBox {
          display: flex;

          .serviceLeft {
            width: 50%;
            height: 850px;
            position: relative;

            .servcieLine1 {
              font-size: 48px;
              font-weight: normal;
              color: #001F34;
              text-align: left;
              margin-top: 160px;
            }

            .servcieLine2 {
              text-align: left;
              font-size: 52px;
              font-weight: 500;
              line-height: normal;
              letter-spacing: 0.06em;
              color: #001F34;
              margin-top: 30px;
            }

            .servcieLine3 {
              text-align: left;
              font-size: 40px;
              font-weight: normal;
              line-height: normal;
              letter-spacing: 0em;
              color: #001F34;
              margin-top: 30px;
            }

            .servcieLine4 {
              text-align: left;
              font-size: 26px;
              font-weight: normal;
              line-height: normal;
              letter-spacing: 0em;
              color: #001F34;
              margin-top: 30px;
            }

            .servcieLine5 {
              cursor: pointer;
              display: flex;
              align-items: center;
              margin-top: 15px;

              .runService {
                text-align: left;
                font-size: 18px;
                font-weight: normal;
                line-height: normal;
                letter-spacing: 0em;
                color: #727272;
                margin-right: 10px;
              }

              img {
                width: 11px;
                height: 11px;
              }
            }

            .servcieLine6 {
              position: absolute;
              bottom: 0;
              display: flex;

              //justify-content: space-between;
              .phoneService {
                width: 140px;

                .phoneName {
                  margin-top: 15px;
                  text-align: left;
                  font-size: 26px;
                  font-weight: normal;
                  color: #001F34;
                }

                .phoneNum {
                  margin-top: 15px;
                  text-align: left;
                  font-size: 18px;
                  font-weight: normal;
                  color: #727272;
                }
              }

              .emailService {
                width: 140px;
                margin-left: 200px;

                .emailName {
                  margin-top: 15px;
                  text-align: left;
                  font-size: 26px;
                  font-weight: normal;
                  color: #001F34;
                }

                .emailNum {
                  margin-top: 15px;
                  text-align: left;
                  font-size: 18px;
                  font-weight: normal;
                  color: #727272;
                }
              }
            }
          }

          .serviceRight {
            width: 50%;
            height: 850px;
            position: relative;
            z-index: -1;

            img {
              position: absolute;
              bottom: 0;
              //transform: translateY(-10%); /* 水平向左偏移50% */
              left: 48%;
              /* 水平居中 */
              transform: translateX(-30%);
              /* 水平向左偏移50% */
            }
          }
        }
      }
    }

  }
}
</style>
