<template>
	<div class="m_wrapper">
		<div class="m_firstBannerWrapper">
			<img
				:src="firstBack"
				alt=""
			/>
		</div>
		<div class="tabs">
			<div class="tab_item">
				<a
					@click.prevent="tabChange('soc')"
					:class="{ 'tab-active': currentTab == 'soc' }"
					>社会招聘</a
				>
				<div
					class="tab_line"
					v-show="currentTab == 'soc'"
				></div>
			</div>
			<div class="tab_item">
				<a
					@click.prevent="tabChange('camp')"
					:class="{ 'tab-active': currentTab == 'camp' }"
					>校园招聘</a
				>
				<div
					class="tab_line"
					v-show="currentTab == 'camp'"
				></div>
			</div>
		</div>
		<div class="tabs_container">
			<SocialRecruitmentComp v-show="currentTab == 'soc'" />
			<CampusRecruitmentComp v-show="currentTab == 'camp'" />
		</div>
	</div>
</template>

<script>
import { isMobile } from '@/utils/index';
import SocialRecruitmentComp from '@/components/phone/SocialRecruitmentComp.vue';
	import CampusRecruitmentComp from '@/components/phone/CampusRecruitmentComp.vue';

	export default {
		components: {
			SocialRecruitmentComp,
			CampusRecruitmentComp,
		},
		data() {
			return {
				isMobile: false,
				currentTab: 'soc',
				firstBack: 'https://img.i2soft.net/i2official-web/v2/assets/images/joinus/phone/firstback_soc.png',
			};
		},
		mounted() {
			this.isMobile = isMobile();
			window.addEventListener('scroll', this.getScroll);
		},
		destroyed() {
			window.removeEventListener('scroll', this.getScroll);
		},
		methods: {
			// tab切换（公司公告、公司治理、业绩报告、投资者服务）
			tabChange(type) {
				this.currentTab = type;
				this.firstBack = `https://img.i2soft.net/i2official-web/v2/assets/images/joinus/phone/${type}_firstback.png`;
			},
			getScroll() {
				this.scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
				if (!!document.documentElement.scrollTop && document.documentElement.scrollTop >= 70) {
					this.$store.commit('TO_AfterScroll');
				} else {
					this.$store.commit('TO_AfterScroll_false');
				}
			},
		},
	};
</script>

<style lang="less" scoped>
	.m_wrapper {
		background: #fff;

		.m_firstBannerWrapper {
			position: relative;
			overflow: hidden;

			img {
				margin: 0 auto;
				display: block;
				width: 100%;
				height: auto;
			}
		}

		.tabs {
			display: flex;
			flex-direction: row;
			align-items: center;
			justify-content: space-evenly;
			padding: 10px 0;
			box-shadow: 0px 0.5px 0px 0px rgba(0, 0, 0, 0.15);

			.tab_item {
				height: 30px;
				font-size: 12px;
				display: flex;
				flex-direction: column;
				align-items: center;
				justify-content: flex-start;
				row-gap: 4px;

				a {
					color: #666666;
				}

				a:hover {
					color: #b08d57;
				}

				.tab-active {
					color: #b08d57;
				}
				.tab_line {
					width: 50%;
					border-bottom: 2px solid #b08d57;
				}
			}
		}
	}
</style>
