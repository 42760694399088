<template>
	<div class="container">
		<div v-if="!isMobile" class="pcWrapper">
			<div class="relationsWrapper">
				<div class="bannerWrapper">
					<img src="https://img.i2soft.net/i2official-web/v2/assets/images/relations/touziRela.png" />
				</div>
				<div class="announcementWrapper">
					<div class="top_box">
						<div class="pc_change">
							<a @click.prevent="jump('anc')">公司公告</a>
							<a @click.prevent="jump('gov')">公司治理</a>
							<a @click.prevent="jump('acv')">业绩报告</a>
						</div>
					</div>
				</div>
				<div class="mapWrapper">
					<div class="investor-index-s1">
						<div class="wrapper">
							<div class="inner-tit gray">
								<WaveTitle
									style="margin-top: 20px"
									:title="'公司公告'"
									:subhead="'Notice'"
								></WaveTitle>
							</div>
							<div class="cont">
								<div
									class="img-box"
									@click="firstNoticeLink(announcements[0].url)"
								>
									<div>
										<img
											src="https://img.i2soft.net/i2official-web/v2/assets/images/dynamic/relationship.png"
											alt="公司公告"
										/>
									</div>
									<div class="imgBoxBottum">
										<div class="boxBottumTitle">
											{{ announcements[0]?.Securities_name }}
										</div>
										<div class="titleTime">
											{{ announcements[0]?.date }}
										</div>
										<div class="bottomContent">
											{{ announcements[0]?.content }}
										</div>
									</div>
								</div>

								<div class="txt-box">
									<div
										class="announcementV2"
										v-for="(announcement, index) in announcements"
										:key="announcement.id"
									>
										<a
											:href="announcement.url"
											target="_blank"
										>
											<div class="contentv2Left">
												{{ `0${index + 1}` }}
											</div>
											<div class="contentv2Rigth">
												<div class="conRightTitle">
													<div class="leftTitle">{{ getAnnounceType(announcement.content) }} </div>
													<div class="leftDate">{{ announcement.date }}</div>
												</div>
												<div class="conRightText">
													{{ announcement.content }}
												</div>
											</div>
										</a>
									</div>
									<a
										href="#/Announce?category=anc"
										style="text-decoration: none; display: flex; flex-direction: row-reverse"
									>
										<div class="news">
											<div class="newsAll"> 查看全部 </div>
										</div>
									</a>
								</div>
							</div>
						</div>
					</div>
					<div class="investor-index-s2">
						<div class="wrapper">
							<div class="inner-tit gray">
								<WaveTitle
									style="margin-top: 20px"
									:title="'公司治理'"
									:subhead="'Notice'"
								></WaveTitle>
							</div>
							<div class="government_box_v2">
								<div class="fourDetail">
									<div
										class="dongshi"
										@click="toGover('director')"
									>
										<img src="https://img.i2soft.net/i2official-web/v2/assets/images/relations/dongshiImg.png" />
										<div class="dongshiName">董事会</div>
										<div class="introduce">艾融软件董事会的介绍信息</div>
										<div class="clickDetail">点击详情</div>
									</div>
									<div
										class="dongshi"
										@click="toGover('supervisor')"
									>
										<img src="https://img.i2soft.net/i2official-web/v2/assets/images/relations/jianshiImg.png" />
										<div class="dongshiName">监事会</div>
										<div class="introduce">艾融软件监事会的介绍信息</div>
										<div class="clickDetail">点击详情</div>
									</div>
									<div
										class="dongshi"
										@click="toGover('senior')"
									>
										<img src="https://img.i2soft.net/i2official-web/v2/assets/images/relations/gaocengImg.png" />
										<div class="dongshiName">高级管理层</div>
										<div class="introduce">艾融软件高层管理层的介绍信息</div>
										<div class="clickDetail">点击详情</div>
									</div>
									<div
										class="dongshi"
										@click="toGover('consti')"
									>
										<img src="https://img.i2soft.net/i2official-web/v2/assets/images/relations/zhangchengImg.png" />
										<div class="dongshiName">公司章程</div>
										<div class="introduce">艾融软件公司章程的介绍信息</div>
										<div class="clickDetail">点击详情</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="investor-index-s3">
						<div class="wrapper">
							<div class="inner-tit white">
								<WaveTitle
									style="margin-top: 20px"
									:title="'业绩报告'"
									:subhead="'Performance report'"
								>
								</WaveTitle>
							</div>
							<div class="yjImgData">
								<div class="threeBao">
									<div class="threeTitle">
										<div
											class="yearBao"
											v-bind:class="{ yearBao_active: yjActive == 'annual' }"
											@click="yearClick('annual')"
											>年报</div
										>
										<div
											class="yearBao"
											v-bind:class="{ yearBao_active: yjActive == 'Inter' }"
											@click="centerClick('Inter')"
											>中报</div
										>
										<div
											class="yearBao"
											v-bind:class="{ yearBao_active: yjActive == 'Quart' }"
											@click="jiClick('Quart')"
											>季报</div
										>
									</div>
									<div class="threeCard">
										<div
											class="yearContent"
											v-if="yjStatus == 'year'"
										>
											<ReportCard
												v-for="annu in annualList"
												:key="annu.id"
												:card-year="'2019-2023'"
												:url-pdf="annu.to"
												:report-name="'年报'"
												:report-eng="'Annual Report'"
												:report-introduce="annu.name"
											>
											</ReportCard>
										</div>

										<div
											class="yearContent"
											v-if="yjStatus == 'center'"
										>
											<ReportCard
												v-for="annu in InterimList"
												:key="annu.id"
												:card-year="'2019-2024'"
												:url-pdf="annu.to"
												:report-name="'中报'"
												:report-eng="'Interim Report'"
												:report-introduce="annu.name"
											>
											</ReportCard>
										</div>
										<div
											class="yearContent"
											v-if="yjStatus == 'ji'"
										>
											<ReportCard
												v-for="annu in QuarterlyList"
												:key="annu.id"
												:card-year="'2019-2024'"
												:url-pdf="annu.to"
												:report-name="'季报'"
												:report-eng="'Quarterly Report'"
												:report-introduce="annu.name"
											>
											</ReportCard>
										</div>
									</div>
								</div>
								<img
									class="yejiBg"
									src="https://img.i2soft.net/i2official-web/v2/assets/images/relations/yejiBack.png"
								/>
								<a
									href="#/Achievement?category=acv"
									class="moreYj"
									>了解详情</a
								>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
    <PhoneIndex v-else/>
	</div>
</template>

<script>
	import { isMobile } from '@/utils/index';
	import WaveTitle from '@/components/WaveTitle';
	import ReportCard from '@/views/V2/relations/componets/ReportCard';
	import PhoneIndex from './PhoneIndex.vue';

	export default {
		components: {
			ReportCard,
			WaveTitle,
			PhoneIndex,
		},
		data() {
			return {
				yjStatus: 'year',
				isMobile: false,
				annualList: [],
				InterimList: [],
				QuarterlyList: [],
				announcements: [],
				datas: [],
				page: {
					totle: '',
					currentPage: 2,
					pageSize: 8,
				},
				Qua_page: {
					totle: '',
					currentPage: 1,
					pageSize: 3,
				},
				yjActive: 'annual',
				active: '',
				block: '',
				currentTab: 'anc',
			};
		},
		filters: {},
		created() {
			this.getData();
			this.getachievent();
			this.func('annual');
		},
		mounted() {
			this.isMobile = isMobile();
			window.addEventListener('scroll', this.getScroll);
		},
		destroyed() {
			window.removeEventListener('scroll', this.getScroll);
		},
		methods: {
			// 公司治理跳转
			toGover(a) {
				console.log('a', a);
				if (a != 'consti') {
					this.$router.push({ path: '/government?#gov', query: { location: a } });
				} else {
					this.$router.push({ path: '/government?#consti', query: { location: a } });
				}
			},
			// tab切换
			func(c) {
				this.active = c;
				this.block = c;
			},
			// 跳转
			jump(type) {
				if (type == 'anc') {
					this.$router.push({ path: '/Announce', query: { category: type } });
				} else if (type == 'gov') {
					this.$router.push({ path: '/government', query: { category: type } });
				} else if (type == 'acv') {
					this.$router.push({ path: '/Achievement', query: { category: type } });
				} else if (type == 'ser') {
					this.$router.push({ path: '/Service', query: { category: type } });
				}
			},

			// tab切换（公司公告、公司治理、业绩报告、投资者服务）
			tabChange(type) {
				this.currentTab = type;
			},
			//获取数据并处理
			getData() {
				this.$axios.get('/parameter/query').then((res) => {
					this.datas = res.data.data;
					const datas = this.datas;
					this.page.totle = datas.length;
					console.log('长度:' + this.page.totle);
					// this.announcements = datas.slice(0, 8);
					this.announcements = datas.slice(0, 4);
					console.log('条数', this.announcements);
				});
			},
			// 获取业绩表
			getachievent() {
				// 年报业报
				this.$axios.get('/parameter/annualList').then((res) => {
					const datas = res.data.data;
					// this.annualList = res.data.data;
					// 投资者关系底部-业绩报告，切换卡片默认年报只展示三条数据
					this.annualList = datas.slice((this.Qua_page.currentPage - 1) * this.Qua_page.pageSize, this.Qua_page.pageSize + (this.Qua_page.currentPage - 1) * this.Qua_page.pageSize);
					console.log('年报业报', this.annualList);
				});
				// 中报业报
				this.$axios.get('/parameter/InterimList').then((res) => {
					const datas = res.data.data;
					// this.InterimList = res.data.data;
					// 投资者关系底部-业绩报告，切换卡片默认只展示中报三条数据
					this.InterimList = datas.slice((this.Qua_page.currentPage - 1) * this.Qua_page.pageSize, this.Qua_page.pageSize + (this.Qua_page.currentPage - 1) * this.Qua_page.pageSize);
					console.log('中报业报', this.InterimList);
				});
				// 季报业报
				this.$axios.get('/parameter/QuarterlyList').then((res) => {
					const datas = res.data.data;
					this.QuarterlyList = datas.slice((this.Qua_page.currentPage - 1) * this.Qua_page.pageSize, this.Qua_page.pageSize + (this.Qua_page.currentPage - 1) * this.Qua_page.pageSize);
					console.log('季报业报', this.QuarterlyList);
				});
			},
			getScroll() {
				// this.indexNumTop = $("#index_num").offset().top;
				this.scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
				if (!!document.documentElement.scrollTop && document.documentElement.scrollTop >= 70) {
					//设置滚动大于300时的执行内容
					// console.log(
					//   "document.documentElement.scrollTop=",
					//   document.documentElement.scrollTop
					// );
					this.$store.commit('TO_AfterScroll');
				} else {
					this.$store.commit('TO_AfterScroll_false');
				}
			},

			getAnnounceType(str) {
				if (!str || (str && str.trim().length < 0)) {
					return '';
				} else {
					let type = str.includes('[') && str.includes(']') ? str.match(/\[(.*?)\]/)[1] : '';
					return type;
				}
			},

			yearClick(c) {
				this.yjStatus = 'year';
				this.yjActive = c;
			},
			centerClick(c) {
				this.yjStatus = 'center';
				this.yjActive = c;
			},
			jiClick(c) {
				this.yjStatus = 'ji';
				this.yjActive = c;
			},
			firstNoticeLink(url) {
				window.open(url);
			},
		},
	};
</script>

<style lang="less" scoped>
	* {
		-moz-box-sizing: border-box;
		box-sizing: border-box;
		-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
	}

	.relationsWrapper {
		background-color: #ffffff;

		.bannerWrapper {
			width: 100%;
			position: relative;

			img {
				width: 100%;
				height: auto;
			}

			.bannerTextWrapper {
				position: absolute;
				top: 212px;
				left: 129px;
				display: flex;
				flex-direction: column;

				.bannerText1 {
					color: rgba(174, 57, 56, 100);
					font-size: 40px;
					text-align: left;
					font-family: PingFangSC-Medium;
				}

				.bannerText2 {
					color: rgba(255, 255, 255, 100);
					font-size: 40px;
					text-align: left;
					font-family: PingFangSC-Medium;
				}

				.bannerText3 {
					color: rgba(255, 255, 255, 100);
					font-size: 30px;
					text-align: left;
					font-family: PingFangSC-Light;
				}
			}
		}

		.announcementWrapper {
			margin-top: -6px;
			background-color: #f4f0d7;
			padding: 0 20px;
			display: flex;
			flex-direction: row;
			justify-content: center;

			.top_box {
				width: 75%;
			}

			.pc_change {
				margin-right: 25px;
				display: flex;
				flex-direction: row;
				align-items: flex-end;
				align-items: center;
				justify-content: flex-end;
				column-gap: 31px;

				a {
					padding: 20px 0;
					cursor: default;
					text-decoration: none;
					color: #000000;
					font-size: 15px;
					cursor: pointer;
				}

				a:hover {
					color: #f49a42;
				}

				.active {
					color: #f49a42;
				}
			}
		}

		.mapWrapper {
			display: flex;
			flex-direction: column;
			/*width: 80%;*/
			margin: auto;
			padding: 0px;

			.investor-index-s1 {
				width: 80%;
				margin: auto;
				padding: 0px;

				.wrapper {
					display: flex;
					flex-direction: column;

					.h3 {
						color: #333333;
						font-size: 26px;
					}

					.cont {
						margin: 2vw 0;
						display: flex;

						.img-box {
							cursor: pointer;
							width: 42%;
							max-width: 640px;
							overflow: hidden;

							.imgBoxBottum {
								padding: 25px 55px 20px 55px;
								//width: 100%;
								height: 226px;
								background-image: url('https://img.i2soft.net/i2official-web/v2/assets/images/dynamic/relationButtom.png');
								background-size: cover;
								background-repeat: no-repeat;

								.boxBottumTitle {
									font-family: 'Alibaba PuHuiTi 2.0';
									//width: 100%;
									font-size: 26px;
									font-weight: 500;
									color: rgba(4, 2, 0, 0.76);
								}

								.titleTime {
									//width: 100%;
									font-family: 'Alibaba PuHuiTi 2.0';
									color: #001f34;
									font-size: 18px;
									font-weight: 250;
								}

								.bottomContent {
									margin-top: 20px;
									//width: 100%;
									font-family: 'Alibaba PuHuiTi 2.0';
									font-size: 21px;
									font-weight: 250;
									color: #001f34;
								}
							}
						}

						img {
							display: block;
							width: 100%;
							height: 100%;
							object-fit: cover;
							transition: transform 0.3s ease;
							vertical-align: middle;
							border: none;
							margin-top: 3.5%;
							//padding-bottom: 7.5%;
						}

						.txt-box {
							width: 58%;
							padding-left: 20px;
							//padding-left: 3.125vw;
							//line-height: 3vw;

							//div {
							//    /*margin-top: 5px;*/
							//    margin-bottom: 5px;
							//    text-align: left;
							//}

							a {
								//color: black;
								//margin-top: 0;
								//font-size: 10px;
								//position: relative;
								//line-height: 24px;
								width: 100%;
								display: flex;
								text-decoration: none;
							}

							//a:link {
							//    color: #333333;
							//}
							//
							//a:visited {
							//    color: #333333;
							//}
							//
							//a:hover {
							//    color: #2f50a2;
							//}

							.date {
								display: inline-block;
								text-align: right;
								width: 25%;
							}

							.content {
								display: inline-block;
								text-overflow: clip;
								text-align: left;
								width: 75%;
							}

							.announcement {
								a {
									font-size: 14px;
								}
							}

							.announcementV2 {
								display: flex;
								border-bottom: 1px solid #cac8c7;
								padding-top: 20px;

								.contentv2Left {
									font-family: DINCondensed;
									font-size: 88px;
									color: #001f34;
								}

								.contentv2Rigth {
									color: #001f34;
									padding-left: 20px;
									width: 100%;

									.conRightTitle {
										color: #001f34;
										display: flex;
										justify-content: space-between;

										.leftTitle {
											color: #001f34;
											font-size: 30px;
											font-weight: 700;
										}

										.leftDate {
											color: #001f34;
											font-size: 24px;
											font-weight: 300;
										}
									}

									.conRightText {
										margin-top: 20px;
										color: #001f34;
										width: 100%;
										font-size: 24px;
										font-weight: 250;
										text-align: left;
									}
								}
							}

							.announcementV2:hover {
								background: #f3d8b8;
							}

							.news {
								margin-top: 20px;
								float: right;
								width: 203.59px;
								height: 109.96px;
								background-image: url('https://img.i2soft.net/i2official-web/v2/assets/images/dynamic/news.png');
								background-size: cover;
								background-position: center;
								background-repeat: no-repeat;
								position: relative;

								.newsAll {
									position: absolute;
									right: 0;
									/* 右对齐 */
									bottom: 0;
									/* 底部对齐 */
									font-size: 30px;
									font-weight: 500;
									color: #001f34;
									margin-bottom: 10px;
								}
							}
						}
					}

					.inverstor-more {
						text-decoration: none;
						width: 120px;
						line-height: 40px;
						font-size: 15px;
						display: block;
						color: #ffffff;
						text-align: center;
						background-image: linear-gradient(90deg, rgba(53, 135, 195, 1), rgba(36, 93, 161, 1));
						transition: all 0.3s ease;
					}
				}
			}

			.investor-index-s2 {
				width: 100%;
				margin: auto;
				padding: 0px;

				.wrapper {
					margin-top: 30px;
					margin-bottom: 40px;
					width: 103%;
					display: flex;
					flex-direction: column;

					.h3 {
						font-size: 26px;
					}

					.government_box {
						margin: 2vw 0;
						display: flex;

						.item {
							margin-right: 14px;
							display: block;
							vertical-align: top;
							width: calc((100% - 6.25vw) / 4);
							position: relative;
							background-color: #fff;
							transition: all 0.3s ease;
							cursor: pointer;

							.img_box {
								position: relative;
								opacity: 1;
								pointer-events: none;
								z-index: 2;
								overflow: hidden;
							}

							img {
								display: block;
								width: 100%;
								vertical-align: middle;
								border: none;
							}

							span {
								font-size: 40px;
								font-family: 'PingFangSC-Medium';
								color: #e5e5e5;
								line-height: 1;
								position: absolute;
								left: 1.5vw;
								top: 2vw;
								z-index: 3;
								transition: all 0.3s ease;
							}

							.txt-box {
								position: absolute;
								left: 1.5vw;
								right: 1.5vw;
								top: calc(100% - 2.5vw);
								transform: translateY(-100%);
								transition: all 0.3s ease;
								z-index: 3;
								text-align: left;

								h3 {
									font-size: 18px;
									transition: color 0.3s ease;
									font-weight: normal;
								}

								p {
									font-size: 14px;
									color: #808080;
									margin-top: 10px;
									transition: color 0.3s ease;
								}
							}
						}

						.item:hover .img_box {
							opacity: 0;
						}

						.item:hover .txt-box {
							color: #ffffff;
						}

						.item:hover {
							background-image: linear-gradient(45deg, #001066, #2f50a2);
							transform: translateY(-20px);
							box-shadow: 0 26px 40px -24px rgb(0 36 100 / 50%);

							span {
								display: none;
							}

							.txt-box {
								top: calc(100% - 13.5vw);
							}
						}
					}

					.government_box_v2 {
						width: 100%;
						display: flex;
						align-items: center;
						justify-content: center;
						height: 556px;
						background-image: url('https://img.i2soft.net/i2official-web/v2/assets/images/relations/cmpZhiLi.png');
						background-size: cover;
						background-position: center;
						background-repeat: no-repeat;

						.fourDetail {
							width: 70%;
							display: flex;
							justify-content: space-between;
							align-items: center;

							.dongshi {
								cursor: pointer;

								.dongshiName {
									margin-top: 20px;
									font-size: 36px;
									font-weight: 500;
									color: #ffffff;
								}

								.introduce {
									font-size: 24px;
									font-weight: 300;
									color: #ffffff;
									padding: 20px;
								}
							}

							.dongshi:hover {
								img {
									display: none;
								}

								transform: translateY(-60px);

								.dongshiName {
									color: #f49a42;
								}

								.introduce {
									color: #f49a42;
								}

								.clickDetail {
									display: flex;
									justify-content: center;
								}
							}

							.clickDetail {
								font-size: 30px;
								font-weight: 300;
								margin-top: 100px;
								color: #f49a42;
								display: none;
							}
						}
					}

					.inverstor-more {
						text-decoration: none;
						position: relative;
						left: 80%;
						width: 120px;
						line-height: 40px;
						font-size: 15px;
						display: block;
						color: #ffffff;
						text-align: center;
						background-image: linear-gradient(10deg, rgba(53, 135, 195, 1), rgba(36, 93, 161, 1));
						transition: all 0.3s ease;
					}
				}
			}

			.investor-index-s3 {
				margin: 0 auto;
				padding-bottom: 50px;
				width: 80%;

				.wrapper {
					display: flex;
					flex-direction: column;

					.h3 {
						font-size: 26px;
					}

					.conts {
						background-position: center;
						background-repeat: no-repeat;
						background-size: cover;
						background-image: url(https://img.i2soft.net/i2official-web/assets/images/relations/achievement_S_back.png);
						background-attachment: fixed;

						.cont {
							padding: 0px;
							height: 100%;
							margin: auto;
							width: 80%;
							background-color: #fff;
							display: flex;
							opacity: 0.9;

							.tab-tit {
								/*padding: 3.125vw 0;*/
								width: 140px;

								.cell-box {
									height: 33.33%;
									position: relative;
									text-align: center;
									cursor: pointer;

									.cell {
										margin-top: 25%;
										width: 100%;
										display: inline-block;
										vertical-align: middle;

										h3 {
											font-size: 18px;
										}

										h4 {
											font-size: 12px;
										}
									}
								}

								.item {
									height: 33.33%;
									position: relative;
									text-align: center;
									cursor: pointer;
								}

								.cell-box_active {
									color: #2f50a2;
									height: 33.33%;
									position: relative;
									text-align: center;
									cursor: pointer;
									border-right: 2px solid #2f50a2;

									.cell {
										margin-top: 25%;
										width: 100%;
										display: inline-block;
										vertical-align: middle;

										h3 {
											font-size: 18px;
										}

										h4 {
											font-size: 12px;
										}
									}
								}
							}

							.tab-cont {
								text-align: left;
								width: calc(100% - 140px);
								border-left: 1px solid #e6e6e6;

								.child.active {
									display: block;

									.more {
										text-decoration: none;
										font-size: 14px;
										color: #29489a;
										position: absolute;
										right: 1.5vw;
										bottom: 1.5vw;
									}
								}

								.child {
									display: none;
									position: relative;
									padding: 3.125vw 4.5vw 4vw;
								}

								.item:nth-of-type(1) {
									margin-top: 0;
									padding-top: 0;
									border: none;
								}

								.item {
									margin-top: 1.5vw;
									padding-top: 1vw;
									border-top: 1px solid #e6e6e6;
								}

								.year {
									font-size: 34px;
									line-height: 1;
									color: #29489a;
									font-family: 'ziticqtezhanti';
								}

								h3 {
									font-size: 18px;
									color: #333333;
									white-space: nowrap;
									overflow: hidden;
									text-overflow: ellipsis;
									margin-top: 10px;
								}

								.btns {
									margin-top: 15px;

									a {
										text-decoration: none;
										width: 120px;
										line-height: 40px;
										font-size: 15px;
										display: inline-block;
										vertical-align: top;
										color: #808080;
										text-align: center;
										border: 1px solid #e6e6e6;
										margin-right: 20px;
									}

									a:link {
										color: #333333;
									}

									a:visited {
										color: #333333;
									}

									a:hover {
										color: white;
										background-image: linear-gradient(10deg, rgba(53, 135, 195, 1), rgba(36, 93, 161, 1));
									}
								}
							}
						}
					}
				}

				.yjImgData {
					height: 800px;
					position: relative;

					.threeBao {
						position: absolute;
						bottom: 20%;
						margin: 0 auto;
						width: 100%;
						z-index: 99;

						.threeTitle {
							margin: 0 auto;
							width: 30%;
							display: flex;
							justify-content: space-between;

							.yearBao {
								cursor: pointer;
								font-size: 32px;
								font-weight: 300;
								color: #001f34;
							}

							.yearBao:hover {
								font-size: 36px;
								color: #f49a42;
								border-bottom: 2px solid #f49a42;
							}

							.yearBao_active {
								cursor: pointer;
								font-size: 32px;
								font-weight: 300;
								color: #f49a42;
								border-bottom: 2px solid #f49a42;
							}

							.centerBao {
							}

							.jiBao {
							}
						}

						.threeCard {
							width: 100%;
							margin: 0 auto;
							margin-top: 72px;
							//overflow: auto;
							display: flex;
							justify-content: center;

							.yearContent {
								display: flex;
								gap: 25px;
								//justify-content: space-between;
								overflow: auto;
								/**
                  grid布局
                   */
								//display: grid;
								//grid-auto-flow: column;
								//grid-template-columns: repeat(auto-fit,450px);
								//grid-gap: 25px 30px;
								//justify-content: center;
							}
						}
					}

					.yejiBg {
						position: absolute;
						width: 100%;
						bottom: 0;
						left: 50%;
						/* 水平居中 */
						transform: translateX(-50%);
						/* 水平向左偏移50% */
					}

					.moreYj {
						position: absolute;
						bottom: 36px;
						left: 50%;
						/* 水平居中 */
						transform: translateX(-50%);
						/* 水平向左偏移50% */
						color: white;
						font-size: 30px;
						font-weight: 300;
					}
				}
			}
		}
	}
</style>
