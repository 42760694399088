<!--
 * @Author: lyt
 * @Date: 2024-10-22 00:26:01
 * @LastEditTime: 2025-01-20 11:01:31
 * @LastEditors: lyt
 * @Description: 移动端-投资者关系-公司公告模块
 * @FilePath: /new-i2finance/official_web_portal/official/src/components/phone/AnnounceComp.vue
 *  
-->
<template>
	<div class="m-anc-wrapper">
		<h3>公告</h3>
		<!-- 时间轴 -->
		<div class="time">
			<div class="timeline">
				<div
					class="timeline-item"
					v-for="(year, index) in reversedYears"
					:key="index"
				>
					<div
						class="timeline-step"
						v-bind:class="{ 'focus-step': year?.value == curTimeLineYear }"
						@click="timelineClick(year?.value)"
					>
						<div class="timeline-circle"></div>
						<div
							class="line"
							v-if="index < reversedYears.length - 1"
						></div>
						<div class="timeline-date">{{ year?.value }}</div>
					</div>
				</div>
			</div>
		</div>
		<!-- 列表 -->
		<div class="list-container">
			<div class="list" v-if="announcements && announcements.length > 0">
				<a
					:href="announcement.url"
					target="_blank"
					v-for="announcement in announcements"
					:key="announcement.id"
				>
					<div class="item">
						<img
							class="left-icon"
							src="https://img.i2soft.net/i2official-web/v2/assets/images/relations/phone/anc_icon.svg"
						/>
						<div class="right-box">
							<div>{{ announcement.date }}</div>
							<div class="desc">{{ announcement.content }}</div>
						</div>
					</div>
				</a>
			</div>
			<el-empty v-else description="暂无数据" />
		</div>
		<!-- 分页-->
		<div class="pagination">
			<el-pagination
				background
				@current-change="handleCurrentChang"
				:current-page="page.currentPage"
				:page-size="page.pageSize"
				layout="prev, pager, next"
				:total="page.totle"
				size="small"
			>
			</el-pagination>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'AnnounceComp',
		props: {},
		data() {
			return {
				years: [
					{
						value: '2018',
						label: '2018年',
					},
					{
						value: '2019',
						label: '2019年',
					},
					{
						value: '2020',
						label: '2020年',
					},
					{
						value: '2021',
						label: '2021年',
					},
					{
						value: '2022',
						label: '2022年',
					},
					{
						value: '2023',
						label: '2023年',
					},
					{
						value: '2024',
						label: '2024年',
					},
				],
				months: [
					{
						value: '01',
						lable: '01',
					},
					{
						value: '02',
						lable: '02',
					},
					{
						value: '03',
						lable: '03',
					},
					{
						value: '04',
						lable: '04',
					},
					{
						value: '05',
						lable: '05',
					},
					{
						value: '06',
						lable: '06',
					},
					{
						value: '07',
						lable: '07',
					},
					{
						value: '08',
						lable: '08',
					},
					{
						value: '09',
						lable: '09',
					},
					{
						value: '10',
						lable: '10',
					},
					{
						value: '11',
						lable: '11',
					},
					{
						value: '12',
						lable: '12',
					},
				],
				curTimeLineYear: '', // 当前时间轴所选年份
				//分页
				page: {
					currentPage: 1,
					pageSize: 10,
					totle: 0,
				},
				datas: '',
				// 显示的数据
				announcements: [],
			};
		},
		filters: {
			// 年月过滤
			filter_month(value) {
				return value.substring(0, 7);
			},
			// 日期过滤
			filter_date(value) {
				return value.substring(8, 10);
			},
		},
		created() {
			this.curTimeLineYear = this.years[this.years.length - 1].value;
			this.getData(this.curTimeLineYear);
			window.scrollTo(0, 540);
		},
		mounted() {
			window.addEventListener('scroll', this.getScroll);
		},
		destroyed() {
			window.removeEventListener('scroll', this.getScroll);
		},
		computed: {
			reversedYears() {
				return this.years.slice().reverse();
			},
		},
		methods: {
			// 点击时间轴时间点
			timelineClick(cur) {
				this.curTimeLineYear = cur;
				this.getData(this.curTimeLineYear);
			},

			//获取数据并处理
			getData(year) {
				this.$axios.get('/parameter/query').then((res) => {
					let handleData = res.data.data;
					let filtersData = [];
					this.page.currentPage = 1;
					if (year && year.trim().length > 0) {
						handleData.forEach((item) => {
							item.years = item.date.substring(0, 4);
							item.monthes = item.date.substring(5, 7);
						});
						for (let i = 0; i < handleData.length; i++) {
							if (handleData[i].years.match(year)) {
								filtersData.push(handleData[i]);
							}
						}
						this.page.totle = filtersData.length;
					} else {
						filtersData = handleData;
						this.page.totle = handleData.length;
					}
					this.datas = filtersData;
					this.announcements = filtersData.slice((this.page.currentPage - 1) * this.page.pageSize, this.page.pageSize + (this.page.currentPage - 1) * this.page.pageSize);
				});
			},
			// 分页
			handleCurrentChang(newPage) {
				this.page.currentPage = newPage;
				this.announcements = this.datas.slice((this.page.currentPage - 1) * this.page.pageSize, this.page.pageSize + (this.page.currentPage - 1) * this.page.pageSize);
			},
			getScroll() {
				this.scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
				if (!!document.documentElement.scrollTop && document.documentElement.scrollTop >= 70) {
					this.$store.commit('TO_AfterScroll');
				} else {
					this.$store.commit('TO_AfterScroll_false');
				}
			},
		},
	};
</script>

<style lang="less" scoped>
	* {
		-moz-box-sizing: border-box;
		box-sizing: border-box;
		-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
	}

	.m-anc-wrapper {
		padding: 12px;

		.time {
			padding-top: 20px;
			margin-bottom: 10px;

			.timeline {
				display: flex;
				flex-direction: row;
				align-items: center;

				.timeline-item {
					position: relative;
					padding: 20px 0;
					width: 100%;

					.timeline-step {
						position: relative;
						text-align: center;
						cursor: pointer;

						.timeline-circle {
							width: 8px;
							height: 8px;
							background-color: #d8d9db;
							border-radius: 50%;
							margin: 0 auto;
							position: relative;
							z-index: 1;
						}

						.timeline-date {
							margin-top: 16px;
							font-size: 14px;
							color: #999999;
						}
					}

					.focus-step {
						.timeline-circle {
							background-color: #b08d57;
						}

						.timeline-date {
							color: #b08d57;
							font-weight: bold;
						}
					}

					.line {
						position: absolute;
						left: 50%;
						top: 3px;
						width: calc(100%);
						height: 2px;
						background: #d8d9db;
					}
				}
			}
		}
		.list-container {
			.list {
				display: flex;
				flex-direction: column;
				row-gap: 10px;

				a {
					text-decoration: none;
					background: #f8f8f8;
				}

				.item {
					padding: 16px 12px;
					display: flex;
					flex-direction: row;
					align-items: center;
					justify-content: flex-start;
					column-gap: 15px;
					.left-icon {
						width: 36px;
						min-width: 36px;
						height: 36px;
					}

					.right-box {
						display: flex;
						flex-direction: column;
						align-items: flex-start;
						justify-content: space-around;

						div {
							font-size: 12px;
							font-weight: normal;
							line-height: 14px;
							letter-spacing: 0.9px;
							color: #999999;
						}

						.desc {
							margin-top: 4px;
							text-align: left;
							font-weight: bold;
							color: #333333;
						}
					}
				}
			}
		}

		.pagination {
			width: 100%;
			padding: 30px 0px;
			overflow: hidden;
			display: flex;
			justify-content: center;
		}
	}

	/deep/ .el-pagination {
		padding: 0px;
		width: 100%;
	}
	/deep/.el-pagination.is-background .el-pager li {
		min-width: 25px;
		margin: 0 3px;
	}
	/deep/ .el-pagination.is-background .btn-prev,
	.el-pagination.is-background .el-pager li {
		min-width: 25px;
		margin: 0 3px;
	}
	/deep/ .el-pagination.is-background .btn-next,
	.el-pagination.is-background .btn-prev,
	.el-pagination.is-background .el-pager li {
		min-width: 25px;
		margin: 0 3px;
	}

	/deep/ .el-pagination.is-background .el-pager li:not(.disabled).active {
		background-color: #b08d57;
		color: #fff;
	}
</style>
