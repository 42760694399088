<template>
    <div class="container">
        <div v-if="!isMobile" class="pcWrapper">
            <div class="ach__relationsWrapper">
                <div class="ach_bannerWrapper">
                    <img src="https://img.i2soft.net/i2official-web/assets/images/dynamic/dyn_back.png" />
                    <div class="bannerTextWrapper">
                    </div>
                </div>
                <div class="announcementWrapper">
                    <div class="top_box">
                        <div class="pc_change">
                            <a @click.prevent="jump('anc')" v-bind:class="{ 'active': (staic == 'anc') }">公司公告</a>
                            <a @click.prevent="jump('gov')" v-bind:class="{ 'active': (staic == 'gov') }">公司治理</a>
                            <a v-bind:class="{ 'active': (staic == 'acv') }">业绩报告</a>
                        </div>
                    </div>
                </div>
                <div class="mapWrapper">
                    <div class="investor-report-s1">
                        <div class="wrapper">
                            <div class="block">
                                <div class="inner-tit gray">
                                    <WaveTitle style="margin-top: 20px" :title="'公司年报'"
                                        :subhead="'Company annual report'"></WaveTitle>
                                </div>
                                <div class="itemsv2">
                                    <div class="reporCards">
                                        <ReportCard v-for="annu in annualList" :key="annu.id" :card-year="'2019-2023'"
                                            :url-pdf="annu.to" :report-name="'年报'" :report-eng="'Annual Report'"
                                            :report-introduce="annu.name"></ReportCard>
                                    </div>
                                    <img class="cardsBottom"
                                        src="https://img.i2soft.net/i2official-web/v2/assets/images/relations/anualBottom.png" />
                                </div>
                            </div>
                            <div class="block">
                                <div class="inner-tit gray" style="margin-top: 200px">
                                    <WaveTitle style="margin-top: 20px" :title="'公司中报'"
                                        :subhead="'Company annual report'"></WaveTitle>
                                </div>
                                <div class="itemsv2">
                                    <div class="reporCards">
                                        <ReportCard v-for="annu in InterimList" :key="annu.id" :card-year="'2019-2024'"
                                            :url-pdf="annu.to" :report-name="'中报'" :report-eng="'Interim Report'"
                                            :report-introduce="annu.name"></ReportCard>
                                    </div>
                                    <img class="cardsBottom"
                                        src="https://img.i2soft.net/i2official-web/v2/assets/images/relations/anualBottom.png" />
                                </div>
                            </div>
                            <div class="block">
                                <div class="inner-tit gray" style="margin-top: 200px">
                                    <WaveTitle style="margin-top: 20px" :title="'公司季报'"
                                        :subhead="'Company annual report'"></WaveTitle>
                                </div>
                                <div class="itemsv2">
                                    <div class="reporCards">
                                        <ReportCard v-for="annu in QuarterlyList" :key="annu.id"
                                            :card-year="'2019-2024'" :url-pdf="annu.to" :report-name="'季报'"
                                            :report-eng="'Quarterly Report'" :report-introduce="annu.name"></ReportCard>
                                    </div>
                                    <img class="cardsBottom"
                                        src="https://img.i2soft.net/i2official-web/v2/assets/images/relations/anualBottom.png" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { isMobile } from "@/utils/index";
import WaveTitle from "@/components/WaveTitle";
import ReportCard from "@/views/V2/relations/componets/ReportCard";
export default {
    components: {
        WaveTitle,
        ReportCard
    },
    data() {
        return {
            isMobile: false,
            // 年报
            annualList: [],
            // 中报
            InterimList: [],
            // 季报
            QuarterlyList: [],
            datas: [],
            query_type: "",
            page: {
                totle: '',
                currentPage: 2,
                pageSize: 8
            },
            staic: '',
        };
    },
    filters: {},
    created() {
        this.getachievent();
        this.query_type = this.$route.query.category;
        console.log("this.query_type", this.query_type);
        this.pg_change(this.query_type);
        window.scrollTo(0, 540);
    },
    mounted() {
        this.isMobile = isMobile();
        window.addEventListener("scroll", this.getScroll);
    },
    destroyed() {
        window.removeEventListener("scroll", this.getScroll);
    },
    methods: {
        // 跳转
        jump(type) {
            if (type == "anc") {
                this.$router.push({ path: "/Announce", query: { category: type } });
            } else if (type == "gov") {
                this.$router.push({ path: "/government", query: { category: type } });
            } else if (type == "ser") {
                this.$router.push({ path: "/Service", query: { category: type } });
            }
        },
        // 跳转后tab标签的改变
        pg_change(type) {
            console.log("static", type);
            this.staic = type;
        },
        // 获取业绩表
        getachievent() {
            // 年报业报
            this.$axios.get("/parameter/annualList").then((res) => {
                this.annualList = res.data.data;
                console.log("年报业报", this.annualList)
            })
            // 中报业报
            this.$axios.get("/parameter/InterimList").then((res) => {
                this.InterimList = res.data.data;
                console.log("中报业报", this.InterimList)
            })
            // 季报业报
            this.$axios.get("/parameter/QuarterlyList").then((res) => {
                this.QuarterlyList = res.data.data;
                console.log("季报业报", this.QuarterlyList)
            })
        },
        getScroll() {
            // this.indexNumTop = $("#index_num").offset().top;
            this.scrollTop =
                window.pageYOffset ||
                document.documentElement.scrollTop ||
                document.body.scrollTop;
            if (!!document.documentElement.scrollTop && document.documentElement.scrollTop >= 70
            ) {
                //设置滚动大于300时的执行内容
                // console.log(
                //   "document.documentElement.scrollTop=",
                //   document.documentElement.scrollTop
                // );
                this.$store.commit("TO_AfterScroll");
            } else {
                this.$store.commit("TO_AfterScroll_false");
            }
        },
    },
};
</script>

<style lang="less" scoped>
* {
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.ach__relationsWrapper {
    background-color: #ffffff;

    .ach_bannerWrapper {
        width: 100%;
        position: relative;

        img {
            width: 100%;
            height: auto;
        }

        .bannerTextWrapper {
            position: absolute;
            top: 45%;
            left: 45%;
            display: flex;
            flex-direction: column;

            .bannerText1 {
                font-size: 30px;
                text-align: left;
                font-family: PingFangSC-Medium;
            }

            .bannerText2 {
                color: rgba(255, 255, 255, 100);
                font-size: 40px;
                text-align: left;
                font-family: PingFangSC-Medium;
            }

            .bannerText3 {
                color: rgba(255, 255, 255, 100);
                font-size: 30px;
                text-align: left;
                font-family: PingFangSC-Light;
            }
        }
    }

        .announcementWrapper {
            margin-top: -6px;
            background-color: #F4F0D7;
            padding: 0 20px;
            display: flex;
            flex-direction: row;
            justify-content: center;
    
            .top_box {
                width: 75%;
            }
    
            .pc_change {
                margin-right: 25px;
                display: flex;
                flex-direction: row;
                align-items: flex-end;
                align-items: center;
                justify-content: flex-end;
                column-gap: 31px;
    
                a {
                    padding: 20px 0;
                    cursor: default;
                    text-decoration: none;
                    color: #000000;
                    font-size: 15px;
                    cursor: pointer;
                }
    
                a:hover {
                    color: #F49A42;
                }
    
                .active {
                    color: #F49A42;
                }
    
            }
    
        }

    .mapWrapper {
        display: flex;
        flex-direction: column;
        margin: auto;
        padding: 0px;

        .investor-report-s1 {
            padding: 6.25vw 0;

            .wrapper {
                width: 100%;
                //max-width: 1560px;
                margin: 0 auto;
                position: relative;
                z-index: 2;

                .block:nth-of-type(n) {
                    margin-top: 0;

                    .inner-tit {
                        padding-bottom: 2vw;
                        //border-bottom: 2px solid #333333;

                        h3 {
                            font-size: 26px;
                            color: #333333;
                        }
                    }

                    .items {
                        margin-top: 3.125vw;
                        position: relative;

                        .year {
                            font-size: 50px;
                            width: 15%;
                            margin-right: 2%;
                            margin-top: 20px;
                            display: inline-block;
                            vertical-align: top;
                            line-height: 1;
                            font-family: 'ziticqtezhanti';
                            word-wrap: break-word;
                            overflow: hidden;
                        }

                        .lists {
                            width: 80%;
                            display: inline-block;
                            vertical-align: top;

                            .list {
                                position: relative;
                                padding: 2vw 0;
                                border-bottom: 1px solid #e6e6e6;

                                .h3 {
                                    text-align: left;
                                    font-size: 20px;
                                    margin-right: 280px;
                                    white-space: nowrap;
                                    overflow: hidden;
                                    text-overflow: ellipsis;
                                    color: #333333;
                                }

                                .btns {
                                    position: absolute;
                                    right: 0;
                                    top: 50%;
                                    transform: translateY(-50%);

                                    a {
                                        text-decoration: none;
                                        margin-left: 20px;
                                        display: inline-block;
                                        vertical-align: top;
                                        text-align: center;
                                        border: 1px solid #e6e6e6;
                                        width: 120px;
                                        line-height: 40px;
                                        font-size: 15px;
                                    }

                                    a:link {
                                        color: #333333;
                                    }

                                    a:visited {
                                        color: #333333;
                                    }

                                    a:hover {
                                        color: white;
                                        background-image: linear-gradient(10deg, rgba(53, 135, 195, 1), rgba(36, 93, 161, 1));
                                    }
                                }
                            }
                        }
                    }

                    .itemsv2 {
                        position: relative;

                        .reporCards {
                            margin: 0 auto;
                            z-index: 99;
                            width: 75%;
                            //display: flex;
                            //justify-content: center;
                            //align-items: center;
                            //flex-wrap: wrap;
                            //row-gap: 20px;
                            display: grid;
                            grid-template-columns: repeat(auto-fit, 450px);
                            grid-gap: 25px 15px;
                            justify-content: center;
                        }

                        //.reporCards > * {
                        //  margin: 0 20px 20px 0; /* 上、右、下、左 的外边距 */
                        //}
                        //
                        //.reporCards > *:last-child {
                        //  margin-right: 0; /* 移除最后一个子元素的右边距 */
                        //}
                        img {
                            z-index: -1;
                            position: absolute;
                            width: 100%;
                            bottom: 0;
                            left: 50%;
                            /* 水平居中 */
                            transform: translate(-50%, 40%);
                            /* 水平向左偏移50% */
                        }

                        .cardsBottom {
                            position: absolute;
                            bottom: 0;
                        }
                    }
                }
            }
        }

    }
}
</style>
