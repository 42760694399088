<!--
 * @Author: lyt
 * @Date: 2025-01-17 14:01:57
 * @LastEditTime: 2025-01-17 18:59:06
 * @LastEditors: lyt
 * @Description: 移动端-加入我们-社会招聘
 * @FilePath: /new-i2finance/official_web_portal/official/src/components/phone/SocialRecruitmentComp.vue
 *  
-->
<template>
	<div class="m-soc-wrapper">
		<div class="m-soc-wrapper-content">
			<div
				class="m-soc-search"
				@keydown.enter="searchJob"
			>
				<el-row>
					<el-col :span="12">
						<div class="m-search-box-type">
							<el-select
								v-model="selectCategory"
								placeholder="请选择"
								@change="selectChanged"
							>
								<el-option
									v-for="(item, index) in category"
									:key="index"
									:label="item.name"
									:value="item.id"
								>
								</el-option>
							</el-select>
						</div>
					</el-col>
					<el-col :span="12">
						<div class="m-search-box-city">
							<el-select
								v-model="selectCity"
								placeholder="请选择"
								@change="selectChanged"
							>
								<el-option
									v-for="(item, index) in city"
									:key="index"
									:label="item.name"
									:value="item.id"
								>
								</el-option>
							</el-select>
						</div>
					</el-col>
				</el-row>
			</div>
			<div class="m-soc-job-box">
				<el-collapse accordion>
					<el-collapse-item
						class="m_JD-item"
						v-for="(item, index) of jobDescList"
						:key="index + 100"
					>
						<template #title>
							<div class="m_collaTop">
								<div class="m_collaTopBefore">
									<p class="m_title">{{ item.title }}</p>
									<p class="m_city">地点：{{ getCityName }}</p>
								</div>
							</div>
						</template>
						<div style="margin-top: 20px">
							<p class="m_claim">任职资格</p>
							<ul
								v-html="item.jobClaim"
								class="m_claim-list"
							></ul>
							<div
								style="margin-top: 20px; text-align: center"
								id="m_inputBut"
							>
								<div class="child">
									<el-input
										v-model="postName"
										placeholder="姓名*"
									></el-input>
								</div>
								<div class="child">
									<el-input
										v-model="postPhone"
										placeholder="电话*"
									></el-input>
								</div>
								<div class="child">
									<el-input
										v-model="postMail"
										placeholder="邮箱*"
									></el-input>
								</div>
							</div>
							<div
								class="m_uploadWrapper"
								style="text-align: left"
							>
								<el-row class="m_uploadBox">
									<p
										class="m_upr"
										style=""
										@click="selectFile"
									>
										{{ fileName }}
									</p>
									<input
										type="file"
										id="userFile"
										title="none"
										class="m_uploadInput"
										style="opacity: 0; position: absolute; z-index: -999"
										@change="getFiles"
									/>

									<el-button
										class="m_commitBtn"
										type="primary"
										@click="postResume(item.title)"
										>提交</el-button
									>
								</el-row>
							</div>

							<div class="mailDiv">
								<p>
									简历可直接发送以下邮箱：<a
										class="email"
										href="https://exmail.qq.com/login"
										>zhaopzx@i2finance.net</a
									>
								</p>
							</div>
						</div>
					</el-collapse-item>
				</el-collapse>
				<div
					v-if="jobDescList.length <= 0"
					class="empty"
				>
					<el-empty description="暂无开放职位，敬请期待…" />
				</div>
			</div>
		</div>
		<!-- 提交结果弹窗 -->
		<el-dialog :visible.sync="isShowSubmitInfo">
			<div class="dialog-title">
				<img
					class="dialog-title-img"
					src="https://img.i2soft.net/i2official-web/v2/assets/images/joinus/close.png"
					alt=""
					@click="closeSubmitDialog()"
				/>
				<p
					class="dialog-title-info"
					v-html="this.postName"
				></p>
				<div class="dialog-title-hint">
					<div>
						<img
							class="dialog-title-icon"
							src="https://img.i2soft.net/i2official-web/assets/images/joinus/icon_phone.svg
              "
							alt=""
						/>
						<span>{{ postPhone }}</span>
					</div>
					<div>
						<img
							class="dialog-title-icon"
							src="https://img.i2soft.net/i2official-web/assets/images/joinus/icon_email.svg
              "
							alt=""
						/>
						<span>{{ postMail }}</span>
					</div>
				</div>
			</div>
			<div class="dialog-content">
				<div class="dialog-content-submit_success">
					<img
						src="https://img.i2soft.net/i2official-web/assets/images/joinus/submit_success.svg"
						alt=""
					/>
					<div>
						<div>感谢您的投递</div>
						<div>我们将尽快完成筛选，通过后会尽快联系您！</div>
					</div>
				</div>
			</div>
			<template #footer>
				<div class="dialog-success-btn">
					<el-button
						class="commitBtn"
						type="primary"
						@click="closeSubmitDialog"
						>关闭</el-button
					>
				</div>
			</template>
		</el-dialog>
	</div>
</template>

<script>
	import { isMobile } from '@/utils/index';
	import * as qiniu from 'qiniu-js';
	import axios from 'axios';
	import { JSEncrypt } from 'jsencrypt';
	import arrowLeft from '@/assets/svg/arrow-left.svg';
	import arrowRiht from '@/assets/svg/arrow-right.svg';

	export default {
		name: 'FirSocialRecruitmentCompst',
		components: {},
		data() {
			return {
				screenWidth: document.body.clientWidth, // 屏幕宽
				computerHeight: 0,
				baseUrl: 'http://internal.i2soft.net:11624',
				//  baseUrl: 'http://192.168.61.48:9096/website/submitResume',
				// baseUrl: 'http://175.102.16.12:9096/website/submitResume',
				//  baseUrl : 'http://bird3.i2soft.net',
				isMobile: false,
				showPage: 0, // 校园招聘
				query_type: 0, // 通过路由传递过来的参数
				selectCategory: null, // 选中的分类
				selectCity: 0, // 选中的城市
				isShowSubmitInfo: false, // 提交成功弹窗显隐控制
				category: [
					{ id: 0, name: '所有类别' },
					{ id: 1, name: '用户体验设计类' },
					{ id: 2, name: '技术研发类' },
					{ id: 3, name: '商务拓展类' },
					{ id: 4, name: '人力资源类' },
					{ id: 5, name: '产品（场景）设计分析类' },
					{ id: 6, name: '运营类' },
				],
				city: [
					{ id: 0, name: '所有城市' },
					{ id: 1, name: '北京市' },
					{ id: 2, name: '上海市' },
					{ id: 3, name: '广州市' },
					{ id: 4, name: '珠海市' },
					{ id: 5, name: '杭州市' },
					{ id: 6, name: '西安市' },
					{ id: 7, name: '成都市' },
					{ id: 8, name: '武汉市' },
					{ id: 9, name: '合肥市' },
				],
				allJD: [
					{
						id: 8,
						title: 'Java工程师',
						description: '',
						jobClaim: ` <li>本科及以上学历，计算机或数学相关专业；</li>
                <li>热爱技术工作，有较强的工作独立性和研究能力；</li>
                <li>有2-10年Java开发工作经验；</li>
                <li>精通Java技术，熟悉Spring/ Spring Boot框架，并了解其核心原理；</li>
                <li>将职业规划方向设定为技术专家；</li>
                <li>抗压能力强，责任心强。</li>`,
						jobCategory: 2,
						jobCity: [0],
					},
					{
						title: '测试工程师',
						description: '',
						jobClaim: ` <li>本科及以上学历，计算机或数学相关专业；</li>
                <li>沟通、理解能力强，工作积极主动；</li>
                <li>有2-10年的软件测试经验、熟悉软件开发测试流程与软件测试方法，熟悉常用的测试工具，具备良好的问题分析能力与软件测试用例设计能力；</li>
                <li>有互联网应用、金融渠道类应用测试经验优先。</li>`,
						jobCategory: 2,
						jobCity: [0],
					},
					{
						title: '大数据开发工程师',
						description: '',
						jobClaim: ` <li>本科及以上学历，计算机或数学相关专业；</li>
                <li>2-10年大数据相关项目经验；</li>
                <li>熟悉hadoop、storm、hive、spark、Kafka、Phoenix等应用；</li>
                <li>英语良好；</li>
                <li>优秀的团队协作、解决问题的能力。</li>`,
						jobCategory: 2,
						jobCity: [0],
					},
					{
						title: '项目（技术）经理',
						description: '',
						jobClaim: ` <li>本科及以上学历，计算机或数学相关专业；</li>
                <li>有2-10年的项目管理经验，JAVA基础扎实，精通IO、多线程、集合等基础框架，精通分布式、缓存、消息、搜索等机制；</li>
                <li>精通struts、Spring、Hibernate、MyBatis应用框架；</li>
                <li>精通HTML、面向对象JavaScript技术，以及框架jQuery</li>
                <li>精通面向对象设计方法和设计模式，逻辑能力佳，熟悉UML设计工具；</li>
                <li>熟练掌握至少一种数据库Oracle、MySQL</li>
                <li>具有很强的编码功底，有过业务设计经验，能解决疑难技术问题；</li>
                <li>有过银行项目或互联网金融类平台开发经验的优先。</li>`,
						jobCategory: 2,
						jobCity: [0],
					},
					{
						id: 1,
						title: '交互设计',
						description: '',
						jobClaim: ` <li>本科及以上学历，计算机、设计类、金融或数学相关专业;</li>
                <li>产出：原型设计图，drd交互文档，产品流程图;</li>
                <li>2-10年以上交互设计经验，能主控业务的整个设计过程，并有成功案例；</li>
                <li>具备产品思维，同时能很好的结合设计思维，并能找到在设计中两者间的平衡点；</li>
                <li>具有数据分析能力，熟悉金融行业者优先；</li>
                <li>熟练掌握专业原型设计软件，如Axure、Visio、Sketch等；</li>
                <li>能够提供高质量的产品原型、流程图、线框图等，能清晰表达设计方案；</li>
                <li>熟练运用Axure、sketch等工具，有一定视觉设计能力。</li>`,
						jobCategory: 1,
						jobCity: [0],
					},
					{
						id: 2,
						title: '动效设计',
						description: '',
						jobClaim: ` <li>本科及以上学历，计算机、设计类、金融或数学相关专业；</li>
               <li>2-10年以上相关工作经验；</li>
                <li>熟练掌握设计工具,具备用任意一种形式(如Origami、 Principle、 Framer)输出高保真可交互demo的能力；</li>
                <li>充分掌握角色运动规律、表现技巧,合理地运用角色动画制作软件(如AE、fash、 spIne等),利用变形、夸张、拟人等艺术手法来创造有温度的IP形象,并落实到产品；</li>
                <li>有优秀的学习能力,良好的审美,拥有良好的沟通表达能力,能够快速吸收并掌握新的设计方法、流程和工具。</li>`,
						jobCategory: 1,
						jobCity: [0],
					},
					{
						id: 3,
						title: '用户研究',
						description: '',
						jobClaim: ` <li>本科及以上学历，计算机、金融或数学相关专业；</li>
                <li>至少2-10年以上市场调研或用户研究行业工作经验，精通定量和定性研究方法；</li>
                <li>优秀的逻辑与分析能力。</li>`,
						jobCategory: 1,
						jobCity: [0],
					},
					{
						id: 4,
						title: '平面设计',
						description: '',
						jobClaim: ` <li>本科及以上学历，计算机、设计类、金融或数学相关专业；</li>
                <li> 为人正直，诚实守信，认真负责； 有插画 和3d 设计的优先；</li>
                <li>2-10年以上相关工作经验；</li>
                <li>拥有完整设计理念、良好美术基础和审美能力；</li>
                <li>能够熟练运用PS, AI、C4D等工具执行设计理念；</li>
                <li>具有团队合作精神，优秀的分析、解决问题能力，快速学习能力，工作态度积极主动；</li>
                <li>投递简历附带作品谢谢！</li>`,
						jobCategory: 1,
						jobCity: [0],
					},
					{
						id: 5,
						title: '角色建模师',
						description: '',
						jobClaim: `  <li>本科及以上学历，计算机、设计类、金融或数学相关专业；</li>
                <li>2-10年以上相关工作经验；</li>
                <li>美术设计类相关专业优先。了解人体解剖学，及优秀的角色形体塑造能力；</li>
                <li>具备自主创建及调优角色设置以达到预期美术效果的实际上手能力；</li>
                <li>熟练掌握多种3DMAX、MAYA、Zbrush、CC3/iclone7、iblender、Daz等建模软件；</li>
                <li>熟悉UE4引擎，有相关3D角色相关制作经验，能熟练使用相关软件制作3D仿真角色；</li>
                <li>做事认真细致，具有高度的责任感和较强的团队合作精神。</li>`,
						jobCategory: 1,
						jobCity: [0],
					},
					{
						id: 6,
						title: '高级架构师',
						description: '',
						jobClaim: `  <li>本科及以上学历，计算机、金融或数学相关专业；</li>
                <li>2-10年以上工作经验, 3年以上银行IT行业交易型系统架构设计经验、具备平台架构或分布式系统架构设计经验，具备业务中台开发/设计经验者优先；</li>
                <li>具备腾讯技术平台TSF设计开发经验者优先；</li>
                <li>扎实的计算机技术背景，精通Java，熟悉和掌握分布式系统的架构设计，熟悉和精通常用的Java类库和ZooKeeper、dubbo、Spring Boot、Spring Cloud、Redis等开源框架和软件；</li>
                <li>深入理解面向对象编程和面向服务企业架构（SOA），具备丰富的微服务及基于微服务架构的平台分析设计经验，熟悉常用设计、开发工具；</li>
                <li>责任心强，有良好的团队合作精神和较强的沟通表达能力，具备团队管理经验者优先。</li>`,
						jobCategory: 2,
						jobCity: [0],
					},

					{
						id: 7,
						title: '中高级UE4/C研发工程师',
						description: '',
						jobClaim: ` <li>本科及以上学历，计算机、金融或数学相关专业；</li>
                <li>热爱技术工作，有较强的工作独立性和研究能力；</li>
                <li>具备2-10年以上C、C++语言开发经验；</li>
                <li>精通UE4虚幻引擎技术，熟悉蓝图、材质、Actor、MetaHumans、视频流推送、LiveLinkFace等；</li>
                <li>将职业规划方向设定为技术专家；</li>
                <li>抗压能力强，责任心强；</li>
                <li>有游戏行业开发经验者优先。</li>`,
						jobCategory: 2,
						jobCity: [0],
					},
					{
						id: 9,
						title: 'iOS前端工程师',
						description: '',
						jobClaim: ` <li>本科及以上学历，计算机、金融或数学相关专业；</li>
                <li>2-10年以上工作经验，具备手机银行、移动展业、开放银行、直销银行平台开发设计经验者优先；</li>
                <li>具备腾讯TMF移动框架经验者优先;</li>
                <li>熟悉Objective-C／Swift，熟悉Cocoa设计模式，深入理解MVC MVVM;</li>
                <li>有一定的H5和JS基础;</li>
                <li>对移动金融产品类APP的的安全性能有一定的了解。</li>
                <li>对性能优化有一定的自己的见解和了解。</li>
                <li>态度积极向上，有责任心，有较强的自学能力。</li>`,
						jobCategory: 2,
						jobCity: [0],
					},
					{
						id: 10,
						title: '安卓前端工程师',
						description: '',
						jobClaim: ` <li>本科及以上学历，计算机、金融或数学相关专业；</li>
                <li>2-10年以上工作经验，具备手机银行、移动展业、开放银行、直销银行平台开发设计经验者优先；</li>
                <li>具备腾讯TMF移动框架经验者优先;</li>
                <li>精通 Android APP 开发，计算机基础扎实，熟悉常见数据结构、算法和设计模式，熟悉数据库开发，熟悉 TCP/IP、HTTP 等网络协议；</li>
                <li>有丰富的软件架构设计经验，能快速搭建、调试或重构大规模软件代码；</li>
                <li>精通 Android UI 界面、动画绘制、多线程，对 HTTP/HTTPS/Socket 等网络通信协议有深刻理解和经验；</li>
                <li>对性能优化有过深入和系统的理解，并有实战经验；</li>
                <li>对至少2个底层库的源码级别研究的经验；并有blog等知识输出;</li>
                <li>责任心强，有良好的团队合作精神和较强的沟通表达能力。</li> `,
						jobCategory: 2,
						jobCity: [0],
					},
					{
						id: 11,
						title: 'H5前端开发工程师',
						description: '',
						jobClaim: ` <li>本科及以上学历，计算机、金融或数学相关专业；</li>
                <li>2-10年以上工作经验, 有银行IT行业开发经验优先、具备小组长或项目经理经验者优先；</li>
                <li>精通react或vue.js框架及周边技术包括但不仅限于vuex/redux，能理解目前流行的框架(react/Vue)的设计思路并能进行源码分析;</li>
                <li>基本功扎实，精通HTML5，CSS3，ES6，ES7，flex布局，熟悉ECMAScript语言规范，有丰富的前端工程方面的经验;</li>
                <li>基于Vue.js和React完成web前端项目，进行性能优化；</li>
                <li>熟练使用webpack,babel，有前端模块化，工程化，自动化实践，前端性能优化经验;</li>
                <li>注重代码质量，设计经验丰富，能够产出高质量的设计和代码，了解前沿技术和技术难点攻克;</li>
                <li>责任心强，有良好的团队合作精神和较强的沟通表达能力。</li>`,
						jobCategory: 2,
						jobCity: [0],
					},
					{
						id: 13,
						title: '商务专员',
						description: '',
						jobClaim: ` <li>本科及以上学历，计算机、金融或数学相关专业；</li>
                <li>2-10年以上相关工作经验；</li>
                <li>有合同跟单流程、物品采购流程，接受过商务谈判、商务合同起草等方面的培训者优先；</li>
                <li>具备较强的业务执行能力及沟通协调能力；</li>
                <li>具有优秀的综合分析能力及观察发现能力；</li>
                <li>熟悉Word、Excel、PPT等常用办公软件的操作；</li>
                <li>性格活泼，做事认真仔细，有一定的责任心。</li>`,
						jobCategory: 3,
						jobCity: [0],
					},
					{
						id: 14,
						title: '销售经理',
						description: '',
						jobClaim: ` <li>本科及以上学历，计算机、金融或数学相关专业，有银行ITO业务销售经验；</li>
                <li>2-10年以上相关工作经验；</li>
                <li>熟悉人月制和项目制的业务模式，并发挥销售在其中的价值；</li>
                <li>稳定性好。</li>`,
						jobCategory: 3,
						jobCity: [0],
					},
					{
						id: 15,
						title: '招聘主管',
						description: '',
						jobClaim: ` <li>本科及以上学历，计算机、金融或数学相关专业，2-10年以上IT外包行业招聘经验，有银行人力资源外包招聘经验优先；</li>
                <li>熟练掌握面试技巧，能够迅速准确地理解需求，独立进行面试和甄选；</li>
                <li>熟悉各种招聘渠道的管理，可有效拓展招聘渠道；</li>
                <li>具有较强的自我驱动力执行力和责任心，团队合作意识强；</li>
                <li>具有较强的表达沟通和协调能力，有大局观；</li>
                <li>熟悉IT行业招聘特点，接受乙方招聘模式。</li>`,
						jobCategory: 4,
						jobCity: [0],
					},
					{
						id: 16,
						title: '招聘专员',
						description: '',
						jobClaim: ` <li>本科及以上学历，计算机、金融或数学相关专业；</li>
                <li>2-10年以上相关工作经验；</li>
                <li>善于表达，良好的语言组织能力及沟通能力；</li>
                <li>良好的自我驱动性，目标感、执行力、抗压能力强；</li>
                <li>熟悉乙方互联网技术招聘特点，有IT外包技术岗位招聘经验者优先，擅长技术岗位招聘优先。</li>`,
						jobCategory: 4,
						jobCity: [0],
					},
					{
						id: 17,
						title: 'HRBP',
						description: '',
						jobClaim: ` <li>本科及以上学历，计算机、金融或数学相关专业，2-10年以上相关岗位工作经验，互联网软件行业经验优先；</li>
                <li>对于人力资源业务伙伴有自己的理解与定位，善学习总结；</li>
                <li>具有服务意识与业务支持意识，有同理心与责任心，沟通协调佳，具备一定的抗压能力；</li>
                <li>有良好的职业道德与职业素养。</li>`,
						jobCategory: 4,
						jobCity: [0],
					},
					{
						id: 18,
						title: '财务运营岗',
						description: '',
						jobClaim: ` <li>本科及以上学历，计算机、金融或数学相关专业，2-10年以上工作经验，有财务相关工作经验优先，有会计从业资格证书或初级会计师职称优先；</li>
                <li>熟练使用Excel，对数据敏感，清晰的逻辑思维；</li>
                <li>有良好的沟通协调能力，进行跨部门的工作交流与对接；</li>
                <li>有责任心与良好的职业道德。</li>`,
						jobCategory: 4,
						jobCity: [0],
					},
					{
						id: 19,
						title: '产品经理',
						description: '',
						jobClaim: ` <li>本科及以上学历，计算机、金融或数学相关专业；</li>
                <li>有2-10年的银行、互联网金融从业经验；</li>
                <li>有较强的学习能力、换位思考能力，善于产品分析和总结归纳，熟悉并产品运营，具备产品创新意识及用户视角；</li>
                <li>逻辑思维能力强，善于抽象, 抽象问题是什么意思和简化复杂问题，并给出可行性强的解决方案；</li>
                <li>具有很好的跨团队沟通、沟通表达和协调能力，抗压能力强，工作责任心强，执行力强。</li>`,
						jobCategory: 5,
						jobCity: [0],
					},
					{
						id: 20,
						title: '需求分析师',
						description: '',
						jobClaim: ` <li>本科及以上学历，计算机、金融或数学相关专业；</li>
                <li>2-10年以上工作经验，具备1年以上软件业务需求分析工作经验，具备银行/互联网移动App客户端产品（渠道应用）业务分析/设计工作经验者优先；</li>
                <li>具备银行、保险行业移动展业、CRM平台产品开发/设计经验者优先；</li>
                <li>具备个人网上银行、手机银行、直销银行平台产品开发/设计经验者优先；</li>
                <li>能够熟练使用墨刀、Axure等原型设计工具软件，设计制作产品需求原型；</li>
                <li>有较强的业务逻辑分析能力，能够准确快速的把握需求要点；</li>
                <li>具备良好的需求文档（功能分析说明书）编写能力。</li>
                <li>善于表达和沟通，团队协作能力强，具备良好的学习能力和责任心。 </li>`,
						jobCategory: 5,
						jobCity: [0],
					},
					{
						id: 21,
						title: 'UI设计师',
						description: '',
						jobClaim: ` <li>本科及以上学历，计算机、设计类、金融或数学相关专业；</li>
                <li>2-10年以上从事设计行业工作经验，具有移动端用户界面设计经验；</li>
                <li>具备较高的艺术素养和较强视觉表现力，富于创新、思维活跃，并对先进的设计理念和技术有较强的理解能力，并对新事物有足够的敏感度；</li>
                <li>良好的表达沟通能力和团队协作精神； </li>
                <li>能够熟练使用各类设计软件，如：Photoshop、Illustrator、Sketch和常用的原型设计工具（Axure、OmniGraffe等）。</li>`,
						jobCategory: 5,
						jobCity: [0],
					},
				],
				allJob: [],
				jobName: '',
				jobDescList: [],
				postName: '',
				postPhone: '',
				postMail: '',
				positionName: '',
				fileData: undefined,
				fileName: '请上传简历',
				key: '',
				putExtra: {
					fname: '',
					params: {},
					mimeType: null,
				},
				config: {
					useCdnDomain: true,
					region: qiniu.region.z0,
				},
				timeStampKey: undefined,
				//rsa加密
				encrypt: undefined,
				//v2新加data
				arrowLeft: arrowLeft,
				arrowRiht: arrowRiht,
				selectedJob: {},
			};
		},
		computed: {
			// 计算最上面图片高度
			imgHeight() {
				let aaa = (300 / 1920) * this.screenWidth + 'px';
				// console.log(aaa);
				return aaa;
			},
			// 根据选中值获取城市
			getCityName() {
				let cityName = this.city[this.selectCity].name;
				return cityName === '所有城市' ? '全国' : this.city[this.selectCity].name;
			},
		},
		created() {
			this.query_type = parseInt(this.$route.query.category);
			this.showPage = this.query_type === 99 ? 1 : 0;
			// this.selectCategory = this.query_type && this.query_type !== 99 ? this.query_type : 0;
			this.selectCategory = 0; // 默认取所有类别
		},
		mounted() {
			this.isMobile = isMobile();
			const newAllJD = [];
			this.allJD.forEach((item, index) => {
				let newItem = { ...item };
				newItem.id = index + 1;
				// 将带有新 id 的元素添加到新数组中
				newAllJD.push(newItem);
			});
			this.allJD = newAllJD;
			this.jobDescList = this.allJD;
			this.allJob = this.allJD;
			// this
			this.selectJobCategory(this.selectCategory);

			// 加密
			this.encrypt = new JSEncrypt({});
			this.encrypt.setPublicKey('MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQCBpQeOuKBmvx+1uThF1PGNFaGZ6dEbRdCEsH/sxnoyDc0nDjcNXL8rXADjHjO1vVDrksXLn0JwYtF2DVLGz6vSkL029d5g1pH4QqZq+7QaWgIY7kvv0Ob67FSxP3irEg0MXJW6jrvvfZN7+YqYofwx7bz0ffyHHZXEEkfkvhY/oQIDAQAB');
			window.addEventListener('scroll', this.getScroll);
			// 计算 图片高度
			this.computerHeight = (300 / 1920) * this.screenWidth + 'px';

			this.selectedJob = this.allJob[0];
		},
		destroyed() {
			window.removeEventListener('scroll', this.getScroll);
		},

		methods: {
			onChangePage(item) {
				if (item == 0) {
					this.selectCategory = 0;
					this.selectCity = 0;
				}
				this.showPage = item;
				// 改变工作数据源
				this.selectJobCategory(this.selectCategory);
			},
			// 跳转招聘考试页面
			goToExam() {
				this.$router.push({ path: '/examList' });
			},
			selectChanged() {
				// 改变工作数据源
				this.selectJobCategory(this.selectCategory);
			},
			selectJobCategory(id) {
				this.jobDescList = this.allJob.filter((item) => (id === 0 || item.jobCategory === id) && (this.selectCity === 0 || item.jobCity.includes(this.selectCity) || item.jobCity.includes(0)));
				this.selectCategory = id;
			},
			selectJobCity(id) {
				this.jobDescList = this.allJob.filter((item) => (item.jobCity.includes(0) || id === 0 || item.jobCity.includes(id)) && (this.selectCategory === 0 || this.selectCategory === item.jobCategory));
				this.selectCity = id;
			},
			selectFile() {
				let file = document.getElementById('userFile');
				file.click();
			},
			searchJob() {
				this.selectedJob = {};
				this.jobDescList = this.allJob.filter((item) => item.title && item.title.includes(this.jobName));
			},
			getFiles(e) {
				this.fileData = e.target.files;
				let date = new Date();
				this.fileName = this.fileData[0].name;
				this.key = this.fileData[0].name.replace('.', '' + date.getFullYear() + (date.getMonth() + 1) + (date.getDay() - 1) + date.getHours() + date.getMinutes() + date.getSeconds() + '.');
				console.log(this.key);
			},
			postResume(name) {
				if (this.postName.length <= 0 || this.postName.length > 30) {
					this.$message({
						message: '请输入正确的姓名！',
						type: 'warning',
					});
					return false;
				}
				if (this.postPhone.length != 11) {
					this.$message({
						message: '请输入正确的手机号',
						type: 'warning',
					});
					return false;
				}
				if (this.postMail.length <= 0 || this.postMail.length > 30) {
					this.$message({
						message: '请输入正确的邮箱',
						type: 'warning',
					});
					return false;
				}
				if (this.fileData == null || this.fileData[0].length <= 0) {
					this.$message({
						message: '请选择上传的简历',
						type: 'warning',
					});
					return false;
				}
				this.positionName = name;
				this.isShowSubmitInfo = true;
			},
			// 校园招聘提交简历
			onChangeInfo(info) {
				this.key = info.key;
				this.fileData = info.fileData;
				this.postName = info.postName;
				this.postPhone = info.postPhone;
				this.postMail = info.postMail;
				this.positionName = info.postName;
				this.isShowSubmitInfo = true;
				// this.postResume(info.positionName);
			},

			closeSubmitDialog() {
				this.isShowSubmitInfo = false;
				this.clearInfo();
			},
			clearInfo() {
				this.postName = '';
				this.postPhone = '';
				this.postMail = '';
				this.fileData = null;
				this.key = '';
				this.fileName = '请上传简历';
			},

			async updateFile() {
				if (this.fileData == null || this.fileData[0].length <= 0) {
					this.$message({
						message: '请选择上传的简历',
						type: 'warning',
					});
					return false;
				}
				let token = await this.getToken();
				if (token.code != '000000') {
					this.$message.error('token值获取失败,请稍后重试!');
					this.clearInfo();
					return false;
				}
				token = token.data;

				qiniu.getHeadersForChunkUpload(token);
				const observable = qiniu.upload(
					this.fileData[0], //文件数组
					this.key,
					token,
					this.putExtra,
					this.config
				);
				const observer = {
					next: (response) => {
						if (Math.floor(response.total.percent) >= 100) {
							console.log(Math.floor(response.total.percent));
						}
					},
					error: (err) => {
						// 失败
						this.$message({
							message: '上传简历失败，请重试！',
							type: 'error',
						});
						console.log(err);
						this.clearInfo();
					},
					complete: (response) => {
						axios
							.post(this.baseUrl + '/website/submitResume', {
								cvName: this.postName,
								cvEmail: this.postMail,
								s3: this.positionName,
								cvMobile: this.postPhone,
								cvUrl: this.key,
								s: this.encrypt.encrypt(this.timeStampKey),
							})
							.then((res) => {
								if (res.data.code == '000000') {
									console.log('上传简历成功的应答~~~~~', res);
									this.$message({
										message: '上传简历成功！',
										type: 'success',
									});
								} else {
									this.$message({
										message: '上传简历失败，请重试！',
										type: 'error',
									});
								}
								this.clearInfo();
							})
							.catch(function (error) {
								this.$message({
									message: '上传简历失败！',
									type: 'error',
								});
								console.log(error);
								this.clearInfo();
							});
						console.log(response);
					},
				};
				observable.subscribe(observer); // 上传开始
			},

			async getToken() {
				return new Promise((resolve, reject) => {
					this.timeStampKey = new Date().valueOf() + '';
					axios
						.get(this.baseUrl + '/website/getCertificate', {
							params: {
								// token:'cYpWndRpfb4IXWgMYCRSwry7xs9o17IwraKge1vjY6lMuV/DxmUaFUm9u8hytgB/LjIz7dbXDAt88Nd2/qDUMmN7ubFjKHrTkT%2BLxNNtoX%2Bz3pA2QK5E%2Bttk0Beo7Ck/TY3kf%2B8Ojph7cN1IboDi/5GgDVgQXD8FG3SRf%2Bsi23OyEIZz2fLy83z2jr3xaMfS',
								key: this.key,
								s: this.encrypt.encrypt(this.timeStampKey),
							},
						})
						.then((res) => {
							resolve(res.data);
						})
						.catch(function (error) {
							reject(error);
							this.$message({
								message: '服务器错误,请稍后重试!',
								type: 'error',
							});

							this.clearInfo();
						});
				});
			},

			VueDebounce(fnName, time) {
				let timeout = null;
				return function () {
					if (timeout) {
						clearTimeout(timeout);
					}
					timeout = setTimeout(() => {
						fnName();
					}, time);
				};
			},
			getScroll() {
				// this.indexNumTop = $("#index_num").offset().top;
				this.scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
				if (!!document.documentElement.scrollTop && document.documentElement.scrollTop >= 70) {
					//设置滚动大于300时的执行内容
					// console.log(
					//   "document.documentElement.scrollTop=",
					//   document.documentElement.scrollTop
					// );
					this.$store.commit('TO_AfterScroll');
				} else {
					this.$store.commit('TO_AfterScroll_false');
				}
			},
		},
	};
</script>

<style lang="less" scoped>
	@media screen and (max-width: 479px) {
		.m-soc-wrapper {
			// width: 100%;

			.m-soc-wrapper-content {
				padding: 12px 10px;

				// width: 100%;
				overflow: hidden;

				.m-soc-search {
					margin: 20px 0;
					justify-content: flex-end;
					align-items: center;
					display: flex;

					.el-row {
						width: 100%;
						margin-left: 10px;
						margin-right: 10px;

						/deep/ .el-input {
							.el-input__inner {
								width: 100%;
							}
						}
					}

					.el-button {
						position: absolute;
						margin-top: 5px;
						right: 0;
						border: none !important;
						background: transparent !important;
					}
				}

				.m-soc-job-box {
					.m-soc-job-box-item {
						// width: calc(100% - 20px);
						// margin: 10px;
						background-color: rgba(250, 250, 250, 1);
					}
					.empty {
					}
					/deep/ .el-collapse {
						border: none;

						.el-collapse-item {
							padding: 14px 10px;
							background-color: rgba(250, 250, 250, 1);
							margin-bottom: 20px;

							.el-collapse-item__header {
								background-color: transparent !important;
								height: 50px;
								border: none;
							}

							.el-collapse-item__wrap {
								background-color: transparent !important;
							}
						}
						.m_collaTop {
							.m_collaTopBefore {
								.m_title {
									height: 20px;
									margin-top: -12px;
									font-size: 14px;
									font-weight: bolder;
									text-align: left;
									color: #333333;
								}

								.m_city {
									height: 20px;
									margin-top: -15px;
									font-size: 12px;
									text-align: left;
									color: #666666;
								}
							}
						}

						.m_claim {
							margin-top: -3px;
							font-weight: bold;
							line-height: 14px;
							letter-spacing: 0px;
							text-align: left;

							color: #666666;
						}

						.m_claim-list {
							list-style-type: decimal;
							line-height: 28px;
							margin-left: 20px;

							li {
								text-align: left;
								font-size: 13px;
								color: #303133;
							}
						}

						#m_inputBut {
							input {
								background: transparent;
								border-color: #999999;
								height: 40px;
							}

							.child {
								margin-top: 10px;
								-webkit-box-flex: 1;
								margin-right: 10px;

								/deep/ .el-input__inner {
									width: 100%;
									height: 40px;
									background: transparent;
									border-color: #999999;
								}
							}
						}

						.m_uploadWrapper {
							margin-top: 20px;
							text-align: center;
							width: calc(100% - 20px);

							.m_uploadBox {
								flex-wrap: wrap;

								.m_uploadInput {
									width: 253px;
									height: 25px;
								}

								.m_commitBtn {
									width: 100%;
									height: 40px;
									min-height: 40px;
									padding: 9px 15px;
									font-size: 12px;
									border-radius: 3px;
									font-size: 16px;
									border-radius: 26px;
									background-color: #f49a42;
									border: none;
								}

								.m_upr {
									padding: 0px 0px 8px 0px;
									text-align: left;
									border-bottom: #979797 solid 1px;
									width: calc(100% - 20px);
									margin-left: 10px;
								}

								.resume {
									margin-top: 40px;
									margin-left: -55px;
								}
							}
						}
						.mailDiv {
							text-align: left;
							.email {
								color: #245da1;
							}
						}
					}
				}
			}
			/deep/ .el-dialog {
				width: 80%;
			}

			/deep/ .el-dialog__body {
				padding: 0;
			}

			/deep/ .el-dialog__header {
				display: none;
			}

			.dialog-title {
				background: url('https://img.i2soft.net/website-company-recruitment-bg-dialog.jpg') no-repeat;
				background-size: 100% 100%;
				margin-top: -30px;
				padding: 40px 20px;
				// padding: 20px 40px 40px 40px;
				color: #fff;

				.dialog-title-info {
					font-size: 30px;
					margin-bottom: 40px;
					text-align: left;

					span {
						color: #fff;
					}
				}

				.dialog-title-hint {
					width: 80;
					display: flex;
					flex-direction: column;
					align-items: flex-start;
					row-gap: 10px;
					font-size: 14px;

					div {
						display: flex;
						flex-direction: row;
						align-items: center;
					}
				}

				.dialog-title-img {
					cursor: pointer;
					float: right;
					width: 28px;
					height: 28px;
					margin-top: -30px;
				}

				.dialog-title-icon {
					width: 16px;
					height: 16px;
					margin-right: 8px;
				}

				.dialog-hidden {
					width: 130px;
					white-space: nowrap;
					overflow: hidden;
					text-overflow: ellipsis;
					margin-right: 40px;
				}
			}
			.dialog-content {
				padding: 20px;
			}
			.dialog-success-btn {
				width: 100%;
				display: flex;
				align-items: center;
				justify-content: center;
			}
		}
	}
</style>
