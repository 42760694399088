<template>
	<div class="m_wrapper">
		<div class="m_firstBannerWrapper">
			<img
				src="https://img.i2soft.net/i2official-web/v2/assets/images/introduce/phone/firstback.png"
				alt=""
			/>
		</div>
		<div class="m_secondWrapper">
			<div class="m_secontTitle">
				<WaveTitle
					title="我们可以为您提供的能力"
					subhead="Ability to provide"
					isMobile
				></WaveTitle>
			</div>
			<div class="m_secContent">
				<div
					class="m_contentItem"
					v-for="(item, index) in m_secondDataList"
					:key="index"
				>
					<img
						class="m_topImage"
						:src="item.imgUrl"
						alt=""
					/>
					<div class="m_nameDiv">{{ item.name }}</div>
					<BottomLine isMobile />
					<div class="m_introDiv">{{ item.intro }}</div>
				</div>
			</div>
		</div>
		<div class="m_thirdWrapper">
			<WaveTitle
				title="感谢这些客户使用我们的产品和服务"
				subhead="Our customers"
				isMobile
			></WaveTitle>
			<div class="m_thirdContent">
				<el-carousel
					trigger="click"
					indicator-position="outside"
					height="400px"
				>
					<el-carousel-item>
						<div class="m_thirdRow">
							<div
								class="m_thirdItem"
								v-for="(item, index) in m_thirdData.list1"
								:key="index"
							>
								<img
									:src="item.imgUrl"
									alt=""
								/>
							</div>
						</div>
					</el-carousel-item>
					<el-carousel-item>
						<div class="m_thirdRow">
							<div
								class="m_thirdItem"
								v-for="(item, index) in m_thirdData.list2"
								:key="index + 10"
							>
								<img
									:src="item.imgUrl"
									alt=""
								/>
							</div>
						</div>
					</el-carousel-item>
					<el-carousel-item>
						<div class="m_thirdRow">
							<div
								class="m_thirdItem"
								v-for="(item, index) in m_thirdData.list3"
								:key="index + 20"
							>
								<img
									:src="item.imgUrl"
									alt=""
								/>
							</div>
						</div>
					</el-carousel-item>
				</el-carousel>
			</div>
		</div>
		<div class="m_forthWrapper">
			<div class="m_forthTitle">
				<div class="m_forthTab"> 请允许介绍一下 </div>
			</div>
			<div class="m_forthContent">
				<div class="m_forthBox">
					<div>艾融软件</div>
					<div>
						上海艾融软件股份有限公司是一家面向关键行业机构提供深度数字化整体解决方案的科技公司,是国家认证的高技术企业、软件企业，已通过CMMIDEV/5认证，ISO9001、ISO27001、ISO20000等质量、安全和服务体系认证。公司业务涵盖金融IT各领域，从商业银行核心业务系统、外围IT系统建设，到业务咨询、平台建设、安全保障、运营支撑、营销获客，助力客户实现数字化转型。
					</div>
				</div>
			</div>
		</div>
		<div class="m_fifthWrapper">
			<div class="m_fifthTitle">
				<WaveTitle
					title="与我们共同前行的伙伴们"
					subhead="Our Partner"
					isMobile
				></WaveTitle>
			</div>
			<div class="m_fifthContent">
				<img
					src="https://img.i2soft.net/i2official-web/v2/assets/images/introduce/phone/fifthPartner.png"
					alt=""
				/>
			</div>
		</div>
	</div>
</template>

<script>
	import BottomLine from '@/components/BottomLine.vue';
	import WaveTitle from '@/components/WaveTitle.vue';
	import { isMobile } from '@/utils/index';

	export default {
		components: { WaveTitle, BottomLine },
		data() {
			return {
				isMobile: false,
				m_secondDataList: [
					{
						id: '1',
						name: '经验丰富',
						intro: '拥有十余年大型金融科技软件工程项目经验，服务大型金融机构客户数十家。拥有成熟、系统的方法论和产品线，可以为您考虑更多。',
						imgUrl: 'https://img.i2soft.net/i2official-web/v2/assets/images/introduce/phone/second01.svg',
					},
					{
						id: '2',
						name: '多地团队',
						intro: '在北京、上海、广州等地均拥有当地完整服务团队。客户在哪，我们就在哪。贴身全流程服务，保证项目顺畅高效。',
						imgUrl: 'https://img.i2soft.net/i2official-web/v2/assets/images/introduce/phone/second02.svg',
					},
					{
						id: '3',
						name: '整体解决方案',
						intro: '多元化产品涵盖金融IT各领域、汽车软件领域，基于互联网、大数据、机器学习、区块链、人工智能等先进技术应用，从无到有，从交付到运营，提供一揽子解决方案。',
						imgUrl: 'https://img.i2soft.net/i2official-web/v2/assets/images/introduce/phone/second03.svg',
					},
					{
						id: '4',
						name: '个性定制',
						intro: '拥有体系完善的在线存款,在线支付,在线贷款,在线运营,司法级在线身份认证等系列产品及企业级定制开发服务能力。',
						imgUrl: 'https://img.i2soft.net/i2official-web/v2/assets/images/introduce/phone/second04.svg',
					},
					{
						id: '5',
						name: '高效专业',
						intro: '成熟的项目经验和皮实的团队作风,基于您的需要给出最优解。',
						imgUrl: 'https://img.i2soft.net/i2official-web/v2/assets/images/introduce/phone/second05.svg',
					},
					{
						id: '6',
						name: '数字化系统集成',
						intro: '北交所上市企业,上千名员工服务于各大金融机构。可靠的技术及管理体系,确保万无一失。',
						imgUrl: 'https://img.i2soft.net/i2official-web/v2/assets/images/introduce/phone//second06.svg',
					},
				],
				m_thirdData: {
					list1: [
						{
							id: '1',
							imgUrl: 'https://img.i2soft.net/i2official-web/v2/assets/images/introduce/clients/client01.svg',
						},
						{
							id: '2',
							imgUrl: 'https://img.i2soft.net/i2official-web/v2/assets/images/introduce/clients/client02.svg',
						},
						{
							id: '3',
							imgUrl: 'https://img.i2soft.net/i2official-web/v2/assets/images/introduce/clients/client03.svg',
						},
						{
							id: '4',
							imgUrl: 'https://img.i2soft.net/i2official-web/v2/assets/images/introduce/clients/client04.svg',
						},
						{
							id: '5',
							imgUrl: 'https://img.i2soft.net/i2official-web/v2/assets/images/introduce/clients/client05.svg',
						},
						{
							id: '6',
							imgUrl: 'https://img.i2soft.net/i2official-web/v2/assets/images/introduce/clients/client06.svg',
						},
						{
							id: '7',
							imgUrl: 'https://img.i2soft.net/i2official-web/v2/assets/images/introduce/clients/client07.svg',
						},
						{
							id: '8',
							imgUrl: 'https://img.i2soft.net/i2official-web/v2/assets/images/introduce/clients/client08.svg',
						},
					],
					list2: [
						{
							id: '9',
							imgUrl: 'https://img.i2soft.net/i2official-web/v2/assets/images/introduce/clients/client09.svg',
						},
						{
							id: '10',
							imgUrl: 'https://img.i2soft.net/i2official-web/v2/assets/images/introduce/clients/client10.svg',
						},
						{
							id: '11',
							imgUrl: 'https://img.i2soft.net/i2official-web/v2/assets/images/introduce/clients/client11.svg',
						},
						{
							id: '12',
							imgUrl: 'https://img.i2soft.net/i2official-web/v2/assets/images/introduce/clients/client12.svg',
						},
						{
							id: '1',
							imgUrl: 'https://img.i2soft.net/i2official-web/v2/assets/images/introduce/youliBank.png',
						},
						{
							id: '2',
							imgUrl: 'https://img.i2soft.net/i2official-web/v2/assets/images/introduce/msBank.png',
						},
						{
							id: '3',
							imgUrl: 'https://img.i2soft.net/i2official-web/v2/assets/images/introduce/xmBank.png',
						},
						{
							id: '4',
							imgUrl: 'https://img.i2soft.net/i2official-web/v2/assets/images/introduce/nbBank.png',
						},
					],
					list3: [
						{
							id: '5',
							imgUrl: 'https://img.i2soft.net/i2official-web/v2/assets/images/introduce/clients/client05.svg',
						},
						{
							id: '6',
							imgUrl: 'https://img.i2soft.net/i2official-web/v2/assets/images/introduce/clients/client06.svg',
						},
						{
							id: '7',
							imgUrl: 'https://img.i2soft.net/i2official-web/v2/assets/images/introduce/clients/client07.svg',
						},
						{
							id: '8',
							imgUrl: 'https://img.i2soft.net/i2official-web/v2/assets/images/introduce/clients/client08.svg',
						},
						{
							id: '9',
							imgUrl: 'https://img.i2soft.net/i2official-web/v2/assets/images/introduce/clients/client09.svg',
						},
						{
							id: '10',
							imgUrl: 'https://img.i2soft.net/i2official-web/v2/assets/images/introduce/clients/client10.svg',
						},
						{
							id: '11',
							imgUrl: 'https://img.i2soft.net/i2official-web/v2/assets/images/introduce/clients/client11.svg',
						},
						{
							id: '12',
							imgUrl: 'https://img.i2soft.net/i2official-web/v2/assets/images/introduce/clients/client12.svg',
						},
					],
				},
			};
		},
		created() {},
		mounted() {
			this.isMobile = isMobile();
			window.addEventListener('scroll', this.getScroll);
		},
		destroyed() {
			window.removeEventListener('scroll', this.getScroll);
		},
		methods: {
			getScroll() {
				// this.indexNumTop = $("#index_num").offset().top;
				this.scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
				if (!!document.documentElement.scrollTop && document.documentElement.scrollTop >= 70) {
					//设置滚动大于300时的执行内容
					// console.log(
					//   "document.documentElement.scrollTop=",
					//   document.documentElement.scrollTop
					// );
					this.$store.commit('TO_AfterScroll');
				} else {
					this.$store.commit('TO_AfterScroll_false');
				}
			},
		},
	};
</script>

<style lang="less" scoped>
	@media screen and (max-width: 479px) {
		.m_wrapper {
			.m_firstBannerWrapper {
				width: 100%;
				overflow: hidden;
				position: relative;

				img {
					margin: 0 auto;
					display: block;
					width: 100%;
					height: auto;
				}

				.textBox {
					position: absolute;
					top: 35%;
					left: 10px;
					width: 160px;
					height: 70px;
					text-align: left;

					.firstRow1 {
						color: #fff;
						font-size: 16px;
					}

					.firstRow2 {
						color: #fff;
						font-size: 16px;
						font-weight: bolder;
					}

					.firstRow3 {
						color: #fff;
						font-size: 12px;
					}
				}
			}

			.m_secondWrapper {
				padding: 47px 17px;
				background: #fff;

				.m_secontTitle {
					text-align: center;
					font-size: 16px;
					margin-bottom: 30px;
					color: #333;
				}

				.m_secContent {
					width: 100%;
					display: grid;
					grid-template-columns: repeat(2, 1fr);
					align-items: center;
					justify-items: center;
					row-gap: 40px;
					column-gap: 16px;

					.m_contentItem {
						word-wrap: break-word;
						word-break: normal;
						overflow: hidden;

						.m_topImage {
							width: 48px;
							height: 48px;
						}

						.m_nameDiv {
							margin-top: 15px;
							font-size: 14px;
							font-weight: bold;
							color: #001f34;
						}

						.m_introDiv {
							// margin-top: 10px;
							font-size: 11px;
							font-weight: 300;
							color: #001f34;
						}
					}
				}
			}

			.m_thirdWrapper {
				background: #f1f1f1;
				padding: 30px 13px;

				.m_thirdTitle {
					text-align: center;
					font-size: 16px;
					margin-top: 36px;
					margin-bottom: 20px;
					color: #333;
				}

				.m_thirdContent {
					width: 100%;
					margin: 24px auto;
					.m_thirdRow {
						width: 100%;
						display: grid;
						grid-template-columns: repeat(2, 1fr);
						align-items: center;
						justify-items: center;
						.m_thirdItem {
							width: 100%;

							img {
								width: 100%;
								height: 100%;
							}
						}
					}
				}
			}

			.m_forthWrapper {
				background-image: url('https://img.i2soft.net/i2official-web/v2/assets/images/introduce/phone/forthback.png');

				img {
					width: 100%;
					height: auto;
				}

				padding: 55px 24px;
				color: #fff;

				.m_forthTitle {
					display: flex;
					flex-direction: column;
					align-items: center;
					margin-bottom: 33px;

					.m_forthTab {
						width: 110px;
						padding: 4px;
						font-size: 14px;
						font-weight: normal;
						background: linear-gradient(104deg, #e6d2b2 0%, #b08d57 101%);
					}
				}

				.m_forthContent {
					margin: 24px auto;

					.m_forthBox {
						padding: 12px 20px;
						padding-bottom: 32px;
						background: rgba(255, 255, 255, 0.3);
						box-sizing: border-box;
						border: 1px solid #ffffff;
						backdrop-filter: blur(91px);

						div {
							font-size: 12px;
							color: #fff;
							font-weight: 300;
						}

						div:first-child {
							font-size: 16px;
							font-weight: bold;
							margin-bottom: 24px;
						}
					}
				}
			}

			.m_fifthWrapper {
				padding: 43px 0;
				background-image: url('https://img.i2soft.net/i2official-web/v2/assets/images/introduce/phone/fifthback.png');
				background-position: center;
				background-size: cover;

				.m_fifthTitle {
					padding: 0 12px;
					margin-bottom: 40px;
				}

				.m_fifthContent {
					img {
						width: 100%;
						min-height: 205.32px;
					}
				}
			}
		}
		/deep/.el-carousel__indicator.is-active button {
			width: 48px !important;
			height: 4px !important;
			border-radius: 2px !important;
			background: #e0ab65;
		}

		/deep/.el-carousel__button {
			background: #e0ab65;
			width: 4px !important;
			height: 4px !important;
			border-radius: 10px !important;
		}
	}
</style>
