<template>
	<div class="m_wrapper">
		<div class="m_firstBannerWrapper">
			<img
				src="https://img.i2soft.net/i2official-web/v2/assets/images/solve/phone/firstback.png"
				alt=""
			/>
		</div>
		<div class="m_secondWrapper">
			<div class="m_secondTitle">
				<WaveTitle
					title="SQUARE互联网金融2.0整体解决方案"
					subhead="智能场景构件/为服务架构"
					isMobile
				></WaveTitle>
			</div>
			<div class="m_secContent">
				<div class="m_topBox">
					<div
						class="m_topBox_item"
						v-for="(item, index) in m_secondData"
						:key="index"
					>
						<div class="title">
							<div class="title-text">
								<span>0{{ index + 1 }}</span>
								<div class="right">
									<div>{{ item.title }}</div>
								</div>
							</div>
							<div class="line"></div>
						</div>
						<div class="desc">{{ item.desc }} </div>
					</div>
				</div>
			</div>
		</div>
		<div class="m_thirdWrapper">
			<div class="m_thirdContent">
				<div class="videoBox">
					<img
						src="https://img.i2soft.net/i2official-web/v2/assets/images/solve/phone/video.png"
						alt=""
					/>
					<div
						class="playBtn"
						@click="videoBtnDidClick"
					>
					</div>
				</div>
			</div>
		</div>
		<div class="m_forthWrapper">
			<div class="m_forthTitle">
				<WaveTitle
					title="SQUARE互联网金融2.0解决方案"
					subhead="智能场景构件/为服务架构"
					isMobile
				></WaveTitle>
			</div>

			<div class="m_forthContent">
				<div
					class="m_forthRowItem"
					v-for="(item, index) in m_forthData"
					:key="index + 200"
				>
					<div class="m_imageBox">
						<img
							:src="item.imgUrl"
							alt=""
						/>
					</div>
					<div class="m_itemTitle">{{ item.title }}</div>
					<BottomLine isMobile />
					<div class="m_itemBottomName">{{ item.name }}</div>
				</div>
			</div>
		</div>
		<div class="m_fifthWrapper">
			<div class="m_fifthContent">
				<el-carousel
					trigger="click"
					indicator-position="outside"
					height="226.96px;"
				>
					<el-carousel-item
						v-for="(item, index) in m_fifthData"
						:key="index"
					>
						<div class="m_fifthItem">
							<div
								class="m_fifthItem_title"
								:class="`m_fifthItem_title_${index + 1}`"
							>
								<div>
									<span class="m_fifthItem_title_num">0{{ index + 1 }}</span>
									<span>{{ item.title }}</span>
								</div>
							</div>
							<img
								:src="item.imgUrl"
								alt=""
							/>
						</div>
					</el-carousel-item>
				</el-carousel>
			</div>
		</div>
		<video-player :videoSrc="videoUrl" />
	</div>
</template>

<script>
	import videoPlayer from '@/views/video/index.vue';
	import { isMobile } from '@/utils/index';
	import WaveTitle from '@/components/WaveTitle.vue';
	import BottomLine from '@/components/BottomLine.vue';

	export default {
		components: { videoPlayer, WaveTitle, BottomLine },
		data() {
			return {
				isMobile: false,
				videoState: this.$store.state.account.videoState,
				videoUrl: 'https://img.i2soft.net/%E8%89%BE%E8%9E%8D%E8%BD%AF%E4%BB%B6SQUARE%E5%AE%9E%E6%96%BD%E6%96%B9%E6%B3%95%E8%AE%BA.mp4',
				m_secondData: [
					{
						title: '产品及解决方案',
						desc: '精准客户营销、互联网支付、 在线贷款、国际业务、结汇售汇、 房产销售、商旅服务、公共政务。',
					},
					{
						title: '数字化安全保障',
						desc: '大客户画像、全息客户视图、 实时营销评估、权威身份认证、 动态威胁感知。',
					},
					{
						title: '数字化系统集成',
						desc: '设备租赁、基础设施、营销、供应链、国际贸易、直销银行、 生活缴费、大宗交易。',
					},
					{
						title: '数字化运营',
						desc: '行长驾驶舱、集团采购、金融电商、动态运营决策、汽车销售、 社交网络、跨境电商、网上消费、 交易银行。',
					},
				],
				m_forthData: [
					{
						id: '1',
						title: '渠道类',
						name: '个人手机银行、企业手机银行、个人网银、企业网银、小程序、直销银行',
						imgUrl: 'https://img.i2soft.net/i2official-web/v2/assets/images/solve/phone/forth01.svg',
					},
					{
						id: '2',
						title: '平台类',
						name: '业务能力中台、融资中台、营销中台',
						imgUrl: 'https://img.i2soft.net/i2official-web/v2/assets/images/solve/phone/forth02.svg',
					},
					{
						id: '3',
						title: '开发平台类',
						name: '分布式服务开发平台（FAST）；跨平台前端开发平台-Lark',
						imgUrl: 'https://img.i2soft.net/i2official-web/v2/assets/images/solve/phone/forth03.svg',
					},
					{
						id: '4',
						title: '工具类',
						name: '互联网+可信身份认证平台、互联网+全渠道运营服务平台',
						imgUrl: 'https://img.i2soft.net/i2official-web/v2/assets/images/solve/phone/forth04.svg',
					},
					{
						id: '5',
						title: '运营类',
						name: ' 银行商城联合运营服务、小程序/公众号/服务号/营销活动/直播/获客展业运营服务',
						imgUrl: 'https://img.i2soft.net/i2official-web/v2/assets/images/solve/phone/forth05.svg',
					},
				],
				m_fifthData: [
					{
						id: '01',
						title: '现金管理案例：上海银行现金管理平台',
						imgUrl: 'https://img.i2soft.net/i2official-web/v2/assets/images/solve/phone/fifth01.png',
					},
					{
						id: '02',
						title: '直销银行案例\n上海银行  上行快线',
						imgUrl: 'https://img.i2soft.net/i2official-web/v2/assets/images/solve/phone/fifth02.png',
					},
					{
						id: '03',
						title: '金融电商案例：工行“融e购”金融电商平台',
						imgUrl: 'https://img.i2soft.net/i2official-web/v2/assets/images/solve/phone/fifth03.png',
					},
					{
						id: '04',
						title: '现金管理案例\n光大银行现金管理云平台',
						imgUrl: 'https://img.i2soft.net/i2official-web/v2/assets/images/solve/phone/fifth04.png',
					},
				],
			};
		},
		mounted() {
			this.isMobile = isMobile();
			window.addEventListener('scroll', this.getScroll);
		},
		destroyed() {
			window.removeEventListener('scroll', this.getScroll);
		},
		methods: {
			videoBtnDidClick() {
				this.$store.commit('TO_VIDEOS');
			},
			getScroll() {
				this.scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
				if (!!document.documentElement.scrollTop && document.documentElement.scrollTop >= 70) {
					this.$store.commit('TO_AfterScroll');
				} else {
					this.$store.commit('TO_AfterScroll_false');
				}
			},
		},
	};
</script>

<style lang="less" scoped>
	@media screen and (max-width: 479px) {
		background: #fff;

		.m_firstBannerWrapper {
			position: relative;
			overflow: hidden;

			img {
				margin: 0 auto;
				display: block;
				width: 100%;
				height: auto;
			}

			.m_textBox {
				position: absolute;
				top: 35%;
				left: 15px;
				width: 180px;
				height: 70px;
				text-align: left;

				.firstRow1 {
					color: #034579;
					font-size: 16px;
				}

				.firstRow2 {
					color: #0497d1;
					font-size: 16px;
					font-weight: bolder;
				}

				.firstRow3 {
					color: #0497d1;
					font-size: 12px;
				}
			}
		}

		.m_secondWrapper {
			padding: 24px 12px;
			overflow: hidden;

			.m_secondTitle {
				margin: 20px 0;
				margin-bottom: 32px;
			}

			.m_secContent {
				background-image: url('https://img.i2soft.net/i2official-web/v2/assets/images/solve/phone/secondback.svg');
				background-position: center;
				background-repeat: no-repeat;
				width: 100%;

				.m_topBox {
					z-index: 99;
					display: grid;
					grid-template-columns: repeat(2, 1fr);
					grid-gap: 12px;
					row-gap: 12px;
					opacity: 0.7;

					.m_topBox_item {
						padding: 20px 12px;
						background: #ffffff;
						box-shadow: 0px 8px 32px 0px rgba(0, 0, 0, 0.16);

						.title {
							margin-bottom: 24px;

							.title-text {
								display: flex;
								flex-direction: row;
								font-size: 14px;
								font-weight: bold;
								color: #b08d57;
								.right {
									margin-left: 8px;
									text-align: left;
									color: #001f34;
								}
							}

							.line {
								width: 80%;
								margin-top: 4px;
								height: 1px;
								background: linear-gradient(to left, #fff, #b08d57);
							}
						}

						.desc {
							font-size: 12px;
							text-align: left;
							line-height: normal;
						}
					}
				}
			}
		}

		.m_thirdWrapper {
			background: url('https://img.i2soft.net/i2official-web/v2/assets/images/solve/phone/thirdback.png');
			padding: 40px 0;
			overflow: hidden;

			.m_thirdContent {
				.videoBox {
					width: 80%;
					position: relative;
					margin: 0 auto;
					cursor: pointer;

					img {
						width: 100%;
						height: auto;
					}

					.playBtn {
						width: 33.59px;
						height: 33.59px;
						position: absolute;
						top: 50%;
						left: 50%;
						transform: translate(-50%, -50%);

						img {
							width: 100%;
							height: 100%;
						}
					}
				}
			}
		}

		.m_forthWrapper {
			padding: 40px 17px;
			overflow: hidden;

			.m_forthTitle {
				margin-bottom: 32px;
			}

			.m_forthContent {
				display: grid;
				grid-template-columns: repeat(2, 1fr);
				row-gap: 20px;
				column-gap: 30px;

				.m_forthRowItem {
					color: #001f34;
					text-align: center;
					line-height: normal;

					.m_imageBox {
						display: flex;
						justify-content: center;

						img {
							width: 48px;
							min-width: 48px;
							height: 48px;
						}
					}

					.m_itemTitle {
						font-size: 14px;
						font-weight: bold;
					}

					.m_itemBottomName {
						font-size: 11px;
						font-weight: 300;
					}
				}
			}
		}

		.m_fifthWrapper {
			width: 100%;
			height: 228px;
			overflow: hidden;

			.m_fifthContent {
				.m_fifthItem {
					position: relative;
					img {
						width: 100%;
						overflow: hidden;
					}

					.m_fifthItem_title {
						position: absolute;
						width: 100%;

						span {
							font-size: 14px;
							line-height: normal;
							color: #001f34;
							white-space: pre-wrap;
							text-align: center;
						}

						.m_fifthItem_title_num {
							color: #b08d57;
							font-weight: bold;
							margin: 0 4px;
						}
					}

					.m_fifthItem_title_1 {
						display: flex;
						justify-content: center;

						div {
							padding: 20px 0;
						}
					}

					.m_fifthItem_title_2 {
						width: 100%;
						bottom: 0;

						div {
							padding: 40px 24px;
							display: flex;
							flex-direction: column;
							align-items: flex-start;
						}

						span {
							text-align: left;
						}
					}

					.m_fifthItem_title_3 {
						display: flex;
						justify-content: center;

						div {
							padding: 40px 0;
						}
					}

					.m_fifthItem_title_4 {
						div {
							padding: 16px;
							display: flex;
							flex-direction: column;
							align-items: flex-end;
						}

						span {
							color: #fff;
							text-align: right;
						}
					}
				}
			}
		}
	}
</style>
