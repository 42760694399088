<!--
 * @Author: lyt
 * @Date: 2025-01-17 14:02:20
 * @LastEditTime: 2025-01-20 10:56:44
 * @LastEditors: lyt
 * @Description:  移动端-加入我们-校园招聘
 * @FilePath: /new-i2finance/official_web_portal/official/src/components/phone/CampusRecruitmentComp.vue
 *  
-->
<template>
	<div class="m-camp-wrapper">
		<div class="m-camp-first-wrapper">
			<div class="m-camp-first-wrapper-title">
				<WaveTitle
					title="艾融软件"
					subhead="i2finance"
					isMobile
				/>
			</div>
			<div class="m-camp-first-wrapper-content">
				<div class="company-container">
					<p class="company-content">
						上海艾融软件股份有限公司（证券代码：830799）是一家向以大型商业银行为主的金融行业提供人工智能、虚拟现实技术的全信创解决方案的高科技公司。公司成立于2009年3月，2021年11月首批登陆北京证券交易所。公司总部位于上海，并在北京、广州、成都、西安、杭州、珠海、合肥、武汉、长沙、福州、深圳、苏州十一个城市设有分支机构。公司旗下还拥有上海艾融数据科技有限公司、上海宜签网络科技有限公司、上海艾融电子信息有限公司及上海砾阳软件有限公司四家子公司。
					</p>
					<el-collapse-transition>
						<div v-show="isShowMoreCompany">
							<p class="company-content">
								艾融软件始终致力于向以大型商业银行为代表的金融机构关键客户提供全面数字化解决方案及其落地实施交付服务，公司业务涵盖商业银行核心业务系统、外围IT系统建设、大规模软件交付服务等，还包括了业务咨询服务、业务运营服务、联合创新服务等。公司具有包括业务咨询、平台建设、安全保障、运营支撑、营销获客、数据分析运营为一体的金融数字化整体解决方案——SQUARE（Smart,
								Quick, Unique, Abundant, Reliable and
								Efficient）平台和一系列标准化、专业化、多元化的企业级应用软件，包括电子银行类、线上贷款类、业务中台类、应用安全类、大数据分析类以及商机发现、营销、运营类产品，艾融软件还具有基于虚拟现实、Web3.0等技术的数字行员、虚拟营业厅等“元宇宙”以及大模型场景化应用金融解决方案及案例，助力客户实现金融数字化转型。
								艾融软件服务的银行客户覆盖了中国20家系统重要性银行中的15家，包括工商银行、农业银行、建设银行、交通银行等大型商业银行；中信银行、民生银行、浦发银行、光大银行、兴业银行、华夏银行、恒丰银行、浙商银行等全国性股份制商业银行；上海银行、北京银行、南京银行、宁波银行、富滇银行、厦门银行、柳州银行、宜宾市商业银行等城市商业银行；上海农商行、北京农商行、广州农商行、重庆农商行等农村商业银行；友利银行、南洋商业银行等外资银行。同时艾融软件还服务了中国信托登记有限责任公司、上海证券交易所、证通公司、信达证券、中国银联、中国供销、太平洋保险、建信人寿、中泰证券、支付宝、拉卡拉等众多优质客户。
							</p>
							<p class="company-content">
								艾融软件经过多年的研发与积累，公司掌握了一批核心技术并取得了多项科技成果，在搜索引擎技术、工作流引擎技术、分布式应用架构技术、即时通信技术、移动互联网技术、数字孪生、虚拟现实、人工智能和大模型等多个方面形成了完整的核心技术体系，形成完备的互联网金融应用软件开发平台和软件产品系列；已通过CMMIDEV/5认证，测试成熟度TMMI3认证，质量管理体系ISO9001认证和信息安全管理体系ISO27001认证、信息技术服务管理体系ISO2000认证，环境管理体系14001认证、业务连续性管理体系22301认证，能源管理体系50001和职业健康安全管理体系45001认证；截止2024年5月末，公司拥有的软件技术发明专利已有24项，国家版权局认证的计算机软件著作权已达401项。
								艾融软件公司系国家高新技术企业、软件企业，始终重视研发和科技创新活动，研发费用投入连续多年保持在营收的10％以上，在加大自研力度的同时，还参加了国家重点项目“金融数据合成与智能模型风险评估检测平台及示范应用”项目，为金融数据的资产化和行业大模型训练创造条件。与此同时，艾融软件积极建设自身全条链信创能力，积累了丰富的经验和实践案例。2024年公司与星闪联盟达成合作意向，已经有913人次获得了鸿蒙应用开发证书，分别获得华为开发者联盟的生态市场服务商和HarmonyOS开发服务商的资质。
								艾融软件拥有一支行业经验丰富、技术能力突出的专业产品研发和技术服务团队，其中技术工程师占比超过90%。艾融软件是首批特色化示范性软件学院合作企业，不但在内部建立了具有特色的员工培训体系，还通过与大学联合设立了研究生课程、管理培训生计划、标准化培训体系等，使得艾融软件向金融机构提供服务的员工具有高度专业的技术能力和服务意识，保证了公司的服务的专业、高效和品质。公司发挥自身特色优势，与北理工计算机学院在虚拟现实和数字人领域的研究生课题研究方向开展了广泛的合作，实现研发资源、研发人才、技术能力的共享，实践产研结合。
								艾融软件长期致力于大规模软件交付能力建设，建设了企业级的软件研发数字化管理底座-笨鸟系统，并基于此搭建了大规模交付态势感知系统，与公司的SQUARE实施方法、员工培训体系等有机融合，大大提升了公司软件工业化程度，为向客户提供大规模软件交付服务提供了进一步的保障。
							</p>
							<p class="company-content">
								公司以“平台级金融数字化系列软件+专业设计+100%贴身定制服务”为核心竞争力，10余年来专注研究金融业务数字化，致力于助力传统金融机构数字化转型。未来艾融软件将继续专注于打造大规模、高品质的行业应用软件交付能力，开发出更多优秀的行业应用软件，助力关键行业服务的数字化变革，与众多客户共同为“数字中国”做出我们应有的贡献。
							</p>
						</div>
					</el-collapse-transition>
					<p
						class="more"
						@click="isShowMoreCompany = !isShowMoreCompany"
					>
						{{ isShowMoreCompany ? '收起' : '展开' }}
					</p>
					<img
						class="company-img"
						src="https://img.i2soft.net/i2official-web/v2/assets/images/joinus/phone/camp_second.png"
						alt=""
					/>
				</div>
			</div>
		</div>
		<div class="m-camp-second-wrapper">
			<div class="m-camp-second-wrapper-title">
				<WaveTitle
					title="招聘岗位"
					subhead="Recruitment position"
					textColor="#fff"
					isMobile
				/>
			</div>
			<div class="m-camp-second-wrapper-content">
				<el-carousel
					trigger="click"
					indicator-position="outside"
					width="100%"
					height="152px"
				>
					<el-carousel-item
						v-for="(group, groupIndex) of groupedSchoolJD"
						:key="groupIndex"
					>
						<div
							class="m_more_job-item"
							@click="m_onOpenDialog(item)"
							v-for="(item, index) of group"
							:key="index"
						>
							<div
								class="m_more_job-item-title"
								v-html="item.title"
							></div>
							<div class="m_more_job-item-content">
								<div>
									<img src="https://img.i2soft.net/i2official-web/v2/assets/images/joinus/phone/camp_icon_jobCategory.svg" />
									<span>{{ item.jobCategory }}</span></div
								>
								<div>
									<img src="https://img.i2soft.net/i2official-web/v2/assets/images/joinus/phone/camp_icon_location.svg" />
									<span>全国</span>
								</div>
							</div>
						</div>
					</el-carousel-item>
				</el-carousel>
			</div>
		</div>
		<div class="m-camp-third-wrapper">
			<div class="m-camp-third-wrapper-title">
				<WaveTitle
					title="应聘流程"
					subhead="Application process"
					isMobile
				/>
			</div>
			<div class="m-camp-third-wrapper-content">
				<img src="https://img.i2soft.net/i2official-web/v2/assets/images/joinus/phone/camp_fourth.png" />
			</div>
		</div>
		<div class="m-camp-fourth-wrapper">
			<div class="m-camp-fourth-wrapper-title">
				<WaveTitle
					title="管培生帮助中心"
					subhead="Management trainee"
					textColor="#fff"
					isMobile
				/>
			</div>
			<div class="m-camp-fourth-wrapper-content">
				<div>
					<h4>管培生的管培方式是什么样的？</h4>
					<p>1 月所有通过录用的技术岗管培生统一到上海总部参加入职培训</p>
					<ul>
						<li
							v-for="(item, index) in m_helpData"
							:key="index"
						>
							{{ item.leftText }}{{ item.desc }}
						</li>
					</ul>
					<div class="m_help-connection">
						<p>校招联系人：徐小姐</p>
						<p> 单位电话：021-61625530<br />联系邮箱：future@i2finance.net </p>
						<p>微信公众号：<br />【艾融Future+】（在线投递简历）、【艾融软件】</p>
					</div>
				</div>
			</div>
		</div>
		<!-- 点击招聘弹框 -->
		<div
			v-if="isShowfixedJob"
			class="m_fixedJobWrapper"
		>
			<div class="m_fix_container">
				<div class="m_fixedJob_top">
					<div
						class="m_left_img_div"
						@click="dismissFixBox()"
					>
						<img
							src="https://img.i2soft.net/i2official-web/assets/images/nav/phone/nav_left_arrow.svg"
							alt=""
						/>
					</div>
				</div>
				<div class="m_fixed_title_box">
					<div
						class="m_title_title"
						v-html="this.currentJob.title"
					></div>
					<div class="m_title_location">
						<img
							class="m_dialog-title-icon"
							src="
                  https://img.i2soft.net/i2official-web/assets/images/joinus/campus/location_black.svg
                "
							alt=""
						/>
						<p class="m_dialog-hidden">
							{{ currentJob.jobCity }}
						</p>
						<img
							class="m_dialog-title-icon"
							src="
                  https://img.i2soft.net/i2official-web/assets/images/joinus/campus/icon_time_black.svg
                "
							alt=""
						/>
						<p class="m_dialog-hidden">
							{{ currentJob.time }}
						</p>
					</div>
					<div class="m_line"></div>
				</div>
				<div class="m_dialog-content">
					<p class="m_dialog-content-title">岗位要求</p>
					<ul
						class="m_dialog-content-ul"
						v-html="currentJob.description"
					></ul>
				</div>
				<div class="m_line"></div>
				<div class="m_dialog-content">
					<p class="m_dialog-content-title">岗位职责</p>
					<ul
						class="m_dialog-content-ul"
						v-html="currentJob.jobClaim"
					></ul>
				</div>
				<template>
					<div
						style="text-align: center"
						id="m_inputBut"
					>
						<div class="child">
							<el-input
								v-model="name"
								placeholder="姓名*"
							></el-input>
						</div>
						<div class="child">
							<el-input
								v-model="phone"
								placeholder="电话*"
							></el-input>
						</div>
						<div class="child">
							<el-input
								v-model="mail"
								placeholder="邮箱*"
							></el-input>
						</div>
					</div>
					<div
						class="m_uploadWrapper"
						style="text-align: left"
					>
						<el-row class="m_uploadBox">
							<p
								class="m_upr"
								style=""
								@click="selectFiles"
							>
								{{ schoolFileName }}
							</p>
							<input
								type="file"
								id="openFile"
								title="none"
								style="opacity: 0; position: absolute; z-index: -999"
								@change="getFile"
							/>
							<el-button
								class="m_commitBtn"
								type="primary"
								@click="onCloseDialog(currentJob.title)"
								>提交</el-button
							>
						</el-row>
						<p class="m_upload-hint">
							简历可直接发送以下邮箱：<a
								class="email"
								href="https://exmail.qq.com/login"
								>zhaopzx@i2finance.net</a
							>
						</p>
					</div>
				</template>
			</div>
		</div>
		<!-- 提交结果弹窗 -->
		<el-dialog
			:visible.sync="isShowSubmitInfo"
			class="dialog-submit-res"
		>
			<div class="dialog-title">
				<img
					class="dialog-title-img"
					src="https://img.i2soft.net/i2official-web/v2/assets/images/joinus/close.png"
					alt=""
					@click="closeSubmitDialog()"
				/>
				<p class="dialog-title-info">{{ name }}</p>
				<div class="dialog-title-hint">
					<div>
						<img
							class="dialog-title-icon"
							src="https://img.i2soft.net/i2official-web/assets/images/joinus/icon_phone.svg
              "
							alt=""
						/>
						<span>{{ phone }}</span>
					</div>
					<div>
						<img
							class="dialog-title-icon"
							src="https://img.i2soft.net/i2official-web/assets/images/joinus/icon_email.svg
              "
							alt=""
						/>
						<span>{{ mail }}</span>
					</div>
				</div>
			</div>
			<div class="dialog-content">
				<div class="dialog-content-submit_success">
					<img
						src="https://img.i2soft.net/i2official-web/assets/images/joinus/submit_success.svg"
						alt=""
					/>
					<div>
						<div>感谢您的投递</div>
						<div>我们将尽快完成筛选，通过后会尽快联系您！</div>
					</div>
				</div>
			</div>
			<template #footer>
				<div class="dialog-success-btn">
					<el-button
						class="commitBtn"
						type="primary"
						@click="closeSubmitDialog"
						>关闭</el-button
					>
				</div>
			</template>
		</el-dialog>
	</div>
</template>

<script>
	import WaveTitle from '@/components/WaveTitle.vue';

	export default {
		name: 'CampusRecruitmentComp',
		components: { WaveTitle },
		props: {},
		data() {
			return {
				isShowMoreCompany: false,
				m_schoolJD: [
					{
						id: 1,
						time: '2021-6-1',
						title: '<p>创新实验室技术研发 </p>',
						description: ` <li>新技术的研发，主要围绕金融科技行业的虚拟银行建设，人工智能（机器学习、深度学习）、3D 虚幻引擎、大数据风控分析、平行仿真/数字孪生等</li>
                <li>统一技术开发平台/标准的研发 </li>
                <li>公司金融电商、现金管理、直销银行等产品的研发</li>`,
						jobClaim: ` <li>研究生学历优先，计算机/软件工程/通信/网络/信息管理等相关专业 </li>
                <li>对技术有热情，有不懈的追求和内驱力 </li>
                <li>有很强的学习、动手能力，可以快速地掌握新的知识、工具 </li>
                <li>熟悉业界主流的开源技术、框架、工具，知识面宽广</li>`,
						//   jobCategory: 1,
						jobCategory: '技术类',
						//   jobCity: [1, 2, 3, 4, 5, 6, 7, 8, 9],
						jobCity: '北京,上海，广州，珠海，杭州，西安，成都，武汉，合肥',
					},

					{
						id: 2,
						time: '2021-6-1',
						title: '<p>前端开发管培生 </p>',
						description: ` <li>深入理解业务需求，依据设计稿完成前端代码的编写</li>
                <li>负责公司项目、业务平台及智能终端的前端界面开发 </li>
                <li>优化与改进产品的技术实现、性能与用户体验</li>`,
						jobClaim: ` <li>本科学历，计算机/软件工程/通信/网络/信息管理等相关专业 </li>
                <li>熟悉 JavaScript 等 Web 开发技术，有良好的程序设计和架构能力，至少熟悉 Angular、Vue 等主流开发框架中的一种</li>
                <li>了解 html/xhtml、css 等网页制作技术，熟悉页面架构和布局</li>
                <li>具备良好的编程习惯和沟通能力，抗压能力强</li>`,
						//   jobCategory: 1,
						jobCategory: '技术类',
						//   jobCity: [1, 2, 3, 4, 5, 6, 7, 8, 9],
						jobCity: '北京,上海，广州，珠海，杭州，西安，成都，武汉，合肥',
					},
					{
						id: 3,
						time: '2021-6-1',
						title: '<p>需求分析管培生 </p> ',
						description: ` <li>根据公司（集团及子公司）项目及产品需求，负责项目及产品的需求收 集、汇总、分析、更新、跟踪等工作</li>
                <li>配合公司（集团及子公司）项目及产品需求，负责编写产品需求文档， 包括业务结构及流程、界面原型、页面要素描述等内容  </li>
                <li>配合公司（集团及子公司）产品宣传，负责产品功能演示、操作手册编 写等产品工作</li>`,
						jobClaim: ` <li>本科学历，计算机/软件工程/通信/网络/信息管理等相关专业 </li>
                <li>熟悉 JavaScript 等 Web 开发技术，有良好的程序设计和架构能力，至少熟悉 Angular、Vue 等主流开发框架中的一种</li>
                <li>了解 html/xhtml、css 等网页制作技术，熟悉页面架构和布局</li>
                <li>具备良好的编程习惯和沟通能力，抗压能力强</li>`,
						//   jobCategory: 1,
						jobCategory: '技术类',
						//   jobCity: [1, 2, 3, 4, 5, 6, 7, 8, 9],
						jobCity: '北京,上海，广州，珠海，杭州，西安，成都，武汉，合肥',
					},
					{
						id: 4,
						time: '2021-6-1',
						title: '<p>大数据开发管培生  </p>',
						description: ` <li>负责数据相关产品的模块设计与代码编写 </li>
                <li>负责大数据项目实施以及部分设计开发 </li>
                <li>负责大数据环境下的数据清洗、转换、建模、分析以及部分开发工作 </li>
                <li>负责大数据环境的部署、调优以及日常运维</li>`,
						jobClaim: ` <li>本科学历，计算机/软件工程/通信/网络/信息管理等相关专业 </li>
                <li>熟练使用 Java 编程语言，熟练使用 Sql 语言 </li>
                <li>熟悉至少一种实时计算引擎 Storm, Spark Streaming, Flink, 对hadoop 生态其他组件有一定了解，比如 HBase， hadoop, Hive, Druid 等</li>
                <li>具备较强的责任心、良好的沟通能力及团队协作能力 </li>`,
						//   jobCategory: 1,
						jobCategory: '技术类',
						//   jobCity: [1, 2, 3, 4, 5, 6, 7, 8, 9],
						jobCity: '北京,上海，广州，珠海，杭州，西安，成都，武汉，合肥',
					},
					{
						id: 5,
						time: '2021-6-1',
						title: `<p>销售管培生<span style="font-size: 14px">（大客户销售方向）</span></p>`,
						description: ` <li>挖掘和反馈客户需求、开拓新市场和新客户，维护和发展客户关系</li>
                <li>收集和反馈市场销售信息，协助销售经理完成金融软件产品的销售任务 </li>
                <li>协助完成招投标工作，跟踪和反馈项目进展，处理项目流程事宜 </li>`,
						jobClaim: ` <li>本科学历，市场营销专业优先  </li>
                <li>熟练掌握 office 办公软件，善于处理流程性事务</li>
                <li>具备较强的沟通协调能力，亲和大方，形象气质佳</li>
                <li>有销售或者项目管理实习经历者优先 </li>`,
						//   jobCategory: 1,
						jobCategory: '技术类',
						//   jobCity: [1, 2, 3, 4, 5, 6, 7, 8, 9],
						jobCity: '北京,上海，广州，珠海，杭州，西安，成都，武汉，合肥',
					},
					{
						id: 6,
						time: '2021-6-1',
						title: '<p>培训管培生  </p>',
						description: ` <li>协助制定并执行部门月度培训安排计划 </li>
                <li>培训行政管理，对培训所需(产生)的教材、记录及档案进行管理  </li>
                <li>根据需求担任培训项目讲师 </li>
                <li>协助开展公司企业文化活动的策划、组织实施  </li>
                <li>协助培训体系建设，完善培训制度，如讲师管理制度、学员管理制度、课程管理、年度培训计划调查、总结分析等 </li>`,
						jobClaim: ` <li>本科及以上学历 </li>
                 <li>熟练掌握 office 办公软件，能独立制作 PPT  </li>
                <li>优秀的沟通和表达能力</li>
                <li>性格开朗，积极进取，热爱教育培训事业</li>
                <li>工作认真细致，有较强的责任心 </li>
                <li>思维开拓，有团队合作精神 </li>`,
						//   jobCategory: 1,
						jobCategory: '技术类',
						//   jobCity: [1, 2, 3, 4, 5, 6, 7, 8, 9],
						jobCity: '北京,上海，广州，珠海，杭州，西安，成都，武汉，合肥',
					},
					{
						id: 7,
						time: '2021-6-1',
						title: '<p>行政管培生 </p> ',
						description: ` <li>负责企业集团化管理下的固定资产及办公用品管理 </li>
                  <li>负责行政物资采购和发放、业务性采购及供应商沟通维护 </li>
                <li>负责集团考勤数据汇总、分析、管理工作 </li>
                <li>负责支部党务工作的宣传与执行</li>`,
						jobClaim: ` <li>本科学历，行政管理专业优先 </li>
                <li>熟练掌握 office 办公软件，工作效率高 </li>
                <li>了善于独立解决问题、考虑问题周全且细致 </li>
                <li>工作积极主动，具备良好的事务性沟通能力</li>`,
						//   jobCategory: 1,
						jobCategory: '技术类',
						//   jobCity: [1, 2, 3, 4, 5, 6, 7, 8, 9],
						jobCity: '北京,上海，广州，珠海，杭州，西安，成都，武汉，合肥',
					},
					{
						id: 8,
						time: '2021-6-1',
						title: '<p>招聘管培生 </p> ',
						description: ` <li>协助部门制定人员招聘计划并对最终招聘进度和结果负责 </li>
                    <li>组织开拓和完善各种人力资源招聘渠道，发布招聘信息 </li>
                <li>筛选简历、组织安排面试，对面试结果进行跟踪反馈 </li>
                <li>负责员工薪资谈判、入职前和试用期的沟通跟进 </li>`,
						jobClaim: ` <li>本科学历，人力资源管理专业优先 </li>
                <li>人力资源招聘工作有一定的兴趣和认知</li>
                <li>沟通协作能力强、工作责任心及工作执行力强</li>
                <li>有招聘或者猎头实习经历优先 </li>`,
						//   jobCategory: 1,
						jobCategory: '技术类',
						//   jobCity: [1, 2, 3, 4, 5, 6, 7, 8, 9],
						jobCity: '北京,上海，广州，珠海，杭州，西安，成都，武汉，合肥',
					},
					{
						id: 9,
						time: '2021-6-1',
						title: '<p>财务管培生 </p> ',
						description: ` <li>协助经理完成日常事务性工作，协助处理帐务</li>
                <li>申请票据，购买发票，准备和报送会计报表，协助办理税务报表的申报 </li>
                <li>现金及银行收付处理，制作记帐凭证，银行对帐，单据审核，开具与保管发票  </li>
                <li>负责与银行、税务等部门的对外联络</li>`,
						jobClaim: ` <li>本科学历，财务，会计，经济等相关专业优先  </li>
                <li>较好的会计基础知识和一定的英语能力，有财会工作经验者优先 </li>
                <li>逻辑思维清晰，有上进心，可以较熟练使用 Excel 表及财务办公软件</li>
                <li>工作认真负责，做事细致，有独立工作能力，应变能力突出，具有良好的责任心、敬业精神和团队协作精神</li>`,
						//   jobCategory: 1,
						jobCategory: '技术类',
						//   jobCity: [1, 2, 3, 4, 5, 6, 7, 8, 9],
						jobCity: '北京,上海，广州，珠海，杭州，西安，成都，武汉，合肥',
					},
					{
						id: 10,
						time: '2021-6-1',
						title: '<p>JAVA 开发管培生 </p> ',
						description: ` <li>完成软件项目的功能设计、开发、测试、投产</li>
                <li>负责自身代码开发质量，定期进行代码评审和代码安全检查</li>
                 <li>负责公司产品文档、源代码的整理和编写，演示环境搭建</li>
                <li>负责现有技术平台、技术组件、技术工具的维护、优化、改善</li>`,
						jobClaim: ` <li>本科学历，计算机/软件工程/通信/网络/信息管理等相关专业 </li>
                <li>有一定开发相关实习或项目实训经验 </li>
                <li>具备良好的 JAVA 语言基础，熟悉基于 Oracle 和 Mysql 的设计和开发， 熟练使用 Spring、Mybatis 等框架 </li>
                <li>具备良好的学习和沟通能力，喜欢钻研，乐于接受挑战</li>`,
						//   jobCategory: 1,
						jobCategory: '技术类',
						//   jobCity: [1, 2, 3, 4, 5, 6, 7, 8, 9],
						jobCity: '北京,上海，广州，珠海，杭州，西安，成都，武汉，合肥',
					},
				],
				m_helpData: [
					{
						id: 10,
						leftText: '培训周期：',
						desc: '为期 2-3 个月',
					},
					{
						id: 11,
						leftText: '培训导师：',
						desc: '艾融软件-笨鸟学院技术研发部及培训主管',
					},
					{
						id: 12,
						leftText: '培训课程：',
						desc: 'JAVA、前端等方面的基础课程+实训（参与实践的开发项目）+企业文化类课程',
					},
					{
						id: 14,
						leftText: '培训 3 个月以后：',
						desc: '参加分支机构的留用考核面试',
					},
					{
						id: 15,
						leftText: '实习：',
						desc: '通过考核后根据每个管培生的意向工作地点或公司要求分配实习（实习期间如有答辩或论文等事项，允许请假）',
					},
					{
						id: 16,
						leftText: '转正：',
						desc: '拿到毕业证和学位证后留用，签订劳动合同',
					},
				],
				currentJob: Object,
				isShowfixedJob: false,
				name: '',
				phone: '',
				mail: '',
				schoolFileName: '*上传简历',
				fileDatas: undefined,
				keys: '',
				isShowSubmitInfo: false, // 提交成功弹窗显隐控制
				postName: '',
				postPhone: '',
				postMail: '',
				positionName: '',
			};
		},
		computed: {
			groupedSchoolJD() {
				const groups = [];
				for (let i = 0; i < this.m_schoolJD.length; i += 2) {
					groups.push(this.m_schoolJD.slice(i, i + 2));
				}
				return groups;
			},
		},
		created() {
			this.query_type = this.$route.query.category;
			console.log('this.query_type', this.query_type);
			window.scrollTo(0, 540);
		},
		mounted() {
			window.addEventListener('scroll', this.getScroll);
		},
		destroyed() {
			window.removeEventListener('scroll', this.getScroll);
		},
		methods: {
			dismissFixBox() {
				this.isShowfixedJob = false;
			},
			m_onOpenDialog(item) {
				this.isShowfixedJob = true;
				this.currentJob = item;
			},
			selectFiles() {
				let file = document.getElementById('openFile');
				file?.click();
			},
			getFile(e) {
				this.fileDatas = e.target.files;
				let date = new Date();
				this.schoolFileName = this.fileDatas[0].name;
				this.keys = this.fileDatas[0].name.replace('.', '' + date.getFullYear() + (date.getMonth() + 1) + (date.getDay() - 1) + date.getHours() + date.getMinutes() + date.getSeconds() + '.');
				console.log(this.keys);
			},
			validateForm() {
				if (this.name.length <= 0 || this.name.length > 30) {
					this.$message({
						message: '请输入正确的姓名！',
						type: 'warning',
					});
					return false;
				}
				if (this.phone.length != 11) {
					this.$message({
						message: '请输入正确的手机号',
						type: 'warning',
					});
					return false;
				}
				if (this.mail.length <= 0 || this.mail.length > 30) {
					this.$message({
						message: '请输入正确的邮箱',
						type: 'warning',
					});
					return false;
				}
				if (this.fileDatas == null || this.fileDatas[0].length <= 0) {
					this.$message({
						message: '请选择上传的简历',
						type: 'warning',
					});
					return false;
				}
				return true;
			},
			onCloseDialog(title) {
				if (title === undefined) {
					this.isShowJobInfo = false;
					this.isShowfixedJob = false;
					return;
				}
				if (this.validateForm()) {
					this.isShowJobInfo = false;
					this.isShowfixedJob = false;
					this.$emit('uploadResume', {
						positionName: this.deleteHtmlTag(title),
						postName: this.name,
						postPhone: this.phone,
						postMail: this.mail,
						fileData: this.fileDatas,
						key: this.keys,
					});
					this.isShowSubmitInfo = true;
					console.log(this.postName);
				}
			},
			closeSubmitDialog() {
				this.isShowSubmitInfo = false;
				this.clearInfo();
			},
			clearInfo() {
				this.postName = '';
				this.postPhone = '';
				this.postMail = '';
				this.fileData = null;
				this.key = '';
				this.fileName = '请上传简历';
			},
			deleteHtmlTag(str) {
				str = str.replace(/<[^>]+>|&[^>]+;/g, '').trim(); //去掉所有的html标签和&nbsp;之类的特殊符合
				return str;
			},
			getScroll() {
				this.scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
				if (!!document.documentElement.scrollTop && document.documentElement.scrollTop >= 70) {
					this.$store.commit('TO_AfterScroll');
				} else {
					this.$store.commit('TO_AfterScroll_false');
				}
			},
		},
	};
</script>

<style lang="less" scoped>
	* {
		-moz-box-sizing: border-box;
		box-sizing: border-box;
		-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
	}

	.m-camp-wrapper {
		padding-bottom: 22px;
		.m-camp-first-wrapper {
			.m-camp-first-wrapper-title {
				padding: 27px 0px;
			}
			.m-camp-first-wrapper-content {
				.company-container {
					padding: 0 12px;
					display: flex;
					justify-content: center;
					align-content: center;
					flex-direction: column;
					margin: auto;

					.company-content {
						font-size: 12px;
						font-weight: normal;
						line-height: 20px;
						letter-spacing: 0em;
						text-align: justify;
						color: #666666;
					}

					.more {
						font-size: 12px;
						font-weight: normal;
						line-height: 28px;
						letter-spacing: 0em;
						color: #429af8;
						text-align: left;

						&:hover {
							cursor: pointer;
						}
					}
				}
			}
		}
		.m-camp-second-wrapper {
			padding: 40px 12px;
			background-position: center;
			background-size: cover;
			background-image: url('https://img.i2soft.net/i2official-web/v2/assets/images/joinus/phone/camp_thirdback.png');

			.m-camp-second-wrapper-title {
				margin-bottom: 50px;
			}
			.m-camp-second-wrapper-content {
				width: 100%;
				display: flex;
				flex-direction: column;
				align-items: center;
				.m_more_job-item {
					width: 180px;
					min-width: 180px;
					padding: 16px;
					background-color: #fff;
					text-align: left;
					color: #666;
					overflow: hidden;

					&:hover {
						background-color: rgba(36, 93, 161, 1);
						box-shadow: 5px 5px 12px 1px rgba(0, 0, 0, 0.2);
						color: #fff;

						.m_more_job-item-title {
							color: #fff;
							font-size: 16px;
						}
					}

					.m_more_job-item-title {
						font-size: 16px;
						font-weight: bold;
						margin: 10px;
						color: #333;
					}

					.m_more_job-item-content {
						width: 100%;
						overflow: hidden;
						font-size: 12px;
						line-height: 22px;
						margin: 18px 5px 10px 10px;

						div {
							display: flex;
							flex-direction: row;
							align-items: center;
							column-gap: 8px;
							white-space: nowrap;
							overflow: hidden;
							text-overflow: ellipsis;
						}
					}
				}
				/deep/ .el-carousel{
					width: 100%;
				}
				/deep/ .el-carousel__item {
					display: flex;
					flex-direction: row;
					column-gap: 10px;
					justify-content: center;
				}
			}
		}
		.m-camp-third-wrapper {
			.m-camp-third-wrapper-title {
				margin: 32px 0;
			}
			.m-camp-third-wrapper-content {
				margin: 20px 0;
				img {
					width: 100%;
				}
			}
		}
		.m-camp-fourth-wrapper {
			padding: 32px 20px;
			background-image: url('https://img.i2soft.net/i2official-web/v2/assets/images/joinus/phone/camp_fifthback.png');
			color: #fff;

			.m-camp-fourth-wrapper-title {
				margin-bottom: 58px;
			}
			.m-camp-fourth-wrapper-content {
				font-size: 12px;
				h4 {
					font-size: 14px;
					font-weight: 500;
					line-height: normal;
				}
				p {
					text-align: left;
				}

				ul {
					margin-bottom: 20px;
					list-style-type: disc;
					margin-left: 20px;
					text-align: left;
					li {
						margin-bottom: 4px;
					}
				}
			}
		}

		.m_fixedJobWrapper {
			position: fixed;
			left: 0;
			top: 0;
			bottom: 0;
			right: 0;
			// background: #245da1;
			background: #fff;
			z-index: 2003;

			.m_fix_container {
				position: relative;
				overflow: scroll;
				box-sizing: border-box;
				margin: 0px 0 30px 0;
				overflow: scroll;
				width: 100%;
				height: 100%;

				// background: #f2c21b;
				.m_fixedJob_top {
					display: flex;
					align-items: center;
					box-shadow: 0px 1px 5px 1px rgba(0, 0, 0, 0.2);

					.m_left_img_div {
						margin-left: 15px;
						overflow: hidden;
						// background: rebeccapurple;
						width: 30px;
						height: 30px;
						display: flex;
						justify-content: center;
						align-items: center;
					}

					background: #fff;
					width: 100%;
					height: 60px;
				}

				.m_fixed_title_box {
					width: 100%;
					height: 100px;

					// background: green;
					.m_title_title {
						width: 230px;
						height: 30px;
						font-size: 20px;
						color: #333;
						text-align: left;
						margin-left: 20px;
					}

					.m_title_location {
						margin-left: 20px;
						width: 300px;
						height: 30px;
						// background: rebeccapurple;
						display: flex;
						justify-content: flex-start;
						align-items: center;

						.m_dialog-hidden {
							margin-left: 5px;
							width: 150px;
							white-space: nowrap;
							overflow: hidden;
							text-overflow: ellipsis;
							text-align: left;
						}
					}

					.m_line {
						overflow: hidden;
						background-color: #dcdfe6;
						height: 1px;
						width: 90%;
						margin: 30px auto;
					}
				}

				.m_dialog-content {
					padding: 15px;

					.m_dialog-content-title {
						text-align: left;
						font-size: 16px;
						margin-bottom: 20px;
						color: #333;
					}

					.m_dialog-content-ul {
						list-style-type: decimal;
						text-align: left;
						font-size: 14px;
						color: #666;
						line-height: 30px;
						font-family: PingFangSC-Regular;
						padding-left: 15px;
					}
				}

				.m_line {
					background-color: #dcdfe6;
					height: 1px;
					width: 90%;
					margin: auto;
				}

				#m_inputBut {
					margin-left: 10px;
					margin-right: 10px;

					input {
						background: transparent;
						border-color: #999999;
						/*float: left;*/
						height: 40px;
					}

					.child {
						margin-top: 10px;
						-webkit-box-flex: 1;
						margin-right: 10px;

						/deep/ .el-input__inner {
							width: 100%;
							height: 40px;
							background: transparent;
							border-color: #999999;
						}
					}
				}

				.empty {
					color: rgba(153, 153, 153, 100);
					font-size: 20px;
					text-align: center;
					font-family: PingFangSC-Semibold;
				}

				.m_uploadWrapper {
					margin-left: 10px;
					margin-right: 10px;
					padding-top: 20px;
					text-align: center;
					width: calc(100% - 20px);

					.m_uploadBox {
						flex-wrap: wrap;

						.m_uploadInput {
							width: 253px;
							height: 25px;
						}

						.m_commitBtn {
							width: 100%;
							height: 40px;
							min-height: 40px;
							padding: 9px 15px;
							font-size: 12px;
							border-radius: 3px;
							font-size: 16px;
							border-radius: 26px;
							background-color: #f49a42;
							border: none;
						}

						.m_upr {
							padding: 0px 0px 8px 0px;
							text-align: left;
							border-bottom: #979797 solid 1px;
							width: calc(100% - 20px);
							margin-left: 10px;
						}

						.resume {
							margin-top: 40px;
							margin-left: -55px;
						}
					}
				}
				.m_upload-hint {
					text-align: left;
					.email {
						color: #245da1;
					}
				}
			}
		}
		.dialog-submit-res {
			/deep/ .el-dialog {
				width: 80%;
			}

			/deep/ .el-dialog__body {
				padding: 0;
			}

			/deep/ .el-dialog__header {
				display: none;
			}

			.dialog-title {
				background: url('https://img.i2soft.net/website-company-recruitment-bg-dialog.jpg') no-repeat;
				background-size: 100% 100%;
				margin-top: -30px;
				padding: 40px 20px;
				// padding: 20px 40px 40px 40px;
				color: #fff;

				.dialog-title-info {
					font-size: 30px;
					margin-bottom: 40px;
					text-align: left;

					span {
						color: #fff;
					}
				}

				.dialog-title-hint {
					width: 80;
					display: flex;
					flex-direction: column;
					align-items: flex-start;
					row-gap: 10px;
					font-size: 14px;

					div {
						display: flex;
						flex-direction: row;
						align-items: center;
					}
				}

				.dialog-title-img {
					cursor: pointer;
					float: right;
					width: 28px;
					height: 28px;
					margin-top: -30px;
				}

				.dialog-title-icon {
					width: 16px;
					height: 16px;
					margin-right: 8px;
				}

				.dialog-hidden {
					width: 130px;
					white-space: nowrap;
					overflow: hidden;
					text-overflow: ellipsis;
					margin-right: 40px;
				}
			}
			.dialog-content {
				padding: 20px;
			}
			.dialog-success-btn {
				width: 100%;
				display: flex;
				align-items: center;
				justify-content: center;
			}
		}
	}
</style>
