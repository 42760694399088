<template>
  <div class="homeWrapper">
    <div v-if="!isMobile" class="pcWrapper">
      <div class="firstBannerWrapper">
        <div class="block">
          <el-carousel :interval="3000" :height="imgHeight">
            <el-carousel-item v-for="(item, index) in firstData" :key="index + 1">
              <img class="scrllImg" :src="item.imgUrl" alt="" />
            </el-carousel-item>
          </el-carousel>
        </div>
      </div>
      <div class="secondWrapper">
        <div class="secontTitle">
          <WaveTitle :title="secondData.title" :subhead="secondData.subhead"></WaveTitle>
        </div>
        <div class="secContent">
          <div class="secRow">
            <div class="secItem" v-for="(item, index) in secondData.list" :key="index">
              <img class="showImage" :src="item.imgUrl" alt="" />
              <div>{{ item.name }}</div>
              <BottomLine />
            </div>
          </div>
        </div>
      </div>
      <div class="thirdWrapper">
        <div class="thirdTitle">
          <div class="wave-title">
            <div>{{ thirdData.title }}</div>
            <img src="https://img.i2soft.net/i2official-web/v2/assets/images/home/wave.png" />
            <div>{{ thirdData.subhead }}</div>
          </div>
        </div>
        <div class="thirdContent">
          <div class="thirdRowItem" v-for="(item, index) in thirdData.list" :key="index">
            <div class="itemTitle">{{ item.title }}</div>
            <div class="itemBottomName">{{ item.name }}</div>
          </div>
        </div>
      </div>
      <div class="forthWrapper">
        <WaveTitle style="margin-bottom: 40px" :title="'我们的市场表现'" :subhead="'Market performance'"></WaveTitle>
        <img src="https://img.i2soft.net/i2official-web/v2/assets/images/home/biaoxian.png" alt="" />
        <div class="forthContent">
          <div class="desc">
            2021年11月15日，北交所正式开市。艾融软件为北交所首批上市公司、北证50首批成份指数样本股、北交所首批做市标的股票。
          </div>
        </div>
      </div>
      <div class="fifthWrapper">
        <img src="https://img.i2soft.net/i2official-web/v2/assets/images/home/team.png" alt="" />
      </div>
      <div class="sixWrapper">
        <div class="sixTitle">
          <WaveTitle :title="sixData.title" :subhead="sixData.subhead"></WaveTitle>
        </div>
        <div class="sixContent">
          <div class="text">
            <div v-for="(item, index) in sixData.list" :key="index">
              {{ item }}
            </div>
          </div>
          <img src="https://img.i2soft.net/i2official-web/v2/assets/images/home/map.png" alt="" />
        </div>
      </div>
    </div>
    <PhoneIndex v-else/> 
  </div>
</template>

<script>

import BottomLine from '@/components/BottomLine.vue';
import WaveTitle from '@/components/WaveTitle.vue';
import { isMobile } from "@/utils/index";
import PhoneIndex from './PhoneIndex.vue';

export default {
  name: "First",
  components: { WaveTitle, BottomLine, PhoneIndex },
  data() {
    return {
      screenWidth: document.body.clientWidth,     // 屏幕宽
      isMobile: false,
      firstData: [
        {
          id: "1",
          imgUrl: "https://img.i2soft.net/i2official-web/v2/assets/images/home/banner1.jpg",
        },
        {
          id: "2",
          imgUrl: "https://img.i2soft.net/i2official-web/v2/assets/images/home/banner2.jpg",
        },
        {
          id: "3",
          imgUrl: "https://img.i2soft.net/i2official-web/v2/assets/images/home/banner3plus.jpg",
        },
        {
          id: "4",
          imgUrl: "https://img.i2soft.net/i2official-web/v2/assets/images/home/banner4.jpg",
        },
      ],
      secondData: {
        title: '我们能为您的行业打造深度的数字化能力',
        subhead: 'Digital capability',
        list: [
          {
            id: "13",
            name: "产品及解决方案",
            imgUrl: "https://img.i2soft.net/i2official-web/v2/assets/images/home/digitalSkills/skill01.svg",
          },
          {
            id: "7",
            name: "业务咨询和创新",
            imgUrl: "https://img.i2soft.net/i2official-web/v2/assets/images/home/digitalSkills/skill02.svg",
          },
          {
            id: "1",
            name: "大规模定制交付",
            imgUrl: "https://img.i2soft.net/i2official-web/v2/assets/images/home/digitalSkills/skill03.svg",
          },
          {
            id: "20",
            name: "数字化运营",
            imgUrl: "https://img.i2soft.net/i2official-web/v2/assets/images/home/digitalSkills/skill04.svg",
          },
          {
            id: "21",
            name: "数字化安全保障",
            imgUrl: "https://img.i2soft.net/i2official-web/v2/assets/images/home/digitalSkills/skill05.svg",
          },
          {
            id: "22",
            name: "数字化系统集成",
            imgUrl: "https://img.i2soft.net/i2official-web/v2/assets/images/home/digitalSkills/skill06.svg",
          },
        ],
      },
      thirdData: {
        title: '我们的技术实力',
        subhead: 'technical strength',
        list: [
          {
            id: "13",
            title: "90%",
            name: "技术工程师超过",
          },
          {
            id: "7",
            title: "430项",
            name: "计算机软件著作权",
          },
          {
            id: "1",
            title: "24项",
            name: "软件技术发明专利",
          },
        ],
      },
      forthData: [
        {
          id: "13",
          title: "90%",
          name: "技术工程师",
          imgUrl: "https://img.i2soft.net/i2official-web/assets/images/home/forth01.png",
        },
        {
          id: "7",
          title: "430项",
          name: "计算机软件著作权",
          imgUrl: "https://img.i2soft.net/i2official-web/assets/images/home/forth02.png",
        },
        {
          id: "1",
          title: "24项",
          name: "软件技术发明专利",
          imgUrl: "https://img.i2soft.net/i2official-web/assets/images/home/forth03.png",
        },
      ],
      sixData: {
        title: '全国机构分布',
        subhead: 'National distribution',
        list: ['上海、北京、广州、杭州、珠海、西安、成都、合肥', '武汉、长沙、福州、深圳、苏州、天津、雄安、厦门']
      },
    };
  },
  computed: {
    // 计算banner高度
    imgHeight() {
      //return 788 / 1920 * this.screenWidth + 'px';
      return 925 / 1920 * this.screenWidth + 'px';
      //return '1920px'
    }
  },
  created() { },
  mounted() {
    this.isMobile = isMobile();
    window.addEventListener("scroll", this.getScroll);
  },
  destroyed() {
    window.removeEventListener("scroll", this.getScroll);
  },

  methods: {
    getScroll() {
      // this.indexNumTop = $("#index_num").offset().top;
      this.scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      if (!!document.documentElement.scrollTop && document.documentElement.scrollTop >= 70
      ) {
        //设置滚动大于300时的执行内容
        // console.log(
        //   "document.documentElement.scrollTop=",
        //   document.documentElement.scrollTop
        // );
        this.$store.commit("TO_AfterScroll");
      } else {
        this.$store.commit("TO_AfterScroll_false");
      }
    },
  },
};
</script>

<style lang="less" scoped>
.homeWrapper {
  background: #fff;

  .pcWrapper {
    .firstBannerWrapper {
      .el-carousel__container {
        width: 100%;
        height: 100%;
      }

      img {
        width: 100%;
        height: inherit;
      }
    }

    .secondWrapper {
      padding: 160px 0;

      .secontTitle {
        text-align: center;
        font-size: 30px;
        margin: 50px 0;
      }

      .center-column {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        div:first-child {
          font-size: 36px;
          font-weight: 700;
          line-height: normal;
          text-align: center;
          letter-spacing: 0em;
          color: #221815;
        }

        img {
          margin: 12px 0;
        }

        div:last-child {
          font-family: Times New Roman;
          font-size: 14px;
          font-weight: normal;
          line-height: normal;
        }
      }

      .secContent {
        width: 75%;
        margin: 24px auto;

        .secRow {
          display: grid;
          grid-template-columns: repeat(3, 1fr);
          align-items: center;
          justify-items: center;
          row-gap: 60px;

          .secItem {
            width: 150px;

            // .showImage {
            //   width: 150px;
            //   height: 150px;
            // }

            div {
              font-size: 16px;
              font-weight: 900;
              line-height: normal;
              text-align: center;
            }
          }
        }
      }
    }

    .thirdWrapper {
      background-image: url("https://img.i2soft.net/i2official-web/v2/assets/images/home/techProwessBack.png");
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
      height: 545px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      // row-gap: 98px;
      justify-content: space-evenly;

      .thirdTitle {
        .wave-title {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          color: #FFF;
          line-height: normal;
          text-align: center;
          letter-spacing: 0em;

          div:first-child {
            font-size: 36px;
            font-weight: 700;


          }

          img {
            margin: 12px 0;
          }

          div:last-child {
            font-family: Times New Roman;
            font-size: 14px;
          }
        }
      }

      .thirdContent {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-evenly;

        .thirdRowItem {
          display: flex;
          flex-direction: column;
          align-items: center;
          line-height: normal;
          text-align: center;
          letter-spacing: 0em;

          .itemTitle {
            font-size: 88px;
            font-weight: bold;
            color: #FFF;

          }

          .itemBottomName {
            font-size: 24px;
            font-weight: normal;
            color: #F49A42;
          }
        }
      }
    }

    .forthWrapper {
      background: #fff;
      padding: 160px 0;

      .forthTitle {
        margin: 50px 0;
        font-size: 16px;
        font-weight: bold;
      }

      .forthContent {
        width: 75%;
        margin: 24px auto;

        .desc {
          font-size: 18px;
          font-weight: normal;
          line-height: normal;
          text-align: center;
          white-space: pre-line;
          letter-spacing: 0em;
          color: #001F34;
        }

      }
    }

    .fifthWrapper {
      position: relative;
      overflow: hidden;

      img {
        width: 100%;
        height: auto;
        float: left;
      }

      .fifthTitle {
        width: 100%;
        position: absolute;
        color: #fff;
        font-size: 30px;
        text-align: center;
        margin-top: 30px;
      }
    }

    .sixWrapper {
      padding: 80px 0;

      .sixTitle {
        margin: 15px 0;
      }

      .sixContent {
        display: flex;
        flex-direction: column;
        align-items: center;


        .text {
          font-size: 18px;
          font-weight: normal;
          line-height: normal;
          text-align: center;
          letter-spacing: 0em;
          color: #001F34;

        }


        .rightImg {
          width: 100%;

          // height: 400px;
          img {
            width: 100%;
            height: 100%;
          }
        }
      }
    }
  }
}
</style>
